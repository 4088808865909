import authAjax from '@ajax/auth';
import React from 'react';
import { useSelector } from 'react-redux';

import { handleError, handleSuccess } from '@util/common';

import ChangePasswordForm from './changePasswordForm/changePasswordForm';

import commonConstant from '../../constant/common';

import './common.scss';

const SettingsPage = () => {
  const userAuth = useSelector(state => state.userAuth)

  const handleSubmitPassword = (values) => {
    if (!commonConstant.PASSWORD_FORMAT.test(values.password)) {
      handleError("Mật khẩu hiện tại không đúng định dạng")
      return
    }


    if (values.password === values.new_password) {
      handleError("Mật khẩu mới phải khác mật khẩu hiện tại")
      return
    }

    if (!commonConstant.PASSWORD_FORMAT.test(values.new_password)) {
      handleError("Mật khẩu mới không đúng định dạng")
      return
    }

    if (values.new_password !== values.new_password_confirmation) {
      handleError("Mật khẩu nhập lại không khớp")
      return
    }

    if (window.confirm(`Xác nhận đổi mật khẩu?`)) {
      authAjax.changePassword(userAuth.user_id, JSON.stringify(values))
        .then(apiResponse => {
          handleSuccess('Thay đổi mật khẩu thành công. Vui lòng đăng nhập lại với mật khẩu mới.')
          setTimeout(function () {

            authAjax.logout()
              .then(apiResponse => {
                window.location.href = '/'
              })
          }, 5000)
        })
        .catch(handleError)
    }
  }

  return <div className="settings-page">
    <h3 className="font-weight-bold pageTitle">
      Cài đặt
    </h3>
    <h5><b>Đổi mật khẩu</b></h5>
    <ChangePasswordForm
      onSubmit={handleSubmitPassword}
    />
  </div>
}

export default SettingsPage