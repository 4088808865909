import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';

import userAuth from '@pages/login/loginReducer';

const rootReducer = combineReducers({
  form: formReducer,
  userAuth,
})

export default rootReducer