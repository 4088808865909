import React from "react";
import './index.scss'

class NotFoundPage extends React.Component {
    constructor(props, context) {
        super(props, context)
        this.state = {
            selectedProblems: []
        }
    }

    render() {
        return (
            <div id="notfound">
                <div className="notfound">
                    <div className="notfound-404">
                        <h1>4<span></span>4</h1>
                    </div>
                    <h2>Oops! Không tìm thấy trang</h2>
                    <p>Xin lỗi nhưng trang bạn đang tìm kiếm không tồn tại, hoặc đã bị xóa, đổi tên hoặc tạm thời không thể truy cập</p>
                    <a href="/">Trở về trang chủ</a>
                </div>
            </div>
        )
    }
}

export default NotFoundPage
