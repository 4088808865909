import React from 'react'
import { Route } from "react-router-dom";
import './common.scss'
import {connect} from 'react-redux'

class ErrorLayout extends React.Component {
    constructor(props, context) {
        super(props, context)
        this.state = {}
    }

    render() {
        const {component: Component, ...rest} = this.props
        return (
            <Route {...rest} render={matchProps => (
                <Component {...matchProps} />
            )}
            />
        )
    }
}

function mapStateToProps(state) {
    return {
        // userAuth: state.userAuth
    }
}

function mapDispatchToProps(dispatch) {
    return {
        // actions: bindActionCreators(authAction, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ErrorLayout)
