export const PUSHER_KEY = 'b662ceef1ae1d7704769'
export const CHANNELS = {
  SUMMARY: 'summary',
  FORM: 'form',
  CHANNEL_WM_ACCOUNT: 'CHANNEL_WM_ACCOUNT',
  LEADERBOARD: 'LEADERBOARD',
}

export const EVENTS = {
  UPDATE: 'summary-update',
  FORM_UPDATE: 'form-update',
  WM_ACCOUNT_UPDATE: 'WM_ACCOUNT_UPDATE',
  LEADERBOARD_UPDATE: 'LEADERBOARD_UPDATE',
}