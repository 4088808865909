import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import authAjax from "@ajax/auth";
import userAjax from '@ajax/user';
import { updateUserAuthInfo } from "@pages/login/loginAction";
import apiResult from '@util/apiResult';
import { handleError, handleSuccess } from '@util/common';
import AddMemberForm from './addMemberForm/addMemberForm';

import StringUtil from '@util/string';
import userConstant from '../../constant/user';
import { useAppContext } from '../../contexts/app';
import './common.scss';

const MemberPage = () => {
  const dispatch = useDispatch()
  const userAuth = useSelector(state => state.userAuth)
  const {
    setShowModal,
    setModalContent,
    setModalTitle,
  } = useAppContext()

  const [filterSearch, setFilterSearch] = useState('')
  const [users, setUsers] = useState([])

  const getUsers = () => {
    userAjax.list()
      .then(apiResponse => {
        setUsers(apiResult.success(apiResponse).users || [])
      })
      .catch(handleError)
  }

  useEffect(() => {
    getUsers()
  }, [])

  const handleFilterChange = (e) => {
    setFilterSearch(e.target.value)
  }

  const handleUpdateInfo = (value, account, key) => {
    userAjax.update(account.user_id, JSON.stringify({ [key]: value }))
      .then(res => {
        handleSuccess('Thay đổi thành công.');
        getUsers()
      })
      .catch(handleError)
  }

  const resetPassword = (account) => {
    if (window.confirm(`Xác nhận khởi tạo mật khẩu của tài khoản ${account.email}?`)) {
      userAjax.update(account.user_id, JSON.stringify({ password: 'Zion@2023' }))
        .then(res => {
          handleSuccess('Khởi tạo mật khẩu thành công.')
          getUsers()
        })
        .catch(handleError)
    }
  }

  const updateUserAuth = (account) => {
    if (userAuth.user_id === account.user_id) {
      authAjax
        .get()
        .then(apiResponse => {
          dispatch(updateUserAuthInfo(apiResult.success(apiResponse)))
        })
    }
  }

  const handleUpdateUser = (account, oldAccount) => {
    if (window.confirm(`Xác nhận cập nhật thông tin của tài khoản ${account.email}?`)) {
      userAjax.update(account.user_id, JSON.stringify({
        'email': account.email,
        'zion': account.zion,
        'old_zion_id': oldAccount.zion.zion_id
      }))
        .then(res => {
          handleSuccess('Cập nhật thành công.')
          getUsers()
          updateUserAuth(account)
          setShowModal(false)
        })
        .catch(handleError)
    }
  }

  const handleCreateUser = (values) => {
    userAjax.create(JSON.stringify(values))
      .then(response => {
        setShowModal(false)
        handleSuccess('Tạo mới thành công')
        getUsers()
      })
      .catch(handleError)
  }

  const toggleFormAdd = () => {
    setModalTitle('Thêm Tài Khoản')
    setModalContent(<AddMemberForm onSubmit={handleCreateUser} closeForm={() => setShowModal(false)} />)
    setShowModal(true)
  }

  const handleEditAccount = (account) => {
    setModalTitle('Cập nhật thông tin tài khoản')
    setModalContent(<AddMemberForm
      onSubmit={(e) => handleUpdateUser(e, account)}
      closeForm={() => setShowModal(false)}
      initialValues={{ ...account, zion: account.zion.name }}
      isEdit
    />)
    setShowModal(true)
  }

  const filteredUsers = filterSearch
    ? users.filter(user => {
      return (user.last_name && StringUtil.nonAccentVietnamese(user.last_name)?.toLowerCase().search(filterSearch) >= 0)
        || (user.first_name && StringUtil.nonAccentVietnamese(user.first_name)?.toLowerCase().search(filterSearch) >= 0)
        || (user.zion?.name && StringUtil.nonAccentVietnamese(user.zion.name)?.toLowerCase().search(filterSearch) >= 0)
        || (user.email && StringUtil.nonAccentVietnamese(user.email).toLowerCase().search(filterSearch) >= 0)
    })
    : users

  const isAddMemberAllowed = [userConstant.ROLE_VALUES.HT].includes(userAuth.roles)
    || userAuth.user_id === 2
  // || ([userConstant.ROLE_VALUES.NT].includes(userAuth.roles) && userAuth.is_hm)

  return (
    <div className="members-page">
      <div className="col-md-12 grid-margin">
        <h3 className="font-weight-bold">Thành viên</h3>
        <br />
        {isAddMemberAllowed && <div className={"addMember "}>
          <h5 onClick={toggleFormAdd}>
            Thêm tài khoản
          </h5>
        </div>}
        <div className='function-row'>
          <div className="form-group searchRow" style={{ display: 'flex', alignItems: 'center' }}>
            <label style={{ margin: '0 10px 0 0' }}>Tìm kiếm</label>
            <input
              className="form-control searchInput"
              type="text"
              onChange={handleFilterChange}
              value={filterSearch}
              placeholder="Xin nhập từ khóa"
            />
          </div>
        </div>

        <div id='member-table-wrapper'>
          <table id='member-table' className='table table-striped'>
            <thead>
              <tr>
                <th scope="col">#</th>
                {/* <th scope="col">Họ tên</th> */}
                <th scope="col">Khu vực</th>
                <th scope="col">Email</th>
                <th scope="col">Trạng thái</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              {filteredUsers.map((user, index) =>
                <tr key={user.user_id}>
                  <td>{filteredUsers.length - index}</td>
                  {/* <td>{user.first_name} {user.last_name}</td> */}
                  <td>{user?.zion?.name}</td>
                  <td>{user.email}</td>
                  <td>
                    <select
                      defaultValue={user.account_status}
                      disabled={user.user_id === userAuth.user_id}
                      onChange={(e) => handleUpdateInfo(e.target.value, user, 'account_status')}
                    >
                      {userConstant.ACCOUNT_STATUS_OPTIONS.map((option, index) => <option key={index} value={option.value}>
                        {option.label}
                      </option>)}
                    </select>
                  </td>
                  <td className='account-actions'>
                    <i
                      className="fa fa-pencil-square-o"
                      aria-hidden="true"
                      onClick={() => handleEditAccount(user)}
                      title="Cập nhật thông tin"
                    />
                    <i
                      className="fa fa-repeat"
                      aria-hidden="true"
                      onClick={() => resetPassword(user)}
                      title="Khởi tạo mật khẩu"
                    ></i>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        {filteredUsers && filteredUsers.length === 0 && <div>
          Không tìm thấy kết quả phù hợp.
        </div>}
      </div>
    </div >
  )
}

export default MemberPage
