import React from 'react';

const PlaneTiny = ({ className }) =>
  // <svg width="33" height="33" viewBox="0 0 33 33" fill="none" className={className} id="planeTiny">
  //   <path d="M29.7514 30.7314C29.8276 30.5931 27.8133 23.4579 26.1857 17.7601C26.4434 17.4344 26.6576 17.1634 26.6576 17.1634L27.4488 17.1552L29.4016 14.6822L27.4597 13.23L25.572 15.6195C25.2639 14.5414 24.9846 13.5734 24.7608 12.789C26.7634 10.0938 30.6141 4.88303 31.1581 3.93322C31.9008 2.63899 32.4765 0.487421 32.061 0.230837C31.6455 -0.0257465 29.6289 1.03277 28.6501 1.77563C27.9067 2.33664 23.3431 7.94361 21.1385 10.6716C20.1996 10.6396 19.0912 10.6039 17.8859 10.5677L20.1207 7.74033L18.1789 6.28804L16.2261 8.761L16.4206 9.50852C16.4206 9.50852 16.0029 10.0375 15.6341 10.504C9.0964 10.3141 1.16744 10.1445 1.01503 10.4211C0.756468 10.8935 1.18684 12.6559 1.18684 12.6559L7.02199 14.1768C6.70702 14.6332 6.5459 15.0118 6.64768 15.0884C6.76285 15.1755 7.17931 14.843 7.60766 14.3297L10.536 15.0942C10.3131 15.4568 10.2091 15.7364 10.2948 15.8024C10.3939 15.8763 10.721 15.6374 11.084 15.239L16.8681 16.7466L12.4374 23.0745L4.7066 23.0527L3.83781 24.7311L9.53301 26.6885L11.1193 25.609C11.1193 25.609 9.94446 27.165 10.3707 27.4269C10.7969 27.6888 12.08 26.466 12.08 26.466L11.4923 27.9314L14.4888 32.8913L16.8122 31.4079L14.4264 24.2992L19.6893 18.7306L22.1966 23.1612C21.7788 23.7138 21.5389 24.2124 21.6595 24.3022C21.7639 24.3814 22.1127 24.1165 22.4948 23.6868L23.9533 26.2673C23.9452 26.2777 23.9371 26.2882 23.9289 26.2986C23.457 26.8953 23.1764 27.4566 23.3049 27.5517C23.4201 27.6388 23.8312 27.3088 24.2568 26.8008L26.9771 31.6097C26.9824 31.6071 29.6262 30.9636 29.7514 30.7314Z" fill="#3D45EF" />
  // // </svg>
  // <svg width="37" height="36" viewBox="0 0 37 36" fill="none" id="planeTin">
  //   <path d="M12.2138 35.8108C12.3686 35.7795 16.5086 29.629 19.8022 24.703C20.2173 24.6889 20.5625 24.6771 20.5625 24.6771L21.0804 25.2753L24.2295 25.1656L24.0815 22.7452L21.0381 22.8506C21.6613 21.9184 22.2191 21.0794 22.6726 20.4014C26.0235 20.1861 32.4882 19.7537 33.5645 19.5545C35.0319 19.284 37.0454 18.3318 36.9724 17.849C36.8995 17.3661 34.788 16.5124 33.5885 16.2462C32.6798 16.0419 25.4518 16.1868 21.9453 16.2692C21.3626 15.5324 20.673 14.6638 19.9213 13.721L23.5231 13.5973L23.3751 11.177L20.226 11.2866L19.7816 11.9183C19.7816 11.9183 19.108 11.9418 18.5137 11.9621C14.4308 6.85259 9.43287 0.694854 9.12331 0.757488C8.59579 0.865724 7.52979 2.33367 7.52979 2.33367L10.143 7.76814C9.59126 7.82296 9.19821 7.94495 9.20562 8.0721C9.21364 8.21629 9.73664 8.31888 10.4051 8.31374L11.7156 11.0417C11.2949 11.1062 11.0144 11.2077 11.0195 11.3157C11.0272 11.4391 11.4209 11.5341 11.9596 11.5534L14.55 16.9403L6.85799 17.6526L1.87541 11.7417L0.0333588 12.1643L2.22318 17.7743L4.07236 18.2862C4.07236 18.2862 2.12575 18.3963 2.20161 18.8907C2.27747 19.3852 4.0399 19.5732 4.0399 19.5732L2.54213 20.0725L0.696507 25.5656L3.33046 26.3785L7.21001 19.9618L14.8609 20.3752L13.1028 25.1528C12.4111 25.1915 11.8756 25.3309 11.8851 25.4809C11.8922 25.6118 12.3198 25.7065 12.8947 25.7201L11.8695 28.5014C11.8563 28.502 11.8431 28.5025 11.8299 28.503C11.0695 28.529 10.4599 28.6778 10.4705 28.8374C10.4786 28.9816 10.9961 29.0817 11.6588 29.0779L9.74975 34.2626C9.75521 34.265 11.9557 35.8655 12.2138 35.8108Z" fill="#3D45EF" />
  // </svg>

  // <svg width="40" height="62" viewBox="0 0 40 62" fill="none" id="planeTiny">
  //   <path d="M35.6562 22.0776C35.6562 31.4508 28.4243 39.0072 19.5539 39.0072C10.6834 39.0072 3.45152 31.4508 3.45152 22.0776C3.45152 12.7044 10.6834 5.14795 19.5539 5.14795C28.4243 5.14795 35.6562 12.7044 35.6562 22.0776Z" fill="white" stroke="#3D45EF" />
  //   <path d="M22.1719 32.1351C22.2509 32.0751 22.9311 27.3393 23.4633 23.5522C23.6941 23.4324 23.886 23.3328 23.886 23.3328L24.34 23.5464L26.0901 22.6365L25.3534 21.2498L23.662 22.1288C23.7626 21.4121 23.8515 20.768 23.9249 20.2467C25.7604 19.2204 29.2971 17.2304 29.8516 16.8242C30.6079 16.2709 31.4888 15.1693 31.3173 14.9044C31.1458 14.6394 29.7222 14.703 28.9725 14.8681C28.4039 14.9916 24.3583 17.0169 22.399 18.0066C21.8708 17.7288 21.2468 17.402 20.5676 17.0481L22.5697 16.0085L21.833 14.6218L20.083 15.5317L20.0023 16.0232C20.0023 16.0232 19.6279 16.2179 19.2976 16.3894C15.6117 14.474 11.1258 12.1864 10.9678 12.3064C10.6989 12.5118 10.4925 13.663 10.4925 13.663L13.4355 16.1644C13.1385 16.3448 12.9493 16.5222 12.9878 16.5951C13.0313 16.6779 13.3545 16.598 13.7309 16.4156L15.2075 17.6715C14.9871 17.8225 14.856 17.9575 14.888 18.0198C14.9257 18.0905 15.1738 18.0408 15.4834 17.9076L18.4007 20.387L14.246 22.8713L9.83544 20.7252L8.90849 21.4686L11.6596 24.187L12.8427 23.9924C12.8427 23.9924 11.7724 24.5798 11.9487 24.8508C12.1249 25.1218 13.1716 24.7596 13.1716 24.7596L12.46 25.4559L12.899 29.1885L14.6069 28.9606L15.068 24.1378L19.5032 22.3279L19.7987 25.6154C19.4183 25.8238 19.1533 26.0497 19.1991 26.1356C19.2384 26.2108 19.5056 26.1519 19.8342 26.0056L20.0052 27.9199C19.9979 27.9237 19.9906 27.9276 19.9833 27.9314C19.5606 28.1508 19.2562 28.4021 19.3053 28.4933C19.3487 28.5761 19.6682 28.4963 20.0417 28.3161L20.3617 31.884C20.3655 31.884 22.0408 32.2366 22.1719 32.1351Z" fill="#3D45EF" />
  //   <path d="M18.5867 0.0512169C8.47769 0.779388 0.397876 9.56392 0.0141779 20.1921C-0.221944 26.8696 2.50822 32.8731 6.93551 36.8858C8.1235 37.9626 9.2082 39.1633 10.2043 40.4337C13.2297 44.2837 18.1292 51.8133 19.996 62C21.8629 51.8133 26.7624 44.2915 29.7877 40.4415C30.7691 39.1865 31.8243 38.0013 32.9975 36.9478C37.2772 33.0978 40 27.3809 40 20.9978C40.0074 8.91322 30.2747 -0.793152 18.5867 0.0512169ZM20.0108 35.7936C12.1745 35.7936 5.82132 29.1238 5.82132 20.8971C5.82132 12.6703 12.1745 6.00053 20.0108 6.00053C27.8471 6.00053 34.2002 12.6703 34.2002 20.8971C34.2002 29.1238 27.8397 35.7936 20.0108 35.7936Z" fill="#FB8C00" />
  // </svg>

  <svg width="40" height="124" viewBox="0 0 40 124" fill="none" id="planeTiny">
    <path d="M35.6562 22.0776C35.6562 31.4508 28.4243 39.0072 19.5539 39.0072C10.6834 39.0072 3.45152 31.4508 3.45152 22.0776C3.45152 12.7044 10.6834 5.14795 19.5539 5.14795C28.4243 5.14795 35.6562 12.7044 35.6562 22.0776Z" fill="white" stroke="#3D45EF" />
    <path d="M22.1719 32.1351C22.2509 32.0751 22.9311 27.3393 23.4633 23.5522C23.6941 23.4324 23.886 23.3328 23.886 23.3328L24.34 23.5464L26.0901 22.6365L25.3534 21.2498L23.662 22.1288C23.7626 21.4121 23.8515 20.768 23.9249 20.2467C25.7604 19.2204 29.2971 17.2304 29.8516 16.8242C30.6079 16.2709 31.4888 15.1693 31.3173 14.9044C31.1458 14.6394 29.7222 14.703 28.9725 14.8681C28.4039 14.9916 24.3583 17.0169 22.399 18.0066C21.8708 17.7288 21.2468 17.402 20.5676 17.0481L22.5697 16.0085L21.833 14.6218L20.083 15.5317L20.0023 16.0232C20.0023 16.0232 19.6279 16.2179 19.2976 16.3894C15.6117 14.474 11.1258 12.1864 10.9678 12.3064C10.6989 12.5118 10.4925 13.663 10.4925 13.663L13.4355 16.1644C13.1385 16.3448 12.9493 16.5222 12.9878 16.5951C13.0313 16.6779 13.3545 16.598 13.7309 16.4156L15.2075 17.6715C14.9871 17.8225 14.856 17.9575 14.888 18.0198C14.9257 18.0905 15.1738 18.0408 15.4834 17.9076L18.4007 20.387L14.246 22.8713L9.83544 20.7252L8.90849 21.4686L11.6596 24.187L12.8427 23.9924C12.8427 23.9924 11.7724 24.5798 11.9487 24.8508C12.1249 25.1218 13.1716 24.7596 13.1716 24.7596L12.46 25.4559L12.899 29.1885L14.6069 28.9606L15.068 24.1378L19.5032 22.3279L19.7987 25.6154C19.4183 25.8238 19.1533 26.0497 19.1991 26.1356C19.2384 26.2108 19.5056 26.1519 19.8342 26.0056L20.0052 27.9199C19.9979 27.9237 19.9906 27.9276 19.9833 27.9314C19.5606 28.1508 19.2562 28.4021 19.3053 28.4933C19.3487 28.5761 19.6682 28.4963 20.0417 28.3161L20.3617 31.884C20.3655 31.884 22.0408 32.2366 22.1719 32.1351Z" fill="#3D45EF" />
    <path d="M18.5867 0.0512169C8.47769 0.779388 0.397876 9.56392 0.0141779 20.1921C-0.221944 26.8696 2.50822 32.8731 6.93551 36.8858C8.1235 37.9626 9.2082 39.1633 10.2043 40.4337C13.2297 44.2837 18.1292 51.8133 19.996 62C21.8629 51.8133 26.7624 44.2915 29.7877 40.4415C30.7691 39.1865 31.8243 38.0013 32.9975 36.9478C37.2772 33.0978 40 27.3809 40 20.9978C40.0074 8.91322 30.2747 -0.793152 18.5867 0.0512169ZM20.0108 35.7936C12.1745 35.7936 5.82132 29.1238 5.82132 20.8971C5.82132 12.6703 12.1745 6.00053 20.0108 6.00053C27.8471 6.00053 34.2002 12.6703 34.2002 20.8971C34.2002 29.1238 27.8397 35.7936 20.0108 35.7936Z" fill="#FB8C00" />
  </svg>



export default PlaneTiny;