import React from "react";

const PlanePath = ({ className }) => (
  <svg width="265" height="228" viewBox="0 0 265 228" fill="none" className={className}>
    <path id="planePath" d="M0.499987 227.5C67.5 115 139 60.0003 264.5 0.500364" stroke="black" />
  </svg>

)

export default PlanePath;
