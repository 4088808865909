import React from 'react'
import { memo } from 'react'
import cx from 'classnames'
import { Slide } from 'react-reveal'
import Parser from 'html-react-parser'

import './common.scss'

const Modal = ({
  children,
  isShow,
  onClose,
  className = '',
  contentClassName = '',
  isOuterCloseButton,
  isFullScreen,
  isSlidable,
  title
}) => {

  const handleCloseModal = () => {
    onClose()
  }

  const modalContent = <div
    className={cx("custom-modal", className, isFullScreen && "fullscreenModal")}
  >

    {isOuterCloseButton
      ? <div className="outerCloseButton">
        <button onClick={handleCloseModal}>
          <img src="/images/close.svg" alt="x" />
        </button>
      </div>
      : <div className="closeButton">
        <div className='logo-home'><img src="/globe.ico" alt="globe" /></div>
        {title && <h3>{Parser(title)}</h3>}
        <button onClick={handleCloseModal}>
          <img src="/icons/cross.png" alt="x" />
        </button>
      </div>
    }
    <div className={cx("modalContent", contentClassName)}>
      {children}
    </div>
  </div>

  return (isShow && <div>
    <div className="backdrop" onClick={handleCloseModal} />
    {isSlidable
      ? <Slide left duration={500}>
        {modalContent}
      </Slide>
      : <>{modalContent}</>
    }
  </div>
  )
}

export default memo(Modal)
