export const required = (value, fieldName) => value ? undefined : `Xin hãy nhập ${fieldName}`;
export const maxLength = max => value =>
  value && value.length > max ? `Cần ${max} ký tự hoặc ít hơn` : undefined
export const maxLength15 = maxLength(15)
export const number = value => value && isNaN(Number(value)) ? 'Vui lòng điền số' : undefined
export const minValue = min => value =>
  value && value < min ? `Tối thiếu là ${min}` : undefined
export const minValue18 = minValue(18)
export const email = value =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ?
    'Xin hãy nhập đúng định dạng email' : undefined
export const phoneNumber = value =>
  value && !/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/.test(value) ?
    'Định dạng SĐT chưa đúng kiểu 0123xxxxxx' : undefined
export const phoneNumberVNese = value =>
  value && !/^(0[1|3|5|7|8|9])+([0-9]{8})\b/.test(value) ?
    'Định dạng SĐT chưa đúng kiểu 0123xxxxxx' : undefined
