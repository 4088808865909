import Ajax from './ajax'

class CarAjax extends Ajax {
    list(uriTail, loading = true) {
        return this.ajax(loading).get(`/cars${uriTail ? uriTail : ''}`)
    }
}

const carAjax = new CarAjax()
export default carAjax
