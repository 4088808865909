import React, { useEffect, useState } from 'react';
import { Route } from "react-router-dom";
import { ToastContainer } from 'react-toastify';

import { API_LOCAL_HOST, API_PRODUCTION } from '../../constant/system';
import './common.scss';

const LoginLayout = ({ component: Component, ...rest }) => {
  const [apiHost, setApiHost] = useState()

  useEffect(() => {
    setApiHost(window.location.hostname.startsWith('localhost') || window.location.hostname.startsWith('127.0.0.1')
      ? API_LOCAL_HOST
      : API_PRODUCTION)
  }, [])

  return (
    <Route {...rest} render={matchProps => (
      <div className="login-layout loading">
        <div className="main">
          <Component {...{ ...matchProps, apiHost: apiHost }} />
        </div>
        <ToastContainer />
      </div>
    )}
    />
  )
}

export default LoginLayout
