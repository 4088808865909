import initialState from '../../initialState'
import authConstant from "@constant/auth";

export default function loginReducer (userAuth = initialState.userAuth, action) {
    switch (action.type) {
        case authConstant.UPDATE_USER_AUTH_INFO:
            return action.userAuth

        default:
            return userAuth
    }
}
