import React from "react";

class SystemErrorPage extends React.Component {
    render() {
        return (
            <div id="notfound">
                <div class="notfound">
                    <div class="notfound-404">
                        <h1>5<span></span>0</h1>
                    </div>
                    <h2>Oops! Hệ thống đang xảy ra lỗi</h2>
                    <p>Xin lỗi vì sự bất tiện này, xin vui lòng thử lại sau. Nếu vẫn thấy lỗi, xin báo lại với đội kỹ thuật để dược hỗ trợ</p>
                    <a href="/">Trở về trang chủ</a>
                </div>
            </div>
        )
    }
}

export default SystemErrorPage