import Ajax from './ajax'

class AuthAjax extends Ajax {
    get() {
        return this.ajax(false).get('/auth')
    }

    login(params)  {
        return this.ajax().post(`/auth`, params)
        // return this.ajax().post(`/auth/admin`, params)
    }

    logout() {
        return this.ajax().post(`/auth/logout`)
    }

    changePassword(id, params) {
        return this.ajax().post(`/auth/${id}/update`, params)
    }

    forgotPassword(params) {
        return this.ajax().post(`/forgot-password`, params)
    }
}

const authAjax = new AuthAjax()
export default authAjax
