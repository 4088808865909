import Pusher from 'pusher-js';
import React, { useEffect, useState } from 'react';
import ReactTable from "react-table";
import withFixedColumns from "react-table-hoc-fixed-columns";

import apiResult from "@util/apiResult";
import { handleError } from '@util/common';
import reportAjax from '../../ajax/report';

import { CHANNELS, EVENTS, PUSHER_KEY } from '../../constant/socket';

import { useSelector } from 'react-redux';
import { WM_FIELDS, WM_TYPES } from '../../constant/report';
import './common.scss';

const ReactTableFixedColumns = withFixedColumns(ReactTable);

const Squad = () => {
  const numberFormatter = Intl.NumberFormat('en-US');
  const userAuth = useSelector(state => state.userAuth)

  const [wms, setWms] = useState({ wms: [], total_DT_hn: 0 })
  const [dataNeedUpdate, setDataNeedUpdate] = useState(true)

  const handleEvent = () => {
    setDataNeedUpdate(true)
  }

  const getFixedColumns = () => {
    return [
      {
        Header: "WM",
        accessor: "name",
        width: 130,
        // Cell: props => {
        //   return <div className={
        //     Object.keys(props.original)
        //       .filter(key => key !== 'date')
        //       .map(key => props.original[key])
        //       .filter(value => parseInt(value) > 0).length > 0
        //       ? 'has-value' : 'no-value'
        //   }>{ currentTab == 'all' ? `Tuần ${props.original.week}` : props.value}</div>
        // }
      },
    ]
  }

  const getColumns = () => {
    return Object.keys(WM_TYPES)
      .map((type, index) => {
        const label = WM_FIELDS.find(field => field.key === type)?.label

        return ({
          Header: label,
          accessor: WM_TYPES[type],
          width: 130,
          style: {
            textAlign: 'center'
          },
          Cell: props => props.value && props.value > 0
            ? <div className='has-value'>{numberFormatter.format(props.value)}</div>
            : <div className='no-value'>0</div>,
          Footer: (row) => {
            const sum = row.data
              .map(data => parseInt(data[WM_TYPES[type]]))
              .reduce((a, b) => a + b, 0);

            return sum && parseInt(sum) > 0
              ? <div className='has-value'>{numberFormatter.format(sum)} <br /><span>{label}</span></div>
              : <div className='no-value'>0 <br /><span>{label}</span></div>
          }
        })
      }
      )
  }

  useEffect(() => {
    const pusher = new Pusher(PUSHER_KEY, {
      cluster: 'ap1'
    });

    const channel = pusher.subscribe(CHANNELS.CHANNEL_WM_ACCOUNT);
    channel.bind(EVENTS.WM_ACCOUNT_UPDATE, handleEvent);

    return () => {
      channel.unsubscribe(CHANNELS.CHANNEL_WM_ACCOUNT)
    }
  }, [])

  useEffect(() => {
    const getReports = () => {
      reportAjax.getWmsAdmin(`?email=${userAuth.email}&id=${userAuth.user_id}`)
        .then(res => setWms(apiResult.success(res)))
        .catch(handleError)
    }

    if (dataNeedUpdate) {
      getReports()
      setDataNeedUpdate(false)
    }
  }, [dataNeedUpdate, userAuth])

  const tableData = wms.wms

  return <div className="squad-page" style={{ width: "100%" }}>
    <div className='squad-page-content'>
      <section>
        {/* <h4 id="leaderboard"></h4> */}
        <div className='table-row'>
          <div className='table-column'>
            <div className='table-block total-table'>
              <table className='table table-striped table-bordered'>
                <thead>
                  <tr>
                    <th>WM Hà Nội</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <ul>
                        <li>Tổng số WM: <strong>{wms?.wms.length}</strong></li>
                        {/* <li>Tổng điểm: <strong>{numberFormatter.format(wms.total_DT_hn)} / {numberFormatter.format(20000)}</strong></li> */}
                      </ul>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>

      <section>
        <ReactTableFixedColumns
          key={dataNeedUpdate}
          data={tableData}
          columns={[
            {
              Header: "",
              fixed: "left",
              columns: getFixedColumns()
            },
            {
              Header: "",
              columns: getColumns()
            },
          ]}
          defaultPageSize={25}
          style={{ height: "60vh", minHeight: 350 }}
          className="-striped"
          previousText={"< Trước"}
          nextText={"Sau >"}
          loadingText="Đang tải..."
          rowsText='dòng/trang'
          pageText="Trang"
          ofText="/"
        />

      </section>
    </div>

  </div>
}

export default Squad