import React, { useEffect, useState } from 'react';
import { Slide } from 'react-reveal'
import cx from 'classnames';

import './CarDriving.scss';
import '../SummaryPage.scss';

const TAB_VALUES = {
  car_images: 'car_images',
  car_leaderboards: 'car_leaderboards'
}

const TABS = [
  {
    id: TAB_VALUES.car_images,
    value: TAB_VALUES.car_images,
    label: 'Hình ảnh'
  },
  {
    id: TAB_VALUES.car_leaderboards,
    value: TAB_VALUES.car_leaderboards,
    label: 'Bảng xếp hạng'
  },
]

const WHEEL_VALUES = {
  BCHC: 'bc_hc',
  TP: 'tp',
  TRUDO: 'sharing_health',
  EDU: 'education_health',
}

const WHEEL_LABELS = {
  BCHC: 'BCHC',
  TP: '1L/4L',
  TRUDO: 'Chia sẻ',
  EDU: 'Giáo dục',
}

const WHEELS = [
  {
    value: WHEEL_VALUES.BCHC,
    label: WHEEL_LABELS.BCHC
  },
  {
    value: WHEEL_VALUES.TP,
    label: WHEEL_LABELS.TP
  },
  {
    value: WHEEL_VALUES.TRUDO,
    label: WHEEL_LABELS.TRUDO
  },
  {
    value: WHEEL_VALUES.EDU,
    label: WHEEL_LABELS.EDU
  },
]

const CarDriving = ({ carParams, userAuth }) => {
  const [isLoading, setLoading] = useState(true)
  const [currentTab, setCurrentTab] = useState(TAB_VALUES.car_images)
  const [currentWheel, setCurrentWheel] = useState(WHEEL_VALUES.BCHC)

  const getColorClassName = (percentRaw) => {
    const percent = parseFloat(percentRaw)
    if (percent < 50) {
      return "red";
    }
    if (percent >= 50 && percent < 100) {
      return "yellow";
    }
    if (percent >= 100) {
      return "green";
    }
  }

  const displayBriefCar = (info, index) => {
    return <div className="car-bg" key={info.zion.zion_id}>
      <div className="zion-name">{index}. {info?.zion.name}</div>
      <Slide top duration={500}>
        <div className="car-parameter">
          <img src="/images/carDriving/car-clock.svg" alt="" />
          <img
            style={info ? { transform: `rotate(${22.5 * (info.active_gskt > 10 ? 10.1 : info.active_gskt)}deg)` } : {}}
            className="watch-hand"
            src="/images/carDriving/car-watch-hand.svg"
            alt=""
          />
          {info && <Slide right>
            <strong>{info?.active_gskt ? `${info?.active_gskt}.0` : 0}</strong>
          </Slide>}
        </div>
      </Slide>
      {info && <Slide right duration={700} >
        <div className={cx("box-info first", getColorClassName(info?.bc_hc))} >
          <i className="fa fa-calendar-check-o" />
          BCHC:
          <span>{info?.bc_hc}đ</span>
        </div>
      </Slide>}
      {info && <Slide right duration={700} >
        <div className={cx(getColorClassName(info?.tp?.percent_4L), "box-info second")}>
          <i className="fa fa-line-chart" />
          1L/4L:
          <span>{info?.tp?.percent_1L}</span>
          /
          <span>{info?.tp?.percent_4L}</span>
        </div>
      </Slide>}
      {info && <Slide right duration={700} >
        <div className="box-info third">
          <i className="fa fa-share-alt-square" />
          Chia sẻ: <span>{info?.sharing_health}</span>
        </div>
      </Slide>}
      {info && <Slide right duration={700}>
        <div className="box-info fourth">
          <i className="fa fa-medkit" />
          Giáo dục: <span>{info?.education_health}</span>
        </div>
      </Slide>}
      {info && <Slide bottom duration={800}>
        <div className="car-image">
          <img src="/images/carDriving/car.svg" alt="" />
        </div>
      </Slide>}
    </div>
  }

  useEffect(() => {
    if (carParams && isLoading) {
      setLoading(false)
    }
  }, [carParams, isLoading])

  return (
    <div className="carDriving">
      {isLoading && <div>Đang tải...</div>}
      {!isLoading && userAuth?.user_id && userAuth.user_id !== 2 &&
        <div className="car-bg">
          <Slide top duration={1000}>
            <div className="car-parameter">
              <img src="/images/carDriving/car-clock.svg" alt="" />
              <img
                style={carParams ? { transform: `rotate(${22.5 * (carParams.active_gskt > 10 ? 10.1 : carParams.active_gskt)}deg)` } : {}}
                className="watch-hand"
                src="/images/carDriving/car-watch-hand.svg"
                alt=""
              />
              {carParams && <Slide right>
                <strong>{carParams?.active_gskt ? `${carParams?.active_gskt}.0` : 0}</strong>
              </Slide>}
            </div>
          </Slide>
          {carParams && <Slide left duration={700} delay={800}>
            <div className={cx("box-info first", getColorClassName(carParams?.bc_hc))} >
              <i className="fa fa-calendar-check-o" />
              BCHC:
              <span>{carParams?.bc_hc}đ</span>
            </div>
          </Slide>}
          {carParams && <Slide left duration={700} delay={1000}>
            <div className={cx(getColorClassName(carParams?.tp?.percent_4L), "box-info second")}>
              <i className="fa fa-line-chart" />
              1L/4L:
              <span>{carParams?.tp?.percent_1L}</span>
              /
              <span>{carParams?.tp?.percent_4L}</span>
            </div>
          </Slide>}
          {carParams && <Slide right duration={700} delay={1400}>
            <div className="box-info third">
              <i className="fa fa-share-alt-square" />
              Chia sẻ: <span>{carParams?.sharing_health}</span>
            </div>
          </Slide>}
          {carParams && <Slide right duration={700} delay={1200}>
            <div className="box-info fourth">
              <i className="fa fa-medkit" />
              Giáo dục: <span>{carParams?.education_health}</span>
            </div>
          </Slide>}
          {carParams && <Slide bottom duration={1000}>
            <div className="car-image">
              <img src="/images/carDriving/car.svg" alt="" />
            </div>
          </Slide>}
        </div>
      }

      {!isLoading && userAuth?.user_id && userAuth.user_id === 2 && carParams && <div>
        <ul className="nav nav-tabs">
          {TABS.map(tab =>
            <div key={tab.value} onClick={() => setCurrentTab(tab.value)} className={"nav-link " + (currentTab === tab.value && 'active')}>{tab.label}</div>
          )}
        </ul>

        {currentTab === TAB_VALUES.car_images && <div className="all-cars">
          <div className='cars-block'>
            <div className='block-name'>Chi hội</div>
            {carParams.branch.map((item, index) => displayBriefCar(item, index + 1))}
          </div>
          <div className='cars-block'>
            <div className='block-name'>Tráng niên</div>
            {carParams.men.map((item, index) => displayBriefCar(item, index + 1))}
          </div>
          <div className='cars-block'>
            <div className='block-name'>Thanh niên nam</div>
            {carParams.boys.map((item, index) => displayBriefCar(item, index + 1))}
          </div>
          <div className='cars-block'>
            <div className='block-name'>Phụ nữ</div>
            {carParams.women.map((item, index) => displayBriefCar(item, index + 1))}
          </div>
          <div className='cars-block'>
            <div className='block-name'>Thanh niên nữ</div>
            {carParams.girls.map((item, index) => displayBriefCar(item, index + 1))}
          </div>
        </div>}

        {currentTab === TAB_VALUES.car_leaderboards && <div>
          <div className='summary-tabs'>
            {WHEELS.map(tab =>
              <button
                key={tab.value}
                className={"btn " + (currentWheel === tab.value ? 'btn-primary btn-primary-custom' : 'btn-default')}
                onClick={() => setCurrentWheel(tab.value)}
              >
                {tab.label}
              </button>
            )}
          </div>

          <Slide bottom>
            <main>
              <div id="headerBoard">
                <h6>[BXH] {WHEELS.find(wheel => wheel.value === currentWheel)?.label}</h6>
              </div>
              <div id="leaderboard">
                <div className="ribbon"></div>
                <table>
                  <tbody>
                    {carParams.leaderboard[currentWheel].map((item, index) =>
                      <tr key={item.zion.zion_id}>
                        <td className="number">{index + 1}</td>
                        <td className="name">{item.zion.name}</td>
                        <td className="points">
                          {item[currentWheel]?.toFixed(2).replace('.', ',')}
                          {index === 0 && <img className="gold-medal" src="https://github.com/malunaridev/Challenges-iCodeThis/blob/master/4-leaderboard/assets/gold-medal.png?raw=true" alt="gold medal" />}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </main>
          </Slide>
        </div>}
      </div>}
    </div >
  )
}

export default CarDriving;