import Ajax from './ajax'

class FormResponseAjax extends Ajax {
    list(uriTail, loading = true) {
        return this.ajax(loading).get(`/form-responses${uriTail ? uriTail : ''}`)
    }

    create(data) {
        return this.ajax().post(`/form-responses`, data)
    }

    update(id, data) {
        return this.ajax().post(`/form-responses/${id}/update`, data)
    }
}

const formResponseAjax = new FormResponseAjax()
export default formResponseAjax
