import React from 'react'
import { render } from 'react-dom'
import configureStore from './configureStore'
import { Provider } from 'react-redux'
import route from './route'
import * as serviceWorker from './serviceWorker';
import 'babel-polyfill'
import './index.scss'
import './scss/vertical-layout-light/style.scss'
import './vendors/feather/feather.css'
import "moment/locale/vi";
import "react-table/react-table.css";
import "react-table-hoc-fixed-columns/lib/styles.css";
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import 'react-calendar/dist/Calendar.css';
import moment from 'moment'


const store = configureStore()
moment.locale('en')

render(
  <Provider store={store}>
    {route}
  </Provider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls. Learn
// more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
