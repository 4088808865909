import { email, required } from "./validation";

export const FORM_INPUT_EMAIL_LOGIN = [
  {
    id: 'email',
    type: 'text',
    title: 'Email',
    value: '',
    validate: [required, email]
  },
  {
    id: 'password',
    type: 'password',
    title: 'Mật khẩu',
    value: '',
    validate: [required]
  },
];