// import { gsap } from 'gsap';
// import MotionPathPlugin from 'gsap/dist/MotionPathPlugin';
import Pusher from 'pusher-js';
import React, { useEffect, useState } from 'react';

import formAjax from '@ajax/form';
import apiResult from '@util/apiResult';
import { handleError, handleSuccess } from '@util/common';
import { CHANNELS, EVENTS, PUSHER_KEY } from '../../constant/socket';

import PlanePath from './PlanePath';
import PlaneTiny from './PlaneTiny';

import { FORM_FIELD_TYPE } from '../../constant/form';
import './common.scss';

const kmTotal = 2700;

const Event2700km = () => {
  // const timelineInstance = useRef()

  const [kmDone, setKmDone] = useState(0)
  const [createdForms, setCreatedForms] = useState([])
  const [isSetupShowing, setSetupShowing] = useState(false)

  const [currentFormId, setCurrentFormId] = useState()
  const [currentFieldId, setCurrentFieldId] = useState()

  const [tempFormId, setTempFormId] = useState()
  const [tempFieldId, setTempFieldId] = useState()

  const [dataNeedUpdate, setDataNeedUpdate] = useState(0)

  const getResponses = (formId, fieldId) => {
    try {
      formAjax.listResponses(formId)
        .then(apiResponse => {
          const formResponses = apiResult.success(apiResponse)

          const total = formResponses
            .map(formRes => formRes.content)

          const totalWithIds = total.map(content => content.find(field => field.id === fieldId))

          if (totalWithIds.filter(i => i).length > 0) {
            const totalFinal = totalWithIds.map(field => field.value)
              .reduce((a, b) => parseFloat(a) + parseFloat(b), 0)

            setKmDone(totalFinal)
          }
        })
        .catch(handleError)
    } catch (error) {
      handleError(error)
    }
  }

  const getLocalStorageData = (key, callback) => {
    try {
      const raw = localStorage.getItem(key)
      const data = raw ? JSON.parse(raw) : raw
      if (data && (typeof callback === 'function')) callback(data)
      return data
    } catch (err) {
      console.error(err);
    }
  }

  const getForms = () => {
    formAjax.list()
      .then(apiResponse => {
        setCreatedForms(apiResult.success(apiResponse)?.created_forms)
      })
      .catch(handleError)
  }

  const getPercent = (a, b) => {
    return (a / b * 100).toFixed(2)
  }

  const percentDone = getPercent(kmDone, kmTotal)

  // const setupAnimation = () => {
  //   gsap.registerPlugin(MotionPathPlugin);
  //   MotionPathPlugin.convertToPath('#planePath');

  //   timelineInstance.current = gsap
  //     .to('#planeTiny', {
  //       duration: 4,
  //       // repeat: -1,
  //       ease: "power1.inOut",
  //       motionPath: {
  //         path: '#planePath',
  //         align: '#planePath',
  //         autoRotate: false,
  //         alignOrigin: [0.5, 0.5],
  //         start: 0,
  //         end: percentDone / 100
  //       },
  //     })
  // }

  const handleEvent = (e) => {
    setDataNeedUpdate(prevState => prevState += 1)
  }

  const getSelectedForm = () => {
    if (tempFormId) {
      return createdForms.find(form => parseInt(form.form_id) === parseInt(tempFormId))
    }

    if (currentFormId) {
      return createdForms.find(form => parseInt(form.form_id) === parseInt(currentFormId))
    }
  }

  const handleSubmit = () => {
    try {
      if (tempFormId && tempFieldId) {
        localStorage.setItem('savedFormId', JSON.stringify(tempFormId))
        localStorage.setItem('savedFieldId', JSON.stringify(tempFieldId))

        setCurrentFormId(tempFormId)
        setCurrentFieldId(tempFieldId)

        setTimeout(() => {
          handleSuccess("Lưu thành công")
        }, 1000)
      }
    } catch (e) {
      // left blank
    }
  }

  const clearSelection = () => {
    setTempFormId(0)
    setTempFieldId("")
  }

  useEffect(() => {
    // setupAnimation()
    getForms()
    getLocalStorageData('savedFormId', (formId) => {
      setCurrentFormId(formId)
      setTempFormId(formId)
    })
    getLocalStorageData('savedFieldId', (fieldId) => {
      setCurrentFieldId(fieldId)
      setTempFieldId(fieldId)
    })

    const pusher = new Pusher(PUSHER_KEY, {
      cluster: 'ap1'
    });

    const channel = pusher.subscribe(CHANNELS.FORM);
    channel.bind(EVENTS.FORM_UPDATE, handleEvent);

    return () => {
      channel.unsubscribe(CHANNELS.FORM)
    }
  }, [])

  // useEffect(() => {
  //   setupAnimation()
  // }, [kmDone])

  useEffect(() => {
    if (currentFormId && currentFieldId) {
      getResponses(currentFormId, currentFieldId)
    }
  }, [dataNeedUpdate, currentFormId, currentFieldId]);

  const selectedForm = getSelectedForm()
  const submitable = tempFormId && tempFieldId

  return (
    <div className="event2700km">
      <h3 className="pageTitle">2700 KM</h3>
      <div className="map-box">
        <div className="map-vn-hq" style={{ backgroundImage: `url("images/hanoi_hanquoc.png")` }}>
          <div className="path-block">
            <PlanePath />
            <PlaneTiny />
          </div>
        </div>
        <div className="flight-info">
          <strong>Thông số chuyến bay</strong>
          <hr />
          <ul>
            <li>
              Chặng đường: <strong>{kmTotal} km</strong>
            </li>
            <li>Đã đi: <strong>{kmDone} km ({percentDone}%)</strong></li>
            <li>Còn lại: <strong>{kmTotal - kmDone} km ({100 - percentDone}%)</strong></li>
          </ul>

          <div className="setup-parameter" onClick={() => setSetupShowing(prevState => !prevState)}>
            Cài đặt <i className={`fa ${isSetupShowing ? "fa-chevron-up" : "fa-chevron-down"}`} />
          </div>

          {isSetupShowing && <>
            <p><small><i>Xin chọn biểu mẫu và cột sẽ lấy dữ liệu cho bản đồ.</i></small></p>
            <div className="parameters-box">
              <div className="form-group">
                <label>Biểu mẫu</label>
                <select
                  onChange={e => {
                    setTempFormId(e.target.value)
                    setTempFieldId(null)
                  }}
                  value={tempFormId || currentFormId}
                  id="formSelect"
                >
                  <option value="0">-- Xin chọn biểu mẫu --</option>
                  {createdForms.map(form =>
                    <option key={form.form_id} value={form.form_id}>
                      {form.name}
                    </option>)}
                </select>
              </div>
              {selectedForm &&
                <div className="form-group">
                  <label>Tổng km lấy từ cột</label>
                  <select
                    key={selectedForm?.form_id}
                    onChange={e => setTempFieldId(e.target.value)}
                    value={tempFieldId || currentFieldId}
                    id="fieldSelect"
                  >
                    <option value="">-- Xin chọn cột tổng--</option>
                    {selectedForm?.content.filter(field => field.type === FORM_FIELD_TYPE.number).map(field => <option key={field.id} value={field.id}>
                      {field.label}
                    </option>)}
                  </select>
                </div>}

              <div>
                <button disabled={!submitable} className="btn btn-primary" onClick={handleSubmit}>Xác nhận</button>
                <button className="btn btn-default" onClick={clearSelection}>Hủy thao tác</button>
              </div>
            </div>
          </>}
        </div>
      </div>
    </div>
  )
}

export default Event2700km