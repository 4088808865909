import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"

import { INPUT_TABS, INPUT_TAB_VALUES, LEADERBOARD_AGES, LEADERBOARD_BRANCH_TYPES, LEADERBOARD_INDIVIDUAL_TYPES, LEADERBOARD_RANKED_KEY } from "../../../../constant/report"

import './Leaderboard.scss'

const Leaderboard = () => {
  const { userAuth } = useSelector(state => state)

  const [currentTab, setCurrentTab] = useState(INPUT_TABS[0])
  const [currentAge, setCurrentAge] = useState(LEADERBOARD_AGES[0].id)
  const [rows, setRows] = useState([])
  const [ranks, setRanks] = useState([])

  const isIndividualType = currentTab.id === INPUT_TAB_VALUES.individual
  const isBranchType = currentTab.id === INPUT_TAB_VALUES.branch

  const handleChangeTab = (tab) => {
    setCurrentAge(LEADERBOARD_AGES[0].id)
    setCurrentTab(tab)
  }

  const handleChangeAge = (age) => {
    setCurrentAge(age)
  }

  const displayLeaderboardHeading = () => {
    if (currentTab.id === INPUT_TAB_VALUES.branch) {
      return 'Chi Hội'
    }

    if (currentTab.id === INPUT_TAB_VALUES.group) {
      return `Địa vực - ${LEADERBOARD_AGES.find(age => age.id === currentAge)?.label}`
    }

    if (currentTab.id === INPUT_TAB_VALUES.individual) {
      return `Cá nhân - ${LEADERBOARD_AGES.find(age => age.id === currentAge)?.label}`
    }

    return ''
  }

  const displayPoint = (rankedRow) => {
    const numberFormatter = Intl.NumberFormat('en-US');

    const point = isIndividualType
      ? rankedRow[LEADERBOARD_BRANCH_TYPES.total_point]
      : rankedRow[LEADERBOARD_RANKED_KEY]

    const rawPoint = parseFloat(point)?.toFixed(2)
    return [numberFormatter.format(rawPoint.split('.')[0]), rawPoint.split('.')[1]].join('.')
  }

  useEffect(() => {
    if (userAuth?.user_id && userAuth.leaderboard) {
      if (currentTab.id === INPUT_TAB_VALUES.branch) {
        setRows(userAuth.leaderboard.leaderboard_branch)
      } else if (currentTab.id === INPUT_TAB_VALUES.group) {
        setRows(userAuth.leaderboard?.leaderboard_group?.[currentAge] || [])
      } else if (currentTab.id === INPUT_TAB_VALUES.individual) {
        setRows(userAuth.leaderboard?.leaderboard_individual?.[currentAge] || [])
      }
    }
  }, [userAuth, currentTab, currentAge])

  useEffect(() => {
    // const rowWithPoint = isBranchType
    //   ? rows.map(branch => {
    //     let totalPoint = 0
    //     let groups = []
    //     console.log('branch-----------', branch)
    //     Object.keys(LEADERBOARD_AGE_TYPES).map(key => {
    //       console.log('age', key)
    //       console.log('currentPoint', totalPoint)
    //       console.log('list', userAuth?.leaderboard?.leaderboard_group?.[key]?.filter(group => group.branch_id === branch.id))
    //       const validGroups = userAuth?.leaderboard?.leaderboard_group?.[key]?.filter(group => group.branch_id === branch.id)
    //       groups.push(validGroups)
    //       totalPoint = totalPoint + validGroups.reduce((sum, { total_point }) => sum + parseFloat(total_point), 0) || 0
    //       return key
    //     })

    //     console.log('final point--------', totalPoint)
    //     return {
    //       ...branch,
    //       total_point: totalPoint
    //     }
    //   })
    //   : rows
    const rowWithPoint = rows

    const validRows = rowWithPoint.filter(row => {
      return row[LEADERBOARD_BRANCH_TYPES.total_point] && (
        isIndividualType
          ? (row[LEADERBOARD_INDIVIDUAL_TYPES.person_name] && row[LEADERBOARD_INDIVIDUAL_TYPES.group_name])
          : (row[LEADERBOARD_BRANCH_TYPES.name] && row[LEADERBOARD_BRANCH_TYPES.tp_4l])
      )
    }
    )

    const calculatedRows = validRows.map(row => (
      {
        ...row,
        [LEADERBOARD_RANKED_KEY]: isIndividualType
          ? row[LEADERBOARD_BRANCH_TYPES.total_point]
          : row[LEADERBOARD_BRANCH_TYPES.total_point] / row[LEADERBOARD_BRANCH_TYPES.tp_4l]
      }
    ))

    calculatedRows.sort((rowA, rowB) => {
      return rowB[LEADERBOARD_RANKED_KEY] - rowA[LEADERBOARD_RANKED_KEY]
    })

    setRanks(calculatedRows)
  }, [rows, isIndividualType, isBranchType, userAuth.leaderboard.leaderboard_group])

  return (
    <div className="leaderboard-block">
      <ul className="nav nav-tabs">
        {INPUT_TABS.map(tab => (
          <li key={tab.id} className="nav-item" onClick={() => handleChangeTab(tab)}>
            <div className={"nav-link " + (currentTab.id === tab.id && ' active')}>{tab.label}</div>
          </li>
        ))}
      </ul>

      {currentTab.id !== INPUT_TAB_VALUES.branch && (
        <div className="age-select">
          Theo độ tuổi:
          <select onChange={e => handleChangeAge(e.target.value)} value={currentAge}>
            {LEADERBOARD_AGES.map(age => (
              <option key={age.id} value={age.id}>{age.label}</option>
            ))}
          </select>
        </div>
      )}
      <main>
        <div id='headerBoard'>
          <h6>[Top #10] {displayLeaderboardHeading()}</h6>
        </div>

        {ranks.length === 0 && <div className="no-data">Đang cập nhật...</div>}

        {ranks.length > 0 && <div id='leaderboard'>
          <div className='ribbon'></div>
          <table>
            <tbody>
              {ranks.slice(0, 10).map((rankedRow, index) =>
                <tr key={rankedRow.id}>
                  <td className='number'>{index + 1}</td>
                  <td className='name'>{isIndividualType ? rankedRow[LEADERBOARD_INDIVIDUAL_TYPES.person_name] : rankedRow[LEADERBOARD_BRANCH_TYPES.name]}</td>
                  {isIndividualType && <td className='name'>{rankedRow[LEADERBOARD_INDIVIDUAL_TYPES.group_name]}</td>}
                  <td className='points'>
                    {displayPoint(rankedRow)}
                    {/* {index === 0 && <img className='gold-medal' src="https://github.com/malunaridev/Challenges-iCodeThis/blob/master/4-leaderboard/assets/gold-medal.png?raw=true" alt="gold medal" />} */}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>}
      </main>
    </div>
  )
}

export default Leaderboard