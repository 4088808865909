import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import moment, * as Moment from 'moment';
import { extendMoment } from 'moment-range';
import Pusher from 'pusher-js';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ReactTable from "react-table";
import withFixedColumns from "react-table-hoc-fixed-columns";

import carAjax from '@ajax/car';
import reportAjax from '@ajax/report';
import userConstant from '@constant/user';
import apiResult from '@util/apiResult';
import { handleError } from '@util/common';

import CarDriving from './CarDriving/CarDriving';

import { CURRENT_STATUS_REPORT_FIELDS, CURRENT_STATUS_REPORT_TYPES, DAILY_REPORT_FIELDS, EIGHT_STEPS_REPORT_FIELDS, EIGHT_STEPS_REPORT_TYPES, MONTHLY_GENERAL_REPORT_FIELDS, MONTHLY_GENERAL_REPORT_TYPES_SHORT, REPORT_RANGES, REPORT_TYPES, WEEKLY_EDU_REPORT_FIELDS, WEEKLY_EDU_REPORT_TYPES, WEEKLY_GENERAL_REPORT_FIELDS, WEEKLY_GENERAL_REPORT_TYPES_SHORT } from '../../constant/report';
import { CHANNELS, EVENTS, PUSHER_KEY } from '../../constant/socket';

import "./SummaryPage.scss";

const ReactTableFixedColumns = withFixedColumns(ReactTable);

const allYearKey = 'all-year'
const lastYearKey = 'last-year'
const dataInPercent = [
  MONTHLY_GENERAL_REPORT_TYPES_SHORT.new_member,
  MONTHLY_GENERAL_REPORT_TYPES_SHORT.member_1,
  MONTHLY_GENERAL_REPORT_TYPES_SHORT.member_2,
  MONTHLY_GENERAL_REPORT_TYPES_SHORT.NTD_edu,
  MONTHLY_GENERAL_REPORT_TYPES_SHORT.role_member,
]
const currentMonth = moment().format('MM')

const SummaryPage = () => {
  const numberFormatter = Intl.NumberFormat('en-US');
  const { userAuth } = useSelector(state => state)
  const [currentTab, setCurrentTab] = useState('all') // or one
  const [reportRange, setReportRange] = useState(REPORT_RANGES.EIGHT_STEPS)

  const [date, setDate] = useState()
  const [reports, setReports] = useState([])

  const [isLNT2023Filter, setLNT2023Filter] = useState(false)
  const [dataNeedUpdate, setDataNeedUpdate] = useState(0)
  const [dateRange, onChangeDateRange] = useState([null, null]);
  const [isDateRangeFilter, setDateRangeFilter] = useState(false);

  const [isMonthFilter, setMonthFilter] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState(null)

  const [carParams, setCarParams] = useState(null);

  const reloadDependencies = [
    currentTab,
    date,
    reportRange,
    isLNT2023Filter,
    isDateRangeFilter,
    dataNeedUpdate,
    dateRange,
    selectedMonth,
    isMonthFilter
  ]

  const clearFilter = () => {
    setLNT2023Filter(false)
    setDateRangeFilter(false)
    onChangeDateRange(null)
    setMonthFilter(false)
    setSelectedMonth(null)
  }

  const getDaysArrayByMonth = (subMonth = 0) => {
    var daysInMonths = moment().subtract(subMonth, 'months').daysInMonth();
    const now = moment().subtract(subMonth, 'months');
    var arrDays = [];

    while (daysInMonths) {
      var current = moment().subtract(subMonth, 'months').date(daysInMonths);
      if (current.isBefore(now.endOf(subMonth > 0 ? 'month' : 'day'))) {
        arrDays.push({
          label: current.format('YYYY/MM/DD'),
          key: current.format('YYYY-MM-DD'),
        });
      }
      daysInMonths--;
    }

    return arrDays;
  }

  const getDaysArrayByThursday = (subMonth = 0) => {
    var daysInMonth = moment().subtract(subMonth, 'months').daysInMonth();
    var arrDays = [];

    while (daysInMonth) {
      var current = moment().subtract(subMonth, 'months').date(daysInMonth);
      if (current.weekday() === 4) {
        arrDays.push({
          label: current.format('MM-DD'),
          key: current.format('YYYY-MM-DD'),
          date: current.format('MM-DD'),
        });
      }
      daysInMonth--;
    }

    return arrDays.map((item, index) => ({ ...item, week: <>Tuần {arrDays.length - index}</> })).slice(arrDays.length > 4 ? 1 : 0);
  }

  const generateUrlParams = useCallback(() => {
    const tab = `tab=${currentTab}`
    const dateParam = `&date=${date || ''}`
    const range = `&range=${reportRange || ''}`
    const isLnt2023 = isLNT2023Filter ? '&is_lnt_2023=1' : ''
    const dateRangeParam = isDateRangeFilter && dateRange ? `&start_date=${moment(dateRange[0]).format('YYYY-MM-DD')}&end_date=${moment(dateRange[1]).format('YYYY-MM-DD')}` : ''
    const month = isMonthFilter && selectedMonth ? `&month=${selectedMonth}` : ''

    return `?${tab}${dateParam}${range}${isLnt2023}${dateRangeParam}${month}`
  }, [currentTab, date, dateRange, isDateRangeFilter, isLNT2023Filter, isMonthFilter, reportRange, selectedMonth])

  const getReports = useCallback(() => {
    const params = generateUrlParams()
    reportAjax.list(params)
      .then(res => {
        setReports(apiResult.success(res))
      })
      .catch(handleError)
  }, [generateUrlParams])

  const getCarParams = () => {
    carAjax.list()
      .then(res => {
        setCarParams(apiResult.success(res))
      })
      .catch(handleError)
  }

  const getDaysInAMonth = (month = +Moment().format("MM") - 1, year = +Moment().format("YYYY")) => {
    const moment = extendMoment(Moment);
    const startDate = moment([year, month]);

    const firstDay = moment(startDate).startOf('month')
    const endDay = moment(startDate).endOf('month')

    const monthRange = moment.range(firstDay, endDay)
    const weeks = [];
    const days = Array.from(monthRange.by('day'));
    days.forEach(it => {
      if (!weeks.includes(it.week())) {
        weeks.push(it.week());
      }
    })

    const calendar = []
    weeks.forEach(week => {
      const firstWeekDay = moment([year, month]).week(week).day(0).isAfter(moment([year, month]).startOf('month'))
        ? moment([year, month]).week(week).day(0)
        : moment([year, month]).startOf('month')
      const lastWeekDay = moment([year, month]).week(week).day(6).isBefore(moment([year, month]).endOf('month'))
        ? moment([year, month]).week(week).day(6)
        : moment([year, month]).endOf('month')
      calendar.push({
        startDate: firstWeekDay,
        endDate: lastWeekDay,
        label: `${firstWeekDay.format('MM/DD')} ~ ${lastWeekDay.format('MM/DD')}`,
        key: lastWeekDay.format('YYYY-MM-DD')
      })
    })

    return calendar.reverse().map((item, index) => ({ ...item, week: <>Tuần {calendar.length - index}</> }));
  }

  const getMonthsInYear = (subYear = 0) => {
    const months = []

    for (let i = 12; i > 0; i--) {
      months.push({
        label: moment().subtract(subYear, 'years').format(`YYYY/${String(i).padStart(2, '0')}`),
        key: moment().subtract(subYear, 'years').format(`YYYY-${String(i).padStart(2, '0')}-01`)
      })
    }

    return months;
  }

  const handleDaily = () => {
    return parseInt(moment().format('DD')) <= 3
      ? [...getDaysArrayByMonth(), ...getDaysArrayByMonth(1)]
      : getDaysArrayByMonth()
  }

  const handleEduMonths = () => {
    if (selectedMonth) {
      return getDaysArrayByThursday(
        selectedMonth
          ? moment().month() + 1 === 1 && selectedMonth > 1 // tháng 1 có thể hiển thị theo tháng của năm ngoái
            ? (13 - selectedMonth)
            : (moment().month() + 1 - selectedMonth)
          : 0
      )
    }

    return [1, 2, 3].includes(moment().date())
      ? [...getDaysArrayByThursday(), ...getDaysArrayByThursday(1)]
      : getDaysArrayByThursday()
  }

  const getDisplayDates = () => {
    switch (reportRange) {
      case REPORT_RANGES.DAILY:
        return handleDaily()
      case REPORT_RANGES.EIGHT_STEPS:
        return handleDaily()
      case REPORT_RANGES.WEEKLY_EDU:
        return handleEduMonths()
      case REPORT_RANGES.WEEKLY_GENERAL:
        return [1, 2, 3].includes(moment().date())
          ? [...getDaysInAMonth(), ...getDaysInAMonth(moment().month() - 1)]
          : getDaysInAMonth()
      case REPORT_RANGES.MONTHLY_GENERAL:
        // Thông số năm trước sẽ hiện hết tháng 1 năm sau
        const months = parseInt(moment().format('MM')) === 1
          ? [...getMonthsInYear(), ...getMonthsInYear(1)]
          : getMonthsInYear()

        return [{
          label: allYearKey,
          key: allYearKey,
        },
        ...months,
        {
          label: lastYearKey,
          key: lastYearKey
        }]
      default:
        return []
    }
  }

  const getReportDates = () => {
    // if (isLNT2023Filter) {
    //   const momentRange = extendMoment(Moment);

    //   const startDate = moment(FESTIVAL_LNT_START_DATE)
    //   const endDate = moment(FESTIVAL_LNT_END_DATE)

    //   return Array.from(momentRange.range(startDate, endDate.isSameOrBefore(moment()) ? endDate : moment()).by('day')).map(day => ({
    //     label: day.format('MM/DD'),
    //     key: day.format('YYYY-MM-DD'),
    //   })).reverse();
    // }

    if (isDateRangeFilter && dateRange && dateRange[0] && dateRange[1]) {
      const momentRange = extendMoment(Moment);

      const startDate = moment(dateRange[0])
      const endDate = moment(dateRange[1])

      return Array.from(
        momentRange.range(
          startDate,
          endDate.isSameOrBefore(moment()) ? endDate : moment()
        )
          .by('day')
      )
        .map(day => ({
          label: day.format('MM/DD'),
          key: day.format('YYYY-MM-DD'),
        })).reverse();
    }

    return getDisplayDates();
  }

  const handleEvent = (e) => {
    setDataNeedUpdate(prevState => prevState += 1)
  }

  useEffect(() => {
    getReports()
    if (userAuth?.is_hm) {
      getCarParams()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...reloadDependencies]);

  useEffect(() => {
    const pusher = new Pusher(PUSHER_KEY, {
      cluster: 'ap1'
    });

    const channel = pusher.subscribe(CHANNELS.SUMMARY);
    channel.bind(EVENTS.UPDATE, handleEvent);

    return () => {
      channel.unsubscribe(CHANNELS.SUMMARY)
    }
  }, [])

  useEffect(() => {
    if (!isDateRangeFilter) {
      onChangeDateRange(null)
    }
  }, [isDateRangeFilter])

  useEffect(() => {
    clearFilter()
    setCurrentTab('all')
  }, [reportRange])

  const dates = getReportDates()
  const shortDates = [...dates].map((date, index) => reportRange === REPORT_RANGES.MONTHLY_GENERAL
    ? (index !== 0 && index !== dates.length - 1) ? date.key.substr(0, 7) : date.key
    : date.key.substr(5)
  )

  const tableData = currentTab === 'all'
    ? reports
      .filter(reportRow => shortDates.includes(reportRow.date))
      .map((reportRow, index) => {
        return ({
          date: reportRow.date, ...reportRow.detail,
          week:
            dates.find(date => {
              const shortDate = reportRange === REPORT_RANGES.MONTHLY_GENERAL
                ? date.key.substr(0, 7)
                : date.key.substr(5)
              return reportRow.date === shortDate
            })?.week || reports.filter(reportRow => shortDates.includes(reportRow.date)).length - index
        })
      })
    : reports.map((reportRow, index) => ({ zion: reportRow.zion, ...reportRow.report, week: reports.length - index }))

  const innerColumns = Object.keys(REPORT_TYPES)
    .map((type, index) => {
      const label = DAILY_REPORT_FIELDS.find(field => field.key === type)?.label

      return ({
        Header: label,
        accessor: REPORT_TYPES[type],
        width: 110,
        style: {
          textAlign: 'center'
        },
        Cell: props => props.value && props.value > 0
          ? <div className='has-value'>{numberFormatter.format(props.value)}</div>
          : <div className='no-value'>0</div>,
        Footer: (row) => {
          const sum = row.data
            .map(data => parseInt(data[REPORT_TYPES[type]]))
            .reduce((a, b) => a + b, 0);

          return sum && parseInt(sum) > 0
            ? <div className='has-value'>{numberFormatter.format(sum)} <br /><span>{label}</span></div>
            : <div className='no-value'>0 <br /><span>{label}</span></div>
        }
      })
    })

  const innerColumnsEightSteps = Object.values(EIGHT_STEPS_REPORT_TYPES)
    .map((type, index) => {
      const label = EIGHT_STEPS_REPORT_FIELDS.find(field => field.key === type)?.label
      return ({
        Header: label,
        accessor: EIGHT_STEPS_REPORT_TYPES[type],
        width: 110,
        style: {
          textAlign: 'center'
        },
        Cell: props => props.value && props.value > 0
          ? <div className='has-value'>{numberFormatter.format(props.value)}</div>
          : <div className='no-value'>0</div>,
        Footer: (row) => {
          const sum = row.data
            .map(data => parseInt(data[EIGHT_STEPS_REPORT_TYPES[type]]))
            .reduce((a, b) => a + b, 0);

          return sum && parseInt(sum) > 0
            ? <div className='has-value'>{numberFormatter.format(sum)} <br /><span>{label}</span></div>
            : <div className='no-value'>0 <br /><span>{label}</span></div>
        }
      })
    })

  const innerColumnsEdu = Object.keys(WEEKLY_EDU_REPORT_TYPES)
    .map((type, index) => {
      const label = WEEKLY_EDU_REPORT_FIELDS.find(field => field.key === type)?.label
      return ({
        Header: label,
        accessor: WEEKLY_EDU_REPORT_TYPES[type],
        width: 140,
        style: {
          textAlign: 'center',
        },
        Cell: props => props.value && props.value > 0
          ? <div className='has-value'>{numberFormatter.format(props.value)}</div>
          : <div className='no-value'>0</div>,
        Footer: (row) => {
          const sum = row.data
            .map(data => parseInt(data[WEEKLY_EDU_REPORT_TYPES[type]]))
            .reduce((a, b) => a + b, 0);

          return sum && parseInt(sum) > 0
            ? <div className='has-value'>{numberFormatter.format(sum)} <br /><span>{label}</span></div>
            : <div className='no-value'>0 <br /><span>{label}</span></div>
        }
      })
    })

  const innerColumnsWeeklyGeneral = Object.keys(WEEKLY_GENERAL_REPORT_TYPES_SHORT)
    .map((type, index) => {
      const label = WEEKLY_GENERAL_REPORT_FIELDS.find(field => field.key === type)?.label

      return ({
        Header: label,
        accessor: null,
        width: 150,
        style: {
          textAlign: 'center'
        },
        Cell: props => {
          const rawValue = props?.original
          const plannedValue = rawValue[type + "_plan"]
          const resultValue = rawValue[type]
          return <div className='multirow-value'>
            <div>Kế hoạch: <span className={plannedValue > 0 ? 'has-value' : 'no-value'}>
              {numberFormatter.format(plannedValue)}
            </span></div>
            <div>Kết quả: <span className={resultValue > 0 ? 'has-value' : 'no-value'}>
              {numberFormatter.format(resultValue)}
            </span></div>
          </div>
        },
      })
    }
    )

  const innerColumnsMonthlyGeneral = Object.keys(MONTHLY_GENERAL_REPORT_TYPES_SHORT)
    .map((type, index) => {
      const label = MONTHLY_GENERAL_REPORT_FIELDS.find(field => field.key === type)?.label

      return ({
        Header: label,
        accessor: null,
        width: 150,
        style: {
          textAlign: 'center'
        },
        Cell: props => {
          const isPercentData = dataInPercent.includes(type)
          const isAllYear = props?.original?.date === allYearKey

          const rawValue = props?.original
          const plannedValue = rawValue[type + "_plan"]
          const resultValue = rawValue[type]

          return <div className='multirow-value'>
            <div>Kế hoạch: <span className={plannedValue > 0 ? 'has-value' : 'no-value'}>
              {isAllYear && isPercentData ? (plannedValue / currentMonth).toFixed(1) : numberFormatter.format(plannedValue)}{isPercentData ? '%' : ''}
            </span></div>
            <div>Kết quả: <span className={resultValue > 0 ? 'has-value' : 'no-value'}>
              {isAllYear && isPercentData ? (resultValue / currentMonth).toFixed(1) : numberFormatter.format(resultValue)}{isPercentData ? '%' : ''}
            </span></div>
          </div>
        },
      })
    }
    )

  const innerColumnsCurrentStatus = Object.keys(CURRENT_STATUS_REPORT_TYPES)
    .map((type, index) => {
      const label = CURRENT_STATUS_REPORT_FIELDS.find(field => field.key === type)?.label

      return ({
        Header: label,
        accessor: null,
        width: 130,
        style: {
          textAlign: 'center'
        },
        Cell: props => {
          const rawValue = props?.original
          const resultValue = rawValue[type]
          return <span className={resultValue > 0 ? 'has-value' : 'no-value'}>
            {numberFormatter.format(resultValue)}
          </span>
        },
        Footer: (row) => {
          const sum = row.data
            .map(data => parseInt(data['_original'][CURRENT_STATUS_REPORT_TYPES[type]]))
            .reduce((a, b) => a + b, 0);

          return sum && parseInt(sum) > 0
            ? <div className='has-value'>{numberFormatter.format(sum)} <br /><span>{label}</span></div>
            : <div className='no-value'>0 <br /><span>{label}</span></div>
        }
      })
    }
    )

  const isMultiAvailable = userAuth.roles === userConstant.ROLE_VALUES.HT
    || (userAuth.roles === userConstant.ROLE_VALUES.CSN && userAuth.user_id === 2)
    || (userAuth.roles === userConstant.ROLE_VALUES.NT && userAuth.is_hm)

  const getColumns = () => {
    switch (reportRange) {
      case REPORT_RANGES.DAILY:
        return innerColumns
      case REPORT_RANGES.EIGHT_STEPS:
        return innerColumnsEightSteps
      case REPORT_RANGES.WEEKLY_EDU:
        return innerColumnsEdu
      case REPORT_RANGES.WEEKLY_GENERAL:
        return innerColumnsWeeklyGeneral
      case REPORT_RANGES.MONTHLY_GENERAL:
        return innerColumnsMonthlyGeneral
      case REPORT_RANGES.CURRENT_STATUS:
        return innerColumnsCurrentStatus
      default:
        return null
    }
  }

  const displayTimeTag = (props) => {
    return <div>{props.value}
      <br />
      <small>
        {props?.original?.is_submitted
          ? <span style={{ color: 'green' }}>Đã hoàn thành</span>
          : props?.original?.updated_at
            ? <span style={{ color: 'navy' }}>Đã lưu tạm thời</span>
            : <span style={{ color: 'red' }}>Chưa báo cáo</span>
        }
      </small>
      <br />
      {(props?.original?.is_submitted || props?.original?.updated_at) && <small>
        &nbsp;&nbsp;({moment(props?.original?.updated_at).format('MM/DD, HH:mm')})
      </small>}
    </div>
  }

  const getFixedColumns = () => {
    const columns = [
      {
        Header: currentTab === 'all' ? (reportRange === REPORT_RANGES.MONTHLY_GENERAL ? "Tháng" : "Ngày") : "Khu vực",
        accessor: currentTab === 'all' ? "date" : 'zion',
        width: currentTab === 'all' ? ((reportRange === REPORT_RANGES.WEEKLY_GENERAL || reportRange === REPORT_RANGES.MONTHLY_GENERAL) ? 110 : 70) : 120,
        Cell: props => {
          return <div className={
            Object.keys(props.original)
              .filter(key => key !== 'date')
              .map(key => props.original[key])
              .filter(value => parseInt(value) > 0).length > 0
              ? 'has-value' : 'no-value'
          }>
            {(reportRange === REPORT_RANGES.WEEKLY_GENERAL || reportRange === REPORT_RANGES.WEEKLY_EDU) && currentTab === 'all'
              ? props.original.week
              : currentTab === 'all'
                ? <div>{props.value === allYearKey
                  ? `Cả năm ${moment().format('YYYY')}`
                  : props.value === lastYearKey
                    ? `Cuối ${(moment()).subtract(1, 'years').format('YYYY-12')}`
                    : props.value}</div>
                : displayTimeTag(props)
            }
          </div>
        }
      },
    ]

    return [REPORT_RANGES.WEEKLY_GENERAL, REPORT_RANGES.MONTHLY_GENERAL].includes(reportRange)
      ? columns
      : [{ ...columns[0], Footer: "TỔNG", }]
  }

  // const handleFilterLnt = () => {
  //   if (isDateRangeFilter && !isLNT2023Filter) {
  //     setLNT2023Filter(true)
  //     setDateRangeFilter(false)
  //     onChangeDateRange(null)
  //     return
  //   }
  //   setLNT2023Filter(prevState => !prevState)
  // }

  const handleFilterDateRange = () => {
    if (isLNT2023Filter && !isDateRangeFilter) {
      setLNT2023Filter(false)
      setDateRangeFilter(true)
      return
    }
    setDateRangeFilter(prevState => !prevState)
  }

  const isJanuary = moment().month() + 1 === 1

  return (
    <div className="summaryPage">

      {userAuth.is_hm && <>
        <h3 className="font-weight-bold">Tổng Kết</h3>
        <CarDriving userAuth={userAuth} carParams={carParams} />
      </>}

      <h3 className="font-weight-bold">{userAuth.is_hm ? "Báo cáo" : "Tổng Kết"}</h3>

      <ul className="nav nav-tabs">
        {userAuth.is_hm && <li className="nav-item" onClick={() => setReportRange(REPORT_RANGES.EIGHT_STEPS)}>
          <div className={"nav-link " + (reportRange === REPORT_RANGES.EIGHT_STEPS && 'active')}>8 bước</div>
        </li>}
        <li className="nav-item" onClick={() => setReportRange(REPORT_RANGES.WEEKLY_EDU)}>
          <div className={"nav-link " + (reportRange === REPORT_RANGES.WEEKLY_EDU && 'active')}>Giáo dục NGV</div>
        </li>
        {userAuth.is_hm && <li className="nav-item" onClick={() => setReportRange(REPORT_RANGES.WEEKLY_GENERAL)}>
          <div className={"nav-link " + (reportRange === REPORT_RANGES.WEEKLY_GENERAL && 'active')}>BC Tuần</div>
        </li>}
        {userAuth.is_hm && <li className="nav-item" onClick={() => setReportRange(REPORT_RANGES.MONTHLY_GENERAL)}>
          <div className={"nav-link " + (reportRange === REPORT_RANGES.MONTHLY_GENERAL && 'active')}>BC Tháng</div>
        </li>}
      </ul>

      {[REPORT_RANGES.DAILY].includes(reportRange) &&
        <div className='summary-filter'>
          Bộ lọc:
          <div className={`filter-item ${isDateRangeFilter ? 'active' : ''}`} onClick={handleFilterDateRange}>Từ ... đến ... </div>
          <div>
            {isDateRangeFilter && <DateRangePicker
              format='y/MM/dd'
              locale='vi'
              onChange={onChangeDateRange}
              value={dateRange}
              maxDate={new Date()}
              minDate={new Date(new Date().setDate(new Date().getDate() - 120))}
            />}
          </div>
        </div>}
      {[REPORT_RANGES.EIGHT_STEPS].includes(reportRange) &&
        <div className='summary-filter'>
          Bộ lọc:
          <div className={`filter-item ${isDateRangeFilter ? 'active' : ''}`} onClick={handleFilterDateRange}>Từ ... đến ... </div>
          <div>
            {isDateRangeFilter && <DateRangePicker
              format='y/MM/dd'
              locale='vi'
              onChange={onChangeDateRange}
              value={dateRange}
              maxDate={new Date()}
              minDate={new Date(new Date().setDate(new Date().getDate() - 120))}
            />}
          </div>
        </div>}

      {[REPORT_RANGES.WEEKLY_EDU].includes(reportRange) &&
        <div className='summary-filter'>
          Bộ lọc:
          <div className={`filter-item ${isMonthFilter ? 'active' : ''}`} onClick={() => setMonthFilter(prevState => !prevState)}>Theo tháng</div>
          <div>
            {isMonthFilter && <select onChange={e => setSelectedMonth(e.target.value)}>
              <option> --- </option>
              {
                Array.from(Array(moment().month() + 1).keys()).reverse().map(month =>
                  <option value={month + 1} key={month}>{
                    isJanuary
                      ? `${moment().year()}/${month + 1}`
                      : `Tháng ${month + 1}`}</option>)
              }
              {isJanuary && // Dùng cho tháng 1 xem được năm ngoái
                Array.from(Array(12).keys()).filter(month => month > 0).reverse().map(month =>
                  <option value={month + 1} key={month}>{`${moment().year() - 1}/${month + 1}`}</option>)
              }
            </select>}
          </div>
        </div>}

      {isMultiAvailable && reportRange !== REPORT_RANGES.CURRENT_STATUS && <div className='summary-tabs'>
        <button
          className={"btn " + (currentTab === 'all' ? 'btn-primary btn-primary-custom' : 'btn-default')}
          onClick={() => setCurrentTab('all')}
        >
          {currentTab === 'all'
            ? <b>Tất cả</b>
            : <div>Tất cả</div>
          }
        </button>
        <button
          className={"btn " + (currentTab !== 'all' ? 'btn-primary btn-primary-custom' : 'btn-default')}
          onClick={() => {
            setDate(dates[0]?.key)
            setCurrentTab('one')
          }}
        >
          {currentTab !== 'all'
            ? <b>Theo khu vực</b>
            : <div>Theo khu vực</div>
          }
        </button>
      </div>}
      {currentTab !== 'all' && <ul className="nav nav-pills">
        {dates.map((day, index) => <li className="nav-item" key={day.key}>
          <div
            className={"nav-link " + (((date === day.key) || ((!date && index === 0))) && "active")}
            aria-current={date === day.key ? "page" : ''}
            onClick={() => setDate(day.key)}
          >
            {day.week || day.label}
          </div>
        </li>)}
      </ul>}

      <div className='summary-table'>
        <ReactTableFixedColumns
          key={reportRange}
          data={tableData}
          columns={[
            {
              Header: "",
              fixed: "left",
              columns: getFixedColumns()
            },
            {
              Header: "",
              columns: getColumns()
            },
          ]}
          defaultPageSize={[REPORT_RANGES.DAILY, REPORT_RANGES.EIGHT_STEPS].includes(reportRange) ? 100 : 20}
          style={{ height: "60vh", minHeight: 350 }}
          className="-striped"
          previousText={"< Trước"}
          nextText={"Sau >"}
          loadingText="Đang tải..."
          rowsText='dòng/trang'
          pageText="Trang"
          ofText="/"
        />
      </div>
    </div>
  )
}

export default SummaryPage