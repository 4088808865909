import axios from 'axios'
import $ from 'jquery'
import { API_LOCAL_HOST, API_PRODUCTION } from '../constant/system'

const requestSettings = {
  waitingRequests: 0
}

const apiHost = window.location.hostname.startsWith('localhost') ||
  window.location.hostname.startsWith('127.0.0.1')
  ? API_LOCAL_HOST
  : API_PRODUCTION

const client = axios.create({
  baseURL: apiHost,
  withCredentials: true,
})

const onRequestStart = (loading) => {
  if (loading) requestSettings.waitingRequests += 1

  if (requestSettings.waitingRequests > 0 && !$('.boxes').hasClass('showing')) {
    $('.boxes').addClass('showing')
    $('.overlay-loading').addClass('showing')
  }
}

const onRequestEnd = (loading) => {
  if (loading && requestSettings.waitingRequests > 0) requestSettings.waitingRequests -= 1

  if (requestSettings.waitingRequests === 0 && $('.boxes').hasClass('showing')) {
    $('.boxes').removeClass('showing')
    $('.overlay-loading').removeClass('showing')
  }
}

class Ajax {
  ajax(loading = true) {
    const reqInt = client.interceptors.request.use(function (config) {
      onRequestStart(loading)
      client.interceptors.request.eject(reqInt) // run only for this request, so reject right away
      return config;
    }, function (error) {
      onRequestStart(loading)
      client.interceptors.request.eject(reqInt)
      return Promise.reject(error);
    });

    const resInt = client.interceptors.response.use(function (response) {
      onRequestEnd(loading)
      client.interceptors.response.eject(resInt) // run only for this request, so reject right away
      return response;
    }, function (error) {
      onRequestEnd(loading)
      client.interceptors.response.eject(resInt)
      return Promise.reject(error);
    });

    return client
  }
}

export default Ajax