import formAjax from '@ajax/form';
import formResponseAjax from '@ajax/formResponse';
import Pusher from 'pusher-js';
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ReactTable from "react-table";
import withFixedColumns from "react-table-hoc-fixed-columns";

import apiResult from '@util/apiResult';
import { handleSuccess, handleError } from '@util/common';
import { FORM_FIELD_TYPE, FORM_STATUS_OPTIONS, FUNCTION_HELPER } from "../../constant/form";
import { CHANNELS, EVENTS, PUSHER_KEY } from '../../constant/socket';
import { useAppContext } from "../../contexts/app";
import AddNewForm from './addNewForm/addNewForm';

import userConstant from '../../constant/user';
import './Form.scss';
import moment from 'moment';

const ReactTableFixedColumns = withFixedColumns(ReactTable);

const Form = () => {
  const userAuth = useSelector(state => state.userAuth)

  const {
    setShowModal,
    setModalContent,
    setModalTitle,
  } = useAppContext()

  const [formResponses, setFormResponses] = useState([])
  const [createdForms, setCreatedForms] = useState([])
  const [requestedForms, setRequestedForms] = useState([])
  const [formViewing, setFormViewing] = useState(null)
  const [dataNeedUpdate, setDataNeedUpdate] = useState(0)
  const [loading, setLoading] = useState(0)

  const getForms = () => {
    setLoading(prev => prev + 1)

    formAjax.list()
      .then(apiResponse => {
        setCreatedForms(apiResult.success(apiResponse)?.created_forms)
        setRequestedForms(apiResult.success(apiResponse)?.requested_forms)
      })
      .catch(handleError)
      .finally(() => {
        setLoading(prev => prev - 1)
      })
  }

  const getResponses = () => {
    setLoading(prev => prev + 1)

    formResponseAjax.list()
      .then(apiResponse => {
        setFormResponses(apiResult.success(apiResponse))
      })
      .catch(handleError)
      .finally(() => {
        setLoading(prev => prev - 1)
      })
  }

  const handleUpdateInfo = (value, form, key) => {
    if (loading > 0) return
    setLoading(prev => prev + 1)

    formAjax.update(form.form_id, JSON.stringify({ [key]: value }))
      .then(res => {
        handleSuccess('Thay đổi thành công.')
      })
      .catch(handleError)
      .finally(() => {
        setLoading(prev => prev - 1)
      })
  }

  const handleEvent = (e) => {
    setDataNeedUpdate(prevState => prevState += 1)
  }

  const handleCreateForm = (values) => {
    if (loading > 0) return
    setLoading(prev => prev + 1)

    formAjax.create(JSON.stringify(values))
      .then(response => {
        handleSuccess('Tạo mới thành công')
        setTimeout(() => {
          setShowModal(false)
        }, 3000)
      })
      .catch(handleError)
      .finally(() => {
        setLoading(prev => prev - 1)
      })
  }

  const handleEditForm = (values) => {
    if (loading > 0) return
    setLoading(prev => prev + 1)

    formAjax.update(values.form_id, JSON.stringify(values))
      .then(response => {
        handleSuccess('Cập nhật thành công.')
        setTimeout(() => {
          setShowModal(false)
        }, 3000)
      })
      .catch(handleError)
      .finally(() => {
        setLoading(prev => prev - 1)
      })
  }

  const changeResponseField = (e, index, field) => {
    let { value, type } = e.target

    if (type === FORM_FIELD_TYPE.number && !field.is_decimal) {
      value = value.replace(/^\D+/g, '')
    }

    setFormViewing(prevState => {
      return {
        ...prevState,
        content: prevState.content.map((field, i) =>
          i === index ? { ...field, value: value } : field
        )
      }
    })
  }

  const clearResponse = () => {
    setFormViewing(null)
    getForms()
    setTimeout(() => {
      setShowModal(false)
    }, 3000)
  }

  const handleCreateResponse = () => {
    if (loading > 0) return
    setLoading(prev => prev + 1)

    formResponseAjax.create(JSON.stringify(formViewing))
      .then(response => {
        setShowModal(false)
        handleSuccess('Tạo mới thành công.')
        clearResponse()
      })
      .catch(handleError)
      .finally(() => {
        setLoading(prev => prev - 1)
      })
  }

  const handleEditResponse = () => {
    if (loading > 0) return
    setLoading(prev => prev + 1)

    formResponseAjax.update(formViewing.form_response_id, JSON.stringify(formViewing))
      .then(response => {
        handleSuccess('Cập nhật thành công');
        clearResponse()
      })
      .catch(handleError)
      .finally(() => {
        setLoading(prev => prev - 1)
      })
  }

  const handleSubmitResponse = (e) => {
    e.preventDefault();
    if (formViewing.form_response_id) {
      handleEditResponse()
    } else {
      handleCreateResponse()
    }
  }

  const arrayToObject = (array) => {
    let object = {}

    array.map(item => {
      object[`id-${item.id}`] = item.value
      object[`function-helper-${item.id}`] = item.function_helper

      return item
    })

    return object
  }

  const mapDataTable = (data) => {
    return (data || []).map((formResponse, index) => ({
      index,
      zion: formResponse?.user?.zion?.name,
      updated_at: formResponse.updated_at,
      form_response_id: formResponse.form_response_id,
      ...arrayToObject(formResponse.content)
    }))
  }

  const getSpecificFormResponses = (form) => {
    formAjax.listResponses(form.form_id)
      .then(apiResponse => {
        const tableData = mapDataTable(apiResult.success(apiResponse))

        setModalTitle(form.name)
        setModalContent(<div>
          <ReactTableFixedColumns
            data={tableData}
            columns={getTableColumns(form)}
            defaultPageSize={25}
            style={{ height: "60vh", minHeight: 350 }}
            className="-striped"
            previousText={"< Trước"}
            nextText={"Sau >"}
            loadingText="Đang tải..."
            rowsText='dòng/trang'
            pageText="Trang"
            ofText="/"
          />
        </div>)
        setShowModal(true)
      })
      .catch(handleError)
  }

  const getFixedColumns = () => {
    return [
      {
        Header: "STT",
        accessor: "index",
        width: 40,
        Cell: (props) => {
          return <div style={{ textAlign: 'center' }}>
            {props.original.index + 1}
          </div>
        },
      },
      {
        Header: "Khu vực",
        accessor: "zion",
        width: 160,
        Cell: (props, index) => {
          return <div>
            {props.value}
            <br />
            <small style={{ fontSize: "12px" }}>{props?.original?.updated_at && moment(props?.original?.updated_at).format('MM/DD HH:mm:ss')}</small>
          </div>
        },
      },
    ]
  }

  const getTableColumns = (form) => {
    return [
      {
        Header: "",
        fixed: "left",
        columns: getFixedColumns()
      },
      {
        Header: "",
        columns: form.content.map(field => ({
          Header: field.label,
          accessor: `id-${field.id}`,
          width: 120,
          Cell: props => <div style={{ textAlign: "center" }}>{props.value}</div>,
          Footer: (row) => {
            if (field.function_helper === FUNCTION_HELPER.SUM) {
              const sum = row.data
                .filter(data => parseFloat(data[`id-${field.id}`]))
                .map(data => parseFloat(data[`id-${field.id}`]))
                .reduce((a, b) => a + b, 0);
              return <div style={{ textAlign: "center" }}>{`Tổng = ` + sum}</div>
            }

            return null
          }
        }))
      },
    ]
  }

  const viewResponses = (form) => {
    getSpecificFormResponses(form)
  }

  const handleViewForm = (form) => {
    const formRes = formResponses.find(res => res.form_id === form.form_id)
    setFormViewing(formRes || form)
  }

  const toggleFormAdd = () => {
    setModalTitle('Tạo biểu mẫu')
    setModalContent(<AddNewForm
      handleSubmit={handleCreateForm}
      closeForm={() => setShowModal(false)}
    />)
    setShowModal(true)
  }

  const toggleEditForm = (form) => {
    setModalTitle("Sửa biểu mẫu")
    setModalContent(<AddNewForm
      handleSubmit={handleEditForm}
      closeForm={() => setShowModal(false)}
      selectedForm={form}
      isEdit
    />)
    setShowModal(true)
  }

  const getFormStatus = (form) => {
    return formResponses.find(res => res.form_id === form.form_id) ? "Đã phản hồi" : "Chưa phản hồi"
  }

  const getDefaultValue = (field, index) => {
    const value = formViewing?.content.find((res, i) => i === index)?.value
    return field.type === FORM_FIELD_TYPE.number && value && !field.is_decimal ? parseInt(value) : value
  }

  useEffect(() => {
    if (dataNeedUpdate) setLoading(prev => prev + 1)
    getForms()
    getResponses()
  }, [dataNeedUpdate]);

  useEffect(() => {
    const pusher = new Pusher(PUSHER_KEY, {
      cluster: 'ap1'
    });

    const channel = pusher.subscribe(CHANNELS.FORM);
    channel.bind(EVENTS.FORM_UPDATE, handleEvent);

    return () => {
      channel.unsubscribe(CHANNELS.FORM)
    }
  }, [])

  return (
    <div className="formPage">
      <div className='top-item'>
        <h3 className="font-weight-bold">Biểu mẫu</h3>
        {!formViewing && [userConstant.ROLE_VALUES.HT, userConstant.ROLE_VALUES.CSN, userConstant.ROLE_VALUES.NT].includes(userAuth.roles) && <div onClick={toggleFormAdd} className="add-btn">
          Tạo mới <i className="fa fa-plus-circle" aria-hidden="true" />
        </div>}
      </div>

      {formViewing
        ? <div className='view-form-submissions'>
          <div className='top-heading' onClick={() => setFormViewing(null)}>
            <i className='fa fa-chevron-left' />
          </div>
          <div>
            <h5> {formViewing.name}</h5>
            <p>{formViewing.description}</p>
            <form className='create-class-form' onSubmit={handleSubmitResponse}>
              {formViewing?.content?.map((field, index) => <div
                className="form-group field-input-group"
                key={field.id + index}
              >
                <div className='form-group'>
                  <label htmlFor={field.name}>{field.label}</label>
                  <input
                    className='form-control'
                    id={field.id}
                    // name={field.id}
                    onChange={(e) => changeResponseField(e, index, field)}
                    required={field.required}
                    type={field.type}
                    value={getDefaultValue(field, index)}
                    step={(field.type === 'number' && field.is_decimal) ? "any" : "1"}
                  />
                </div>
              </div>)}
              <button className="submitBtn newFormBtn" type="submit">
                Xác nhận
              </button>
            </form>
          </div>
        </div>
        : <div id='member-table-wrapper'>
          {userAuth.roles !== userConstant.ROLE_VALUES.HT && <h4>Biểu mẫu cần phản hồi</h4>}
          {userAuth.roles !== userConstant.ROLE_VALUES.HT && <table id='member-table' className='table table-striped'>
            <thead>
              <tr>
                <th scope="col">Tên biểu mẫu</th>
                <th scope="col">Trạng thái</th>
              </tr>
            </thead>
            <tbody>
              {requestedForms.length > 0
                ? requestedForms.map((form, index) =>
                  <tr key={form.form_id}>
                    <td style={{ cursor: 'pointer' }} onClick={() => handleViewForm(form)}>{requestedForms.length - index}. {form.name}</td>
                    <td>{getFormStatus(form)}</td>
                  </tr>
                )
                : <tr className='no-result-block'><td colSpan={3}>Không có</td></tr>}
            </tbody>
          </table>}

          {[userConstant.ROLE_VALUES.HT, userConstant.ROLE_VALUES.CSN, userConstant.ROLE_VALUES.NT].includes(userAuth.roles) && <h4>Biểu mẫu đã tạo</h4>}
          {[userConstant.ROLE_VALUES.HT, userConstant.ROLE_VALUES.CSN, userConstant.ROLE_VALUES.NT].includes(userAuth.roles) &&
            <div className='table-wrapper-scroll'>
              <table id='member-table' className='table table-striped'>
                <thead>
                  <tr>
                    <th scope="col">Tên biểu mẫu</th>
                    <th scope="col">Trạng thái</th>
                    <th scope="col">Phản hồi</th>
                  </tr>
                </thead>
                <tbody>
                  {createdForms.length > 0
                    ? createdForms.map((form, index) =>
                      <tr key={form.form_id}>
                        <td style={{ cursor: 'pointer' }} onClick={() => toggleEditForm(form)}>{createdForms.length - index}. {form.name}</td>
                        <td>
                          <select
                            defaultValue={form.status}
                            onChange={(e) => handleUpdateInfo(e.target.value, form, 'status')}
                          >
                            {FORM_STATUS_OPTIONS.map((option, index) => <option key={index} value={option.value}>
                              {option.label}
                            </option>)}
                          </select>
                        </td>
                        <td onClick={() => viewResponses(form)} className='cursor-pointer'>Xem</td>
                      </tr>
                    )
                    : <tr className='no-result-block'><td colSpan={3}>Không có</td></tr>}
                </tbody>
              </table>
            </div>}
        </div>}

      {/* {loading && <div style={{position: 'fixed', top: 0, left: 0}}>Loading...</div>} */}
      {loading > 0 && <div className="loading">Xin chờ. Đang tải...</div>}
    </div>
  )
}

export default Form