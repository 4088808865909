import React from "react";
import { Redirect, BrowserRouter as Router, Switch } from "react-router-dom";

import ErrorLayout from "@layout/errorLayout/errorLayout";
import LoginLayout from '@layout/loginLayout/loginLayout';
import MainLayout from '@layout/mainLayout/mainLayout';

import ForbiddenPage from "@pages/403/ForbiddenPage";
import NotFoundPage from "@pages/404/NotFoundPage";
import SystemErrorPage from '@pages/500/SystemErrorPage';

import LoginPage from '@pages/login/LoginPage';

import MemberPage from "@pages/members/MemberPage";
import ToolsPage from "@pages/tools/ToolsPage";

import ReportPage from "./pages/report/ReportPage";
import SummaryPage from "./pages/summary/SummaryPage";

import Event2700km from "@pages/2700km/2700km";
import SettingsPage from "@pages/settings/Settings";
import CompetitionPage from "./pages/competition/CompetitionPage";
import Form from "./pages/form/Form";
import Squad from "./pages/squad/SquadPage";
import WM from "./pages/wm/WMPage";
// import DocumentPage from "./pages/documents/DocumentPage";

export default (
  <Router>
    <div>
      <Switch>
        <LoginLayout exact path="/" component={LoginPage} />

        <MainLayout exact path="/competition" component={CompetitionPage} />
        <MainLayout exact path="/members" component={MemberPage} />
        <MainLayout exact path="/summary" component={SummaryPage} />
        <MainLayout exact path="/report" component={ReportPage} />
        <MainLayout exact path="/squad" component={Squad} />
        <MainLayout exact path="/wm" component={WM} />
        <MainLayout exact path="/form" component={Form} />
        {/* <MainLayout exact path="/document" component={DocumentPage} /> */}
        <MainLayout exact path="/2700" component={Event2700km} />

        <MainLayout exact path="/tools" component={ToolsPage} />
        <MainLayout exact path="/settings" component={SettingsPage} />

        <ErrorLayout exact path="/system-error" component={SystemErrorPage} />
        <ErrorLayout path="/not-found" component={NotFoundPage} />
        <ErrorLayout path="/forbidden" component={ForbiddenPage} />
        <Redirect to="/not-found" />
      </Switch>

      {/* <Toaster
        toastOptions={{
          duration: 3000,
          success: {
            icon: '✅',
            style: {
              color: '#10552d'
            }
          },
          error: {
            icon: '🚫',
            style: {
              border: '1px solid #eb5758',
              fontWeight: 500
            }
          },
          warning: {
            icon: '🔥'
          }
        }}
        containerStyle={{
          top: 80,
        }}
      /> */}
    </div>
  </Router>
)
