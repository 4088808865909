import $ from 'jquery';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

import authAjax from '@ajax/auth';
import userConstant from '@constant/user';

import { useAppContext } from '../../contexts/app';

import './common.scss';
import sidebarConstant from '../../constant/sidebar';

const Sidebar = () => {
  const history = useHistory()
  const userAuth = useSelector(state => state.userAuth)

  const {
    setSidebarOpen,
    isSidebarCollapsed: [isSidebarCollapsed],
  } = useAppContext()


  const pathname = window.location.pathname

  const validUserSideBar = (sidebarList) => {
    if (sidebarList && sidebarList.length > 0 &&
      !sidebarList.map(item => item.url).includes(pathname) &&
      (!sidebarConstant.ERROR_PATH.includes(pathname))
    ) {
      history.push(sidebarList.map(item => item.url)[0])
    }
  }

  const getSideBarList = () => {
    const userAuthRole = userAuth.roles.trim()

    if (userAuthRole) {
      const userSideBar = sidebarConstant.SIDEBAR_LIST
        .filter(navItem => {
          if (navItem.prefix === 'tools' && userAuthRole === userConstant.ROLE_VALUES.CSN) return true;

          return navItem.roles.includes(userAuthRole) ||
            (navItem.allowedUserIds?.includes(userAuth.user_id)) 
            // ||
            // (navItem.isHm && userAuth.is_hm && userAuth.roles === userConstant.ROLE_VALUES.NT) ||
            // (navItem.isHm && userAuth.is_hm && userAuth.roles !== userConstant.ROLE_VALUES.NT && navItem.isKvtAllowed)
        })

      validUserSideBar(userAuth.roles ? userSideBar : null)
      return userSideBar
    }
    return []
  }

  const toggleSidebar = () => {
    $('#sidebar').toggleClass('active');
    $('#main-content').toggleClass('full-screen');
    setSidebarOpen(false)
  }

  const handleLogout = () => {
    authAjax.logout()
      .then(apiResponse => {
        history.push('/')
      })
  }

  const sidebarList = getSideBarList()

  return (
    <nav className={"sidebar sidebar-offcanvas " + (isSidebarCollapsed && "collapsed-sidebar")} id="sidebar" style={{ width: isSidebarCollapsed ? '45px' : '235px' }}>
      <ul className="nav">
        <li className='user-name'>
          <div className="nav-link">
            <div className="name">
              {userAuth.zion}
            </div>
            <div className="logout-button" onClick={handleLogout}>
              <span className="menu-title">Đăng xuất</span>
            </div>
          </div>
        </li>
        {sidebarList.map((item, idx) =>
          <li className={"nav-item" + (pathname === item.url ? ' active' : '')} key={idx} data-tip={isSidebarCollapsed ? item.name : null}>
            <Link className="nav-link" to={`${item.url}`} onClick={toggleSidebar}>
              <i className={item.image + " menu-icon"}></i>
              <span className="menu-title">{item.name}</span>
            </Link>
          </li>
        )}
      </ul>
    </nav >
  )
}

export default Sidebar
