import Parser from 'html-react-parser';
import moment, * as Moment from 'moment';
import { extendMoment } from 'moment-range';
import React, { useEffect, useState } from 'react';

import reportAjax from '@ajax/report';
import apiResult from '@util/apiResult';
import { handleError } from '@util/common';
import { useSelector } from 'react-redux';
import ReportAdd from './ReportAdd';

import { REPORT_RANGES } from '../../constant/report';
import { useAppContext } from '../../contexts/app';

import "./ReportPage.scss";

const ReportPage = () => {
  const { userAuth } = useSelector(state => state)
  const [reports, setReports] = useState([]);
  const [reportRange, setReportRange] = useState(null)

  const {
    setShowModal,
    setModalContent,
    setModalTitle,
    handleModalOnClose
  } = useAppContext()

  const getReports = () => {
    if (!reportRange || reportRange === REPORT_RANGES.CURRENT_STATUS) return

    reportAjax.listSubmission(`?range=${reportRange}`)
      .then(res => {
        const data = apiResult.success(res);
        setReports(data?.reports || {})
      })
      .catch(handleError)
  }

  const getReportName = (type) => {
    switch (type || reportRange) {
      // case REPORT_RANGES.DAILY:
      //   return 'Truđô'
      case REPORT_RANGES.EIGHT_STEPS:
        return '8 bước'
      case REPORT_RANGES.WEEKLY_EDU:
        return 'Giáo dục NGV'
      case REPORT_RANGES.WEEKLY_GENERAL:
        return 'Tuần'
      case REPORT_RANGES.MONTHLY_GENERAL:
        return 'Tháng'
      // case REPORT_RANGES.CURRENT_STATUS:
      //   return 'Hiện trạng'
      default:
        return 'Báo cáo'
    }
  }

  const toggleFormEdit = (date) => {
    setModalTitle(`[Báo cáo] ${getReportName(date?.type)}`)
    setModalContent(<ReportAdd
      selectedReport={date?.report}
      reportType={date?.type || reportRange}
      date={date?.key}
      fullDate={date}
      label={date?.label}
    />)
    setShowModal(true)
    if (!date.type) { // only for current status report
      handleModalOnClose(getReports)
    }
  }

  const getDaysArrayByMonth = (subMonth = 0) => {
    var daysInMonths = moment().subtract(subMonth, 'months').daysInMonth();
    const now = moment().subtract(subMonth, 'months');
    var arrDays = [];

    while (daysInMonths) {
      var current = moment().subtract(subMonth, 'months').date(daysInMonths);
      if (current.isBefore(now.endOf(subMonth > 0 ? 'month' : 'day'))) {
        arrDays.push({
          label: current.format('YYYY/MM/DD'),
          key: current.format('YYYY-MM-DD'),
        });
      }
      daysInMonths--;
    }

    return arrDays;
  }

  const handleMonths = () => {
    const today = moment()
    return [1, 2, 3].includes(today.date())
      ? [...getDaysArrayByMonth(), ...getDaysArrayByMonth(1)]
      : getDaysArrayByMonth()
  }

  const handleEduMonths = () => {
    const today = moment()
    const days = [1, 2, 3].includes(today.date())
      ? [...getDaysArrayByThursday(), ...getDaysArrayByThursday(1)]
      : getDaysArrayByThursday()

    return days
      .map((item, index) => (
        { ...item, label: `Tuần ${item.weekIndex}` }
      ))
  }

  const getDaysArrayByThursday = (subMonth = 0) => {
    var daysInMonth = moment().subtract(subMonth, 'months').daysInMonth();
    const now = moment().subtract(subMonth, 'months');
    var arrDays = [];

    while (daysInMonth) {
      var current = moment().subtract(subMonth, 'months').date(daysInMonth);
      if (current.isSameOrBefore(now.endOf(subMonth > 0 ? 'month' : 'week')) && current.weekday() === 4) {
        arrDays.push({
          startDate: current.clone().startOf('week'),
          label: current.format('MM/DD'),
          key: current.format('YYYY-MM-DD'),
          endDate: current.clone().endOf('week'),
        });
      }
      daysInMonth--
    }

    return arrDays.map((day, index) => ({ ...day, weekIndex: arrDays.length - index })).slice(arrDays.length > 4 ? 1 : 0);
  }

  const getDaysInAMonth = (month = +Moment().format("MM") - 1, year = +Moment().format("YYYY")) => {
    const moment = extendMoment(Moment);
    const startDate = moment([year, month]);

    const firstDay = moment(startDate).startOf('month')
    const endDay = moment().isBefore(moment(startDate).endOf('month')) ? moment() : moment(startDate).endOf('month');

    const monthRange = moment.range(firstDay, endDay)
    const weeks = [];
    const days = Array.from(monthRange.by('day'));
    days.forEach(it => {
      if (!weeks.includes(it.week())) {
        weeks.push(it.week());
      }
    })

    const calendar = []
    weeks.forEach(week => {
      const firstWeekDay = moment([year, month]).week(week).day(0).isAfter(moment([year, month]).startOf('month'))
        ? moment([year, month]).week(week).day(0)
        : moment([year, month]).startOf('month')
      const lastWeekDay = moment([year, month]).week(week).day(6).isBefore(moment([year, month]).endOf('month'))
        ? moment([year, month]).week(week).day(6)
        : moment([year, month]).endOf('month')
      calendar.push({
        startDate: firstWeekDay,
        endDate: lastWeekDay,
        label: `${firstWeekDay.format('MM/DD')} ~ ${lastWeekDay.format('MM/DD')}`,
        key: lastWeekDay.format('YYYY-MM-DD')
      })
    })

    return calendar.reverse().map((item, index) => ({ ...item, label: `Tuần ${calendar.length - index}` }));
  }

  const getMonthsInYear = (subYear = 0) => {
    const months = []

    for (let i = 12; i > 0; i--) {
      months.push({
        label: moment().subtract(subYear, 'years').format(`YYYY/${String(i).padStart(2, '0')}`),
        key: moment().subtract(subYear, 'years').format(`YYYY-${String(i).padStart(2, '0')}-01`),
        endDate: moment().subtract(subYear, 'years').set('month', i - 1).clone().endOf('month')
      })
    }

    return months;
  }

  const getDisplayDates = () => {
    switch (reportRange) {
      // case REPORT_RANGES.DAILY:
      //   return handleMonths()
      case REPORT_RANGES.EIGHT_STEPS:
        return handleMonths()
      case REPORT_RANGES.WEEKLY_EDU:
        return handleEduMonths()
      case REPORT_RANGES.WEEKLY_GENERAL:
        // Thông số tháng trước sẽ hiện hết mùng 3 tháng sau
        return [1, 2, 3].includes(moment().date())
          ? [...getDaysInAMonth(), ...getDaysInAMonth(moment().month() - 1)]
          : getDaysInAMonth()
      case REPORT_RANGES.MONTHLY_GENERAL:
        // Thông số năm trước sẽ hiện hết tháng 1 năm sau
        return parseInt(moment().format('MM')) === 1
          ? [...getMonthsInYear(), ...getMonthsInYear(1)]
          : getMonthsInYear()
      default:
        return []
    }
  }

  // const handleCurrentStatusForm = async () => {
  //   try {
  //     const response = await reportAjax.getCurrentStatusReport()
  //     const data = await apiResult.success(response)

  //     if (data) {
  //       toggleFormEdit({
  //         date: '',
  //         key: '',
  //         type: REPORT_RANGES.CURRENT_STATUS,
  //         report: data
  //       })
  //     }
  //   } catch (error) {
  //     console.log('err', error)
  //   }
  // }

  useEffect(() => {
    if (reportRange) {
      getReports()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportRange])

  const dates = getDisplayDates()
    .map(date => ({
      ...date, report: reports.find(report =>
        moment(report[[REPORT_RANGES.EIGHT_STEPS].includes(reportRange) ? 'created_at' : 'report_date'])
          .format('YYYY-MM-DD') === date.key
      )
    }))

  return (
    <div className="reportPage">
      <div className='top-item'>
        {reportRange
          ? <h4 onClick={() => setReportRange(null)}><i className='fa fa-chevron-left' />[Báo cáo] {getReportName()}</h4>
          : <h3 className="font-weight-bold">Báo cáo</h3>}
      </div>
      {!reportRange
        ? (
          <ul className="nav nav-tabs">
            {/* {userAuth.is_hm && userAuth.roles === 'NT' && <li className="nav-item" onClick={handleCurrentStatusForm}>
              <div className={"nav-link " + (reportRange === REPORT_RANGES.CURRENT_STATUS && 'active')}>Hiện trạng <i className='fa fa-chevron-right' /></div>
            </li>} */}
            {/* <li className="nav-item" onClick={() => setReportRange(REPORT_RANGES.DAILY)}>
              <div className={"nav-link " + (reportRange === REPORT_RANGES.DAILY && 'active')}>Truđô <i className='fa fa-chevron-right' /></div>
            </li> */}
            {userAuth.is_hm && <li className="nav-item" onClick={() => setReportRange(REPORT_RANGES.EIGHT_STEPS)}>
              <div className={"nav-link " + (reportRange === REPORT_RANGES.EIGHT_STEPS && 'active')}>8 bước <i className='fa fa-chevron-right' /></div>
            </li>}
            <li className="nav-item" onClick={() => setReportRange(REPORT_RANGES.WEEKLY_EDU)}>
              <div className={"nav-link " + (reportRange === REPORT_RANGES.WEEKLY_EDU && 'active')}>Giáo dục NGV <i className='fa fa-chevron-right' /></div>
            </li>
            {userAuth.is_hm && <li className="nav-item" onClick={() => setReportRange(REPORT_RANGES.WEEKLY_GENERAL)}>
              <div className={"nav-link " + (reportRange === REPORT_RANGES.WEEKLY_GENERAL && 'active')}>BC Tuần <i className='fa fa-chevron-right' /></div>
            </li>}
            {userAuth.is_hm && <li className="nav-item" onClick={() => setReportRange(REPORT_RANGES.MONTHLY_GENERAL)}>
              <div className={"nav-link " + (reportRange === REPORT_RANGES.MONTHLY_GENERAL && 'active')}>BC Tháng <i className='fa fa-chevron-right' /></div>
            </li>}
          </ul>
        ) : (
          <div className='report-list'>
            {dates.length > 0
              ? dates.map((date, index) => <div key={date.key || date.label} className='day-item' onClick={() => toggleFormEdit(date)}>
                <div
                  style={{
                    color: (!date.report ? '#5776ff' : (date.report.is_submitted) && '#aaa'),
                    fontWeight: date?.report?.is_submitted ? 400 : 600
                  }}
                >
                  {Parser(date.label)} {reportRange !== REPORT_RANGES.WEEKLY_EDU && date.startDate && date.endDate && `(${date.startDate.format('MM/DD')} - ${date.endDate.format('MM/DD')})`}
                </div>
                <div className='submit-status' style={{
                  color: (!date.report ? '#5776ff' : (date.report.is_submitted) && '#aaa'),
                  fontWeight: date?.report?.is_submitted ? 400 : 600
                }}>
                  {date.report ? (date.report?.is_submitted ? 'Đã hoàn thành' : 'Đã lưu') : 'Chưa hoàn thành'}
                </div>
              </div>)
              : <div className="">Chưa đến ngày báo cáo</div>}
          </div>
        )
      }
    </div>
  )
}

export default ReportPage