import Pusher from 'pusher-js';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import reportAjax from '@ajax/report';
import { CHANNELS, EVENTS, PUSHER_KEY } from '@constant/socket';
import apiResult from "@util/apiResult";
import { handleError } from '@util/common';

import { email, required } from "../../constant/validation";

import './common.scss';

// const NGV_MEMBER_NUMBER = 188 - 43
const NGV_MEMBER_NUMBER = 140
const TOTAL_JOINED_MEMBER_NUMBER = 204
const FRUIT_PER_MEMBER = NGV_MEMBER_NUMBER / TOTAL_JOINED_MEMBER_NUMBER

export const FORM_INPUT_EMAIL_LOGIN = [
  {
    id: 'email',
    type: 'email',
    title: 'Email',
    value: '',
    validate: [required, email]
  },
  {
    id: 'password',
    type: 'password',
    title: 'Mật khẩu',
    value: '',
    validate: [required]
  },
];

const Squad = () => {
  const numberFormatter = Intl.NumberFormat('en-US');
  const userAuth = useSelector(state => state.userAuth)

  const [reports, setReports] = useState({ squads: [], members: [] })
  const [dataNeedUpdate, setDataNeedUpdate] = useState(true)

  const getReports = () => {
    reportAjax.getReportsAdmin(`?email=${userAuth.email}&id=${userAuth.user_id}`)
      .then(res => setReports(apiResult.success(res)))
      .catch(handleError)
  }

  const handleEvent = () => {
    setDataNeedUpdate(true)
  }

  const mapBadges = (index, type) => {
    switch (index + 1) {
      case 1:
        return <img src={`/icons/leaderboard/${type === 'group' ? 'group' : 'individual'}-1.svg`} alt="1" />
      case 2:
        return <img src={`/icons/leaderboard/${type === 'group' ? 'group' : 'individual'}-2.svg`} alt="2" />
      case 3:
        return <img src={`/icons/leaderboard/${type === 'group' ? 'group' : 'individual'}-3.svg`} alt="3" />
      default:
        return <span>{index + 1} </span>
    }
  }

  const getCompletePercent = (squad) => {
    // const neededPotential = squad.member_number - squad.fruit_number * 2
    const neededPotential = Math.round((squad.member_number * FRUIT_PER_MEMBER - squad.fruit_number_2) * 2) // member = 140 hay 204?

    if (neededPotential <= 0) {
      return <strong className='text-success'>Đã hoàn thành</strong>
    }
    const percent = Math.round(squad.potential_fruit / neededPotential * 100 * 100) / 100
    return <strong className={percent <= 50 ? 'text-danger' : (percent <= 79 ? 'text-warning' : 'text-success')}>
      {percent}% ({squad.potential_fruit} / {neededPotential})
    </strong>
  }

  const calculatePotential = (squad) => {
    return Math.round((squad.member_number * FRUIT_PER_MEMBER - squad.fruit_number_2) * 2)
  }

  useEffect(() => {
    const pusher = new Pusher(PUSHER_KEY, {
      cluster: 'ap1'
    });

    const channel = pusher.subscribe(CHANNELS.SUMMARY);
    channel.bind(EVENTS.UPDATE, handleEvent);

    return () => {
      channel.unsubscribe(CHANNELS.SUMMARY)
    }
  }, [])

  useEffect(() => {
    if (dataNeedUpdate) {
      getReports()
      setDataNeedUpdate(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataNeedUpdate])

  const getTotal = () => {
    let totalFruit = 0;
    let totalFruit2 = 0;
    let totalPoint = 0;
    let potentialFruit = 0;
    let learningFruit = 0;
    let totalMember = 0;

    for (let i = 0; i < reports?.squads.length; i++) {
      const squad = reports?.squads[i]

      totalFruit += squad.fruit_number
      totalFruit2 += squad.fruit_number_2
      totalPoint += squad.point
      potentialFruit += squad.potential_fruit
      learningFruit += squad.learning_fruit
      totalMember += squad.member_number

    }

    return {
      totalFruit: totalFruit,
      totalFruit2: totalFruit2,
      totalPoint: totalPoint,
      potentialFruit: potentialFruit,
      learningFruit: learningFruit,
      totalMember: totalMember
    }
  }

  const total = getTotal()

  return <div className="squad-page">
    <div className='squad-page-content'>
      <section>
        <h4 id="leaderboard">LVQ 2023 <br /><small>Biệt đội kết trái</small></h4>

        <div className='table-row'>
          <div className='table-column'>
            <div className='table-block total-table'>
              <table className='table table-striped table-bordered'>
                <thead>
                  <tr>
                    <th>Tổng kết</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <ul>
                        <li>Tổng số biệt đội: <strong>{reports?.squads.length}</strong></li>
                        <li>Tổng số thành viên tham gia: <strong>{total.totalMember}</strong></li>
                        <li>Mục tiêu chiên tiềm năng: <strong>{calculatePotential({ member_number: total.totalMember, fruit_number_2: total.totalFruit2 })}</strong></li>
                        <hr />
                        <li>Tổng trái: <strong>{numberFormatter.format(total.totalFruit)}</strong></li>
                        <li>Số trái (trong tháng 4): <strong>{numberFormatter.format(total.totalFruit2 || 0)}</strong></li>
                        <li>Tổng điểm: <strong>{numberFormatter.format(total.totalPoint)}</strong></li>
                        <li>Chiên tiềm năng: <strong>{numberFormatter.format(total.potentialFruit)}</strong></li>
                        <li>Chiên đang học: <strong>{numberFormatter.format(total.learningFruit)}</strong></li>
                        <li>Khả năng hoàn thành: {getCompletePercent({
                          fruit_number: total.totalFruit,
                          fruit_number_2: total.totalFruit2,
                          member_number: total.totalMember,
                          potential_fruit: total.potentialFruit
                        })}</li>
                      </ul>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div className='table-row'>
          <div className='table-column'>
            <h6>Biệt đội</h6>
            <div className='table-block'>
              <table className='table table-striped table-bordered'>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Biệt đội</th>
                  </tr>
                </thead>
                <tbody>
                  {reports.squads.map((squad, index) => <tr key={squad.name + squad.point + index}>
                    <th>{mapBadges(index, 'group')}</th>
                    <td>
                      <ul>
                        <li><strong>{squad.name}</strong></li>
                        <li><strong>{squad.member_number}</strong> thành viên</li>
                        <li>Mục tiêu chiên tiềm năng: <strong>{calculatePotential(squad)}</strong></li>
                        <li>Điểm xếp hạng: <strong>{numberFormatter.format(Math.round(squad.rank_point))}</strong></li>
                        <hr />
                        <li>Tổng trái: <strong>{numberFormatter.format(squad.fruit_number)}</strong></li>
                        <li>Số trái (trong tháng 4): <strong>{numberFormatter.format(squad.fruit_number_2 || 0)}</strong></li>
                        <li>Tổng điểm: <strong>{numberFormatter.format(squad.point)}</strong></li>
                        <li>Chiên tiềm năng: <strong>{numberFormatter.format(squad.potential_fruit)}</strong></li>
                        <li>Chiên đang học: <strong>{numberFormatter.format(squad.learning_fruit)}</strong></li>
                        <li>Khả năng hoàn thành: {getCompletePercent(squad)}</li>
                      </ul>
                    </td>
                  </tr>)}
                </tbody>
              </table>
            </div>
          </div>

          <div className='table-column'>
            <h6>Cá nhân</h6>
            <div className='table-block member-table'>
              <table className='table table-striped table-bordered'>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Họ tên</th>
                    <th>Số trái</th>
                    <th>Tổng điểm</th>
                  </tr>
                </thead>
                <tbody>
                  {reports.members.map((member, index) => <tr key={member.name + member.point + index}>
                    <th>{mapBadges(index, 'individual')}</th>
                    <td><span>{member.name} <small>({member.squad_name})</small></span></td>
                    <td><strong>{numberFormatter.format(member.fruit_number)}</strong></td>
                    <td><strong>{numberFormatter.format(member.point)}</strong></td>
                  </tr>)}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>
    </div>

  </div>
}

export default Squad