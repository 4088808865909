import { toast, Bounce } from "react-toastify"
import apiResult from './apiResult'

export const handleError = (error) => {
  toast.error(
    typeof error === 'string' ? error : apiResult.errorMessage(error),
    {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      transition: Bounce,
    })
}

export const handleSuccess = (error) => {
  toast.success(
    typeof error === 'string' ? error : apiResult.success(error),
    {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      transition: Bounce,
    })
}