import React from 'react';
import { useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';

import authAjax from '@ajax/auth';
import { useAppContext } from '../../contexts/app';
import './common.scss';

const Header = () => {
  const history = useHistory()
  const userAuth = useSelector(state => state.userAuth)
  const {
    isSidebarOpen: [isSidebarOpen, setSidebarOpen],
    isSidebarCollapsed: [isSidebarCollapsed, toggleSidebarCollapse]
  } = useAppContext()

  const handleToggleSidebar = () => {
    if (!isSidebarOpen) {
      setSidebarOpen(true)
      document.getElementById('sidebar').classList.add('active')
    } else {
      setSidebarOpen(false)
      document.getElementById('sidebar').classList.remove('active')
    }
  }

  const gotoHome = () => {
    history.push('/')
  }

  const handleLogout = () => {
    authAjax.logout()
      .then(apiResponse => {
        history.push('/')
      })
  }

  return <nav className="navbar col-lg-12 col-12 p-0 d-flex flex-row header">
    <div className="logo navbar-brand-wrapper">
      <img style={{ cursor: 'pointer' }} src="/globe.ico" alt="" onClick={gotoHome} />
      <div style={{ whiteSpace: 'nowrap' }}>HN Leaders</div>
      <div className={'collapse-button ' + (isSidebarCollapsed && 'collapsed')} onClick={() => toggleSidebarCollapse(!isSidebarCollapsed)}>
        <img alt='' src="/icons/sidebar/fast-forward-animated.svg" />
      </div>
    </div>
    <div className="navbar-menu-wrapper d-flex align-items-center justify-content-end">
      <ul className="navbar-nav navbar-nav-right">
        <li className="nav-item dropdown">
        </li>
        <li className="nav-item nav-profile dropdown">
          <div className="nav-link welcome-text">
            <strong> {userAuth.zion}</strong>
          </div>
          <div className="logout-button" onClick={handleLogout}>
            <span className="menu-title">Đăng xuất</span>
          </div>
        </li>
      </ul>
      <button className="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button" data-toggle="offcanvas" onClick={handleToggleSidebar}>
        <i className={isSidebarOpen ? "icon-cross" : "icon-menu"}></i>
      </button>
    </div>
  </nav>
}

export default Header
