import React from 'react';

import TextInput from '@parts/form/textInput';
import { handleError } from '@util/common'

import './common.scss';

const LoginForm = ({ handleSubmit, formInputs }) => {
  const onSubmit = () => {
    let isFormValid = true

    const email = document.getElementById('email')
    const password = document.getElementById('password')

    formInputs.map(input => {
      const field = document.getElementById(input.id)
      const validation = input.validate
        ?.map(validate => validate(field.value, input.title))
        .filter(errorMessage => errorMessage)

      if (validation.length > 0) {
        isFormValid = false
        handleError(validation[0])
      }

      return input
    })

    if (isFormValid) {
      handleSubmit({
        email: email.value,
        password: password.value,
      })
    }
  }

  return (
    <form className="loginForm" onSubmit={handleSubmit}>
      {formInputs.map(formInput => <div key={formInput.id} className="form-group">
        <TextInput
          label={formInput.title}
          id={formInput.id}
          name={formInput.id}
          type={formInput.type}
          className="form-input"
          autofill="false"
          autocomplete="off"
        />
      </div>)}
      <div className="mt-3">
        <button type="button" onClick={onSubmit} className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn">Đăng nhập</button>
      </div>
    </form>
  )
}

export default LoginForm