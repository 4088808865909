import authConstant from "@constant/auth";

export const updateUserAuthInfo = (userAuth) => {
  return { type: authConstant.UPDATE_USER_AUTH_INFO, userAuth }
}

export function updateUserAuth(userInfo) {
  return function (dispatch) {
    dispatch(updateUserAuthInfo(userInfo))
  }
}
