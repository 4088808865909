const userConstant = {
    ACCOUNT_STATUSES: {
        1: "Đang hoạt động",
        2: 'Chưa kích hoạt',
        0: 'Đã khóa'
    },
    ACCOUNT_STATUS_OPTIONS: [
      {
        value: 1,
        label: 'Đang hoạt động'
      },
      {
        value: 2,
        label: 'Chưa kích hoạt'
      },
      {
        value: 0,
        label: 'Đã khóa'
      },
    ],
    LOAD_USERS_SUCCESS: 'LOAD_USERS_SUCCESS',
    LOAD_USERS_MANAGER_SUCCESS: 'LOAD_USERS_MANAGER_SUCCESS',
    LOAD_USER_SUCCESS: 'LOAD_USER_SUCCESS',
    UPDATE_DATA_LOADING: 'UPDATE_DATA_LOADING',
    MAX_COLUMN_NUMBER: 15,
    ROLE_GROUP_URL: {
        TD: ['lop-hoc']
    },
    ROLE_VALUES: {
        HT: 'HT', // Hội trưởng
        PKVT: 'PKVT',
        KVT: 'KVT',
        CSN: 'CSN',
        ADMIN: 'ADMIN',
        NT: 'NT',
    },
    ROLE_NGV: ['KVT', 'CSN', 'ADMIN', 'NT', 'PKVT'],
    ROLE_CLASS: ['KVT', 'CSN', 'NT'],
    ROLE_UPLOAD_FILES: ['NT', 'ADMIN'],
}

export default userConstant
