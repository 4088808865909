import React from "react";
import './index.scss'

class NotFoundPage extends React.Component {
    constructor(props, context) {
        super(props, context)
        this.state = {
            selectedProblems: []
        }
    }

    render() {
        return (
            <div id="notfound">
                <div className="notfound">
                    <div className="notfound-404">
                        <h1>4<span></span>3</h1>
                    </div>
                    <h2>Oops! Bạn đã truy cập khu vực hạn chế</h2>
                    <p>Xin lỗi nhưng bạn không có quyền truy cập tài nguyên này! <br/> Vui lòng kiểm tra lại trang đã truy cập và liên hệ bộ phận kỹ thuật nếu cảm thấy đây là lỗi kỹ thuật. <br /> Xin cám ơn nhiều ạ!</p>
                    <a href="/">Trở lại trang chủ</a>
                </div>
            </div>
        )
    }
}

export default NotFoundPage
