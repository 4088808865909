import React from 'react'
import { Field, reduxForm } from 'redux-form'

import TextInput from '@parts/form/textInput'

import './common.scss'

const ChangePasswordForm = ({ errors, handleSubmit }) => {
  return (
    <form className="create-user-form change-psw-form" onSubmit={handleSubmit}>
      <div className="form-group">
        <Field
          label="Mật khẩu hiện tại"
          name="password"
          component={TextInput}
          type="password"
          className="password-input"
        />
      </div>

      <pre>
        <h6>Để bảo an, mật khẩu mới cần:</h6>
        <ul>
          <li>Có ít nhất 8 ký tự</li>
          <li>Có ít nhất một số (1,2,3...)</li>
          <li>Có ít nhất một chữ hoa (A, B, C...)</li>
          <li>Có ít nhất một chữ thường (a, b, c...)</li>
        </ul>
      </pre>

      <div className="form-group passwordField">
        <Field
          label="Mật khẩu mới"
          name="new_password"
          component={TextInput}
          type="password"
          className="password-input"
        />
      </div>
      <div className="form-group">
        <Field
          label="Nhập lại mật khẩu mới"
          name="new_password_confirmation"
          component={TextInput}
          type="password"
          className="password-input"
        />
      </div>
      {errors && errors.map((err, index) => (
        <div className="box-line error" key={index}>{err.message}</div>
      ))}
      <div className="form-actions">
        <button type="submit" className="btn btn-primary">Xác nhận</button>
      </div>
    </form>
  )
}

export default reduxForm({
  form: 'changePasswordForm',
})(ChangePasswordForm)