import Pusher from 'pusher-js';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route } from "react-router-dom";
import { ToastContainer } from 'react-toastify';

import authAjax from "@ajax/auth";
import Header from '@parts/header/header';
import Sidebar from '@parts/sidebar/sidebar';
import apiResult from "@util/apiResult";

import { updateUserAuthInfo } from "@pages/login/loginAction";
import { CHANNELS, EVENTS, PUSHER_KEY } from '../../constant/socket';
import { API_LOCAL_HOST, API_PRODUCTION } from '../../constant/system';
import { AppWrapper } from '../../contexts/app';
import useWindowSize from '../../hooks/useWindowSize';
import Modal from '../../parts/modal/Modal';

import 'react-toastify/dist/ReactToastify.css';

import './common.scss';

const MainLayout = ({ component: Component, ...rest }) => {
  const dispatch = useDispatch()
  const userAuth = useSelector(state => state.userAuth)
  const windowSize = useWindowSize()
  const isMobileView = windowSize?.width <= 480

  // Sidebar
  const [isSidebarOpen, setSidebarOpen] = useState(false)
  const [isSidebarCollapsed, setSidebarCollapse] = useState(false)

  // Mode
  const [apiHost, setApiHost] = useState()

  // Modal
  const [isShowModal, setShowModal] = useState(false)
  const [modalContent, setModalContent] = useState(null)
  const [modalTitle, setModalTitle] = useState(null)
  const [modalOnClose, setModalOnClose] = useState()
  const [dataNeedUpdate, setDataNeedUpdate] = useState(0)

  const handleModalOnClose = (func) => {
    setModalOnClose(() => func)
  }

  const getDefaultCollapsed = () => {
    const defaultCollapsed = localStorage.getItem('is_collapsed')

    if (defaultCollapsed) {
      setSidebarCollapse(JSON.parse(defaultCollapsed))
    }
  }

  const toggleSidebarCollapse = (state) => {
    setSidebarCollapse(state)
    localStorage.setItem('is_collapsed', JSON.stringify(state))
  }

  const handleEvent = (e) => {
    setDataNeedUpdate(prevState => prevState += 1)
  }

  useEffect(() => {
    authAjax
      .get()
      .then(apiResponse => {
        dispatch(updateUserAuthInfo(apiResult.success(apiResponse)))
      })
      .catch(apiResponse => {
        window.location.href = '/'
      })
  }, [dataNeedUpdate, dispatch])

  useEffect(() => {
    getDefaultCollapsed()

    setApiHost(window.location.hostname.startsWith('localhost') || window.location.hostname.startsWith('127.0.0.1')
      ? API_LOCAL_HOST
      : API_PRODUCTION)

    authAjax
      .get()
      .then(apiResponse => {
        dispatch(updateUserAuthInfo(apiResult.success(apiResponse)))
      })
      .catch(apiResponse => {
        window.location.href = '/'
      })

    const pusher = new Pusher(PUSHER_KEY, {
      cluster: 'ap1'
    });

    const channel = pusher.subscribe(CHANNELS.LEADERBOARD);
    channel.bind(EVENTS.LEADERBOARD_UPDATE, handleEvent);

    return () => {
      channel.unsubscribe(CHANNELS.LEADERBOARD)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const store = {
    setSidebarOpen: setSidebarOpen,
    setShowModal: setShowModal,
    setModalContent: setModalContent,
    setModalTitle: setModalTitle,
    handleModalOnClose: handleModalOnClose,
    isSidebarOpen: [isSidebarOpen, setSidebarOpen],
    isSidebarCollapsed: [isSidebarCollapsed, toggleSidebarCollapse],
    apiHost: [apiHost],
    isMobileView: [isMobileView],
    isShowModal: [isShowModal, setShowModal],
    modalContent: [modalContent, setModalContent],
    modalTitle: [modalTitle, setModalTitle],
    modalOnClose: [modalOnClose, handleModalOnClose]
  }

  return (
    <Route
      {...rest}
      render={matchProps => (
        userAuth?.user_id
          ? <AppWrapper sharedState={store}>
            <div className="container-scroller">
              <Header />
              <div className="container-fluid page-body-wrapper">
                <Sidebar />
                <div className="main-panel" style={{ width: isSidebarCollapsed ? "calc(100% - 45px)" : "calc(100% - 235px)" }}>
                  <div className="content-wrapper">
                    <div className="row">
                      <Component {...matchProps} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Modal
              isShow={isShowModal}
              isFullScreen
              onClose={() => {
                setShowModal(false)
                if (typeof modalOnClose === 'function') {
                  modalOnClose()
                  setModalOnClose(null)
                }
              }}
              title={modalTitle}
            >
              {modalContent}
            </Modal>
            <ToastContainer />
          </AppWrapper>
          : null
      )}
    />
  )
}

export default MainLayout
