import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { updateUserAuthInfo } from './loginAction';

import authAjax from '@ajax/auth';
import { FORM_INPUT_EMAIL_LOGIN } from '@constant/login';
import sidebarConstant from "@constant/sidebar";
import apiResult from '@util/apiResult';
import { handleError } from '@util/common';

import LoginForm from './form/loginForm';

import './common.scss';

const LoginPage = () => {
  const dispatch = useDispatch()

  const onHandleSubmit = async (loginInfo) => {
    return authAjax.login(JSON.stringify(loginInfo.remember
      ? loginInfo
      : Object.assign({}, loginInfo, { remember: false })
    ))
      .then(apiResponse => {
        const userAuthData = apiResult.success(apiResponse)
        dispatch(updateUserAuthInfo(userAuthData))
        window.location.href = getRedirectUrl(userAuthData)
      })
      .catch(handleError)
  }

  const getSideBarList = (userAuthData) => {
    return sidebarConstant.SIDEBAR_LIST
      .filter(navItem => {
        return navItem.roles.filter(navRole => {
          return userAuthData.roles.includes(navRole)
        }).length > 0
      })
      .map(navItem => {
        if (navItem.items) {
          return Object.assign({}, navItem, {
            items: navItem.items
              .filter(innerNavItem =>
                innerNavItem.roles
                  .filter(navRole => userAuthData.roles
                    .includes(navRole)
                  ).length > 0)
          })
        }
        return navItem
      })
  }

  const getRedirectUrl = (userAuthData) => {
    const items = getSideBarList(userAuthData)
    return items && items.length > 0 ? items[0].url : '/not-found'
  }

  useEffect(() => {
    authAjax.get()
      .then(apiResponse => {
        // if logged in, redirect
        const userAuthData = apiResult.success(apiResponse)
        window.location.href = getRedirectUrl(userAuthData)
      })
      .catch(apiResponse => {
        document.querySelector('.login-layout').classList.remove('loading');
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  return (
    <div>
      <div className="container-scroller" id="login-form">
        <div className="container-fluid page-body-wrapper full-page-wrapper">
          <div className="content-wrapper d-flex auth px-0" id="login-bg" style={{
            backgroundSize: 'cover', backgroundRepeat: 'no-repeat'
          }}>
            <div className="row w-100 mx-0">
              <div className="col-lg-4 mx-auto">
                <div className="auth-form-light text-left py-5 px-4 px-sm-5">
                  <div className="brand-logo">
                    {/* <img src="/images/logo/logo-transparent-rectangular.png" alt="logo" /> */}
                    <h2
                      className='text-center'
                      style={{ fontWeight: 800, marginBottom: '1rem', fontSize: '40px' }}
                    >HN Leaders</h2>
                    <h6
                      className="font-weight-light text-center"
                      style={{ marginBottom: '1.5rem' }}
                    >Vui lòng đăng nhập để sử dụng.</h6>
                  </div>

                  <LoginForm
                    handleSubmit={onHandleSubmit}
                    formInputs={FORM_INPUT_EMAIL_LOGIN}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LoginPage
