const commonConstant = {
  UNKNOWN_ERROR_CODE: 1,
  UNAUTHORIZED_ERROR_CODE: 401,
  NOT_FOUND_CODE: 2,
  NOT_FOUND_CODE_COMMON: 404,
  PERMISSION_DENIED_CODE: 401,
  ACCESS_DENIED_CODE: 403,
  SYSTEM_ERROR_URL: '/system-error',
  NOT_FOUND_PAGE_URL: '/not-found',
  ACCESS_DENIED_PAGE_URL: '/forbidden',
  LOGIN_PAGE_URL: '/',
  EMAIL_FORMAT: /^(([^<>()[]\\.,;:\s@"]+(\.[^<>()[]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i,
  PHONE_FORMAT: /^[\\+]?[(]?[0-9]{3}[)]?[-\s\\.]?[0-9]{3}[-\s\\.]?[0-9]{4,6}$/i,
  URL_FORMAT: /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=+$,\w]+@)?[A-Za-z0-9.-]+|(?:www\.|[-;:&=+$,\w]+@)[A-Za-z0-9.-]+)((?:\/[+~%/.\w\-_]*)?\??(?:[-+=&;%@.\w_]*)#?(?:[.!/\\\w]*))?)/,
  // PASSWORD_FORMAT: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[~!@#$%^&*(){}\[\],.\/?])(?=.{8,})/i
  PASSWORD_FORMAT: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/i,
  COLORS: ['#e6194B', '#f58231', '#3cb44b', '#4363d8', '#911eb4', '#f032e6', '#a9a9a9', '#9A6324', '#800000', '#808000', '#469990', '#e6194B', '#f58231', '#3cb44b', '#4363d8', '#911eb4', '#f032e6', '#a9a9a9', '#9A6324', '#800000', '#808000', '#469990']
}

export default commonConstant
