import debounce from "lodash/debounce"
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { v4 as uuidv4 } from 'uuid';

import { handleError, handleSuccess } from '@util/common';
import userAjax from "../../../../ajax/user";
import { INPUT_TABS, INPUT_TAB_VALUES, LEADERBOARD_AGES, LEADERBOARD_AGE_TYPES, LEADERBOARD_BRANCH_FIELDS, LEADERBOARD_BRANCH_TYPES, LEADERBOARD_GROUP_FIELDS, LEADERBOARD_GROUP_TYPES, LEADERBOARD_INDIVIDUAL_FIELDS, LEADERBOARD_INDIVIDUAL_TYPES } from "../../../../constant/report";

import './InputData.scss';

// const men = [
//   {
//     "branch_name": "Hanhphuc",
//     "group_index": 1,
//     "tp": 21,
//     "group_name": "Hanhphuc 1"
//   },
//   {
//     "branch_name": "Hanhphuc",
//     "group_index": 2,
//     "tp": 19,
//     "group_name": "Hanhphuc 2"
//   },
//   {
//     "branch_name": "Family",
//     "group_index": 4,
//     "tp": 28,
//     "group_name": "Family 4"
//   },
//   {
//     "branch_name": "Henoc",
//     "group_index": 5,
//     "tp": 19,
//     "group_name": "Henoc 5"
//   },
//   {
//     "branch_name": "Didim",
//     "group_index": 7,
//     "tp": 21,
//     "group_name": "Didim 7"
//   },
//   {
//     "branch_name": "Didim",
//     "group_index": 8,
//     "tp": 24,
//     "group_name": "Didim 8"
//   },
//   {
//     "branch_name": "Hymang",
//     "group_index": 11,
//     "tp": 20,
//     "group_name": "Hymang 11"
//   },
//   {
//     "branch_name": "Hymang",
//     "group_index": 12,
//     "tp": 24,
//     "group_name": "Hymang 12"
//   },
//   {
//     "branch_name": "Davit",
//     "group_index": 13,
//     "tp": 0,
//     "group_name": "Davit 13"
//   },
//   {
//     "branch_name": "Davit",
//     "group_index": 14,
//     "tp": 22,
//     "group_name": "Davit 14"
//   },
//   {
//     "branch_name": "Davit",
//     "group_index": 15,
//     "tp": 25,
//     "group_name": "Davit 15"
//   },
//   {
//     "branch_name": "Davit",
//     "group_index": 16,
//     "tp": 14,
//     "group_name": "Davit 16"
//   },
//   {
//     "branch_name": "Epheso",
//     "group_index": 17,
//     "tp": 12,
//     "group_name": "Epheso 17"
//   },
//   {
//     "branch_name": "Epheso",
//     "group_index": 18,
//     "tp": 12,
//     "group_name": "Epheso 18"
//   },
//   {
//     "branch_name": "Sarang",
//     "group_index": 22,
//     "tp": 13,
//     "group_name": "Sarang 22"
//   },
//   {
//     "branch_name": "Sarang",
//     "group_index": 23,
//     "tp": 18,
//     "group_name": "Sarang 23"
//   },
//   {
//     "branch_name": "Sarang",
//     "group_index": 24,
//     "tp": 22,
//     "group_name": "Sarang 24"
//   },
//   {
//     "branch_name": "Sarang",
//     "group_index": 25,
//     "tp": 17,
//     "group_name": "Sarang 25"
//   },
//   {
//     "branch_name": "Sungni",
//     "group_index": 26,
//     "tp": 45,
//     "group_name": "Sungni 26"
//   },
//   {
//     "branch_name": "Sungni",
//     "group_index": 27,
//     "tp": 22,
//     "group_name": "Sungni 27"
//   },
//   {
//     "branch_name": "Miso",
//     "group_index": 28,
//     "tp": 26,
//     "group_name": "Miso 28"
//   },
//   {
//     "branch_name": "Miso",
//     "group_index": 29,
//     "tp": 25,
//     "group_name": "Miso 29"
//   },
//   {
//     "branch_name": "Somang",
//     "group_index": 30,
//     "tp": 0,
//     "group_name": "Somang 30"
//   },
//   {
//     "branch_name": "Somang",
//     "group_index": 31,
//     "tp": 0,
//     "group_name": "Somang 31"
//   },
//   {
//     "branch_name": "Somang",
//     "group_index": 32,
//     "tp": 0,
//     "group_name": "Somang 32"
//   },
//   {
//     "branch_name": "Giacop",
//     "group_index": 33,
//     "tp": 28,
//     "group_name": "Giacop 33"
//   },
//   {
//     "branch_name": "Giacop",
//     "group_index": 34,
//     "tp": 23,
//     "group_name": "Giacop 34"
//   },
//   {
//     "branch_name": "Giacop",
//     "group_index": 35,
//     "tp": 10,
//     "group_name": "Giacop 35"
//   },
//   {
//     "branch_name": "Phuongdong",
//     "group_index": 37,
//     "tp": 22,
//     "group_name": "Phuongdong 37"
//   },
//   {
//     "branch_name": "Phuongdong",
//     "group_index": 38,
//     "tp": 20,
//     "group_name": "Phuongdong 38"
//   },
//   {
//     "branch_name": "Giosep",
//     "group_index": 40,
//     "tp": 22,
//     "group_name": "Giosep 40"
//   },
//   {
//     "branch_name": "Muchike",
//     "group_index": 60,
//     "tp": 17,
//     "group_name": "Muchike 60"
//   },
//   {
//     "branch_name": "Ysac",
//     "group_index": 61,
//     "tp": 43,
//     "group_name": "Ysac 61"
//   }
// ]

// const women = [
//   {
//     "branch_name": "Henoc",
//     "group_index": 1,
//     "tp": 45,
//     "group_name": "Henoc 1"
//   },
//   {
//     "branch_name": "Henoc",
//     "group_index": 2,
//     "tp": 25,
//     "group_name": "Henoc 2"
//   },
//   {
//     "branch_name": "Henoc",
//     "group_index": 3,
//     "tp": 29,
//     "group_name": "Henoc 3"
//   },
//   {
//     "branch_name": "Hanhphuc",
//     "group_index": 5,
//     "tp": 40,
//     "group_name": "Hanhphuc 5"
//   },
//   {
//     "branch_name": "Hanhphuc",
//     "group_index": 6,
//     "tp": 37,
//     "group_name": "Hanhphuc 6"
//   },
//   {
//     "branch_name": "Miso",
//     "group_index": 7,
//     "tp": 20,
//     "group_name": "Miso 7"
//   },
//   {
//     "branch_name": "Miso",
//     "group_index": 8,
//     "tp": 37,
//     "group_name": "Miso 8"
//   },
//   {
//     "branch_name": "Miso",
//     "group_index": 9,
//     "tp": 36,
//     "group_name": "Miso 9"
//   },
//   {
//     "branch_name": "Miso",
//     "group_index": 10,
//     "tp": 46,
//     "group_name": "Miso 10"
//   },
//   {
//     "branch_name": "Didim",
//     "group_index": 11,
//     "tp": 21,
//     "group_name": "Didim 11"
//   },
//   {
//     "branch_name": "Didim",
//     "group_index": 12,
//     "tp": 22,
//     "group_name": "Didim 12"
//   },
//   {
//     "branch_name": "Didim",
//     "group_index": 13,
//     "tp": 20,
//     "group_name": "Didim 13"
//   },
//   {
//     "branch_name": "Didim",
//     "group_index": 14,
//     "tp": 27,
//     "group_name": "Didim 14"
//   },
//   {
//     "branch_name": "Phuongdong",
//     "group_index": 15,
//     "tp": 41,
//     "group_name": "Phuongdong 15"
//   },
//   {
//     "branch_name": "Phuongdong",
//     "group_index": 16,
//     "tp": 25,
//     "group_name": "Phuongdong 16"
//   },
//   {
//     "branch_name": "Phuongdong",
//     "group_index": 17,
//     "tp": 53,
//     "group_name": "Phuongdong 17"
//   },
//   {
//     "branch_name": "Phuongdong",
//     "group_index": 18,
//     "tp": 52,
//     "group_name": "Phuongdong 18"
//   },
//   {
//     "branch_name": "Somang",
//     "group_index": 19,
//     "tp": 0,
//     "group_name": "Somang 19"
//   },
//   {
//     "branch_name": "Somang",
//     "group_index": 20,
//     "tp": 0,
//     "group_name": "Somang 20"
//   },
//   {
//     "branch_name": "Somang",
//     "group_index": 21,
//     "tp": 0,
//     "group_name": "Somang 21"
//   },
//   {
//     "branch_name": "Somang",
//     "group_index": 22,
//     "tp": 0,
//     "group_name": "Somang 22"
//   },
//   {
//     "branch_name": "Somang",
//     "group_index": 23,
//     "tp": 0,
//     "group_name": "Somang 23"
//   },
//   {
//     "branch_name": "Somang",
//     "group_index": 24,
//     "tp": 0,
//     "group_name": "Somang 24"
//   },
//   {
//     "branch_name": "Sarang",
//     "group_index": 25,
//     "tp": 54,
//     "group_name": "Sarang 25"
//   },
//   {
//     "branch_name": "Sarang",
//     "group_index": 26,
//     "tp": 52,
//     "group_name": "Sarang 26"
//   },
//   {
//     "branch_name": "Sarang",
//     "group_index": 27,
//     "tp": 48,
//     "group_name": "Sarang 27"
//   },
//   {
//     "branch_name": "Sarang",
//     "group_index": 28,
//     "tp": 45,
//     "group_name": "Sarang 28"
//   },
//   {
//     "branch_name": "Sarang",
//     "group_index": 29,
//     "tp": 31,
//     "group_name": "Sarang 29"
//   },
//   {
//     "branch_name": "Sarang",
//     "group_index": 30,
//     "tp": 28,
//     "group_name": "Sarang 30"
//   },
//   {
//     "branch_name": "Sungni",
//     "group_index": 31,
//     "tp": 58,
//     "group_name": "Sungni 31"
//   },
//   {
//     "branch_name": "Sungni",
//     "group_index": 32,
//     "tp": 38,
//     "group_name": "Sungni 32"
//   },
//   {
//     "branch_name": "Sungni",
//     "group_index": 33,
//     "tp": 32,
//     "group_name": "Sungni 33"
//   },
//   {
//     "branch_name": "Sungni",
//     "group_index": 34,
//     "tp": 49,
//     "group_name": "Sungni 34"
//   },
//   {
//     "branch_name": "Sungni",
//     "group_index": 35,
//     "tp": 49,
//     "group_name": "Sungni 35"
//   },
//   {
//     "branch_name": "Giacop",
//     "group_index": 39,
//     "tp": 26,
//     "group_name": "Giacop 39"
//   },
//   {
//     "branch_name": "Giacop",
//     "group_index": 40,
//     "tp": 39,
//     "group_name": "Giacop 40"
//   },
//   {
//     "branch_name": "Giacop",
//     "group_index": 41,
//     "tp": 36,
//     "group_name": "Giacop 41"
//   },
//   {
//     "branch_name": "Giacop",
//     "group_index": 42,
//     "tp": 49,
//     "group_name": "Giacop 42"
//   },
//   {
//     "branch_name": "Family",
//     "group_index": 45,
//     "tp": 59,
//     "group_name": "Family 45"
//   },
//   {
//     "branch_name": "Family",
//     "group_index": 46,
//     "tp": 39,
//     "group_name": "Family 46"
//   },
//   {
//     "branch_name": "Family",
//     "group_index": 47,
//     "tp": 40,
//     "group_name": "Family 47"
//   },
//   {
//     "branch_name": "Giosep",
//     "group_index": 50,
//     "tp": 26,
//     "group_name": "Giosep 50"
//   },
//   {
//     "branch_name": "Giosep",
//     "group_index": 51,
//     "tp": 28,
//     "group_name": "Giosep 51"
//   },
//   {
//     "branch_name": "Giosep",
//     "group_index": 52,
//     "tp": 26,
//     "group_name": "Giosep 52"
//   },
//   {
//     "branch_name": "Davit",
//     "group_index": 53,
//     "tp": 43,
//     "group_name": "Davit 53"
//   },
//   {
//     "branch_name": "Davit",
//     "group_index": 54,
//     "tp": 28,
//     "group_name": "Davit 54"
//   },
//   {
//     "branch_name": "Davit",
//     "group_index": 55,
//     "tp": 21,
//     "group_name": "Davit 55"
//   },
//   {
//     "branch_name": "Davit",
//     "group_index": 56,
//     "tp": 27,
//     "group_name": "Davit 56"
//   },
//   {
//     "branch_name": "Davit",
//     "group_index": 57,
//     "tp": 54,
//     "group_name": "Davit 57"
//   },
//   {
//     "branch_name": "Davit",
//     "group_index": 58,
//     "tp": 39,
//     "group_name": "Davit 58"
//   },
//   {
//     "branch_name": "Muchike",
//     "group_index": 62,
//     "tp": 44,
//     "group_name": "Muchike 62"
//   },
//   {
//     "branch_name": "Muchike",
//     "group_index": 63,
//     "tp": 29,
//     "group_name": "Muchike 63"
//   },
//   {
//     "branch_name": "Muchike",
//     "group_index": 64,
//     "tp": 0,
//     "group_name": "Muchike 64"
//   },
//   {
//     "branch_name": "Ysac",
//     "group_index": 65,
//     "tp": 47,
//     "group_name": "Ysac 65"
//   },
//   {
//     "branch_name": "Ysac",
//     "group_index": 66,
//     "tp": 43,
//     "group_name": "Ysac 66"
//   },
//   {
//     "branch_name": "Ysac",
//     "group_index": 67,
//     "tp": 33,
//     "group_name": "Ysac 67"
//   },
//   {
//     "branch_name": "Hymang",
//     "group_index": 70,
//     "tp": 30,
//     "group_name": "Hymang 70"
//   },
//   {
//     "branch_name": "Hymang",
//     "group_index": 71,
//     "tp": 20,
//     "group_name": "Hymang 71"
//   },
//   {
//     "branch_name": "Hymang",
//     "group_index": 72,
//     "tp": 15,
//     "group_name": "Hymang 72"
//   },
//   {
//     "branch_name": "Hymang",
//     "group_index": 73,
//     "tp": 25,
//     "group_name": "Hymang 73"
//   },
//   {
//     "branch_name": "Hymang",
//     "group_index": 74,
//     "tp": 20,
//     "group_name": "Hymang 74"
//   },
//   {
//     "branch_name": "Hymang",
//     "group_index": 75,
//     "tp": 17,
//     "group_name": "Hymang 75"
//   },
//   {
//     "branch_name": "Epheso",
//     "group_index": 76,
//     "tp": 31,
//     "group_name": "Epheso 76"
//   },
//   {
//     "branch_name": "Epheso",
//     "group_index": 77,
//     "tp": 31,
//     "group_name": "Epheso 77"
//   },
//   {
//     "branch_name": "Epheso",
//     "group_index": 78,
//     "tp": 24,
//     "group_name": "Epheso 78"
//   }
// ]

// const boys = [
//   {
//     "branch_name": "Hymang",
//     "group_index": 2,
//     "tp": "-",
//     "group_name": "Hymang 2"
//   },
//   {
//     "branch_name": "Ysac",
//     "group_index": 3,
//     "tp": "-",
//     "group_name": "Ysac 3"
//   },
//   {
//     "branch_name": "Didim",
//     "group_index": 5,
//     "tp": "-",
//     "group_name": "Didim 5"
//   },
//   {
//     "branch_name": "Epheso",
//     "group_index": 7,
//     "tp": "-",
//     "group_name": "Epheso 7"
//   },
//   {
//     "branch_name": "Davit",
//     "group_index": 8,
//     "tp": "-",
//     "group_name": "Davit 8"
//   },
//   {
//     "branch_name": "Family",
//     "group_index": 9,
//     "tp": "-",
//     "group_name": "Family 9"
//   },
//   {
//     "branch_name": "Phuongdong",
//     "group_index": 10,
//     "tp": "-",
//     "group_name": "Phuongdong 10"
//   },
//   {
//     "branch_name": "Henoc",
//     "group_index": 11,
//     "tp": "-",
//     "group_name": "Henoc 11"
//   },
//   {
//     "branch_name": "Hanhphuc",
//     "group_index": 12,
//     "tp": "-",
//     "group_name": "Hanhphuc 12"
//   },
//   {
//     "branch_name": "Giacop",
//     "group_index": 30,
//     "tp": "-",
//     "group_name": "Giacop 30"
//   },
//   {
//     "branch_name": "Somang",
//     "group_index": 36,
//     "tp": "-",
//     "group_name": "Somang 36"
//   },
//   {
//     "branch_name": "Giosep",
//     "group_index": 37,
//     "tp": "-",
//     "group_name": "Giosep 37"
//   },
//   {
//     "branch_name": "Sungni",
//     "group_index": 39,
//     "tp": "-",
//     "group_name": "Sungni 39"
//   },
//   {
//     "branch_name": "Miso",
//     "group_index": 43,
//     "tp": "-",
//     "group_name": "Miso 43"
//   },
//   {
//     "branch_name": "Sarang",
//     "group_index": 60,
//     "tp": "-",
//     "group_name": "Sarang 60"
//   },
//   {
//     "branch_name": "Muchike",
//     "group_index": 69,
//     "tp": "-",
//     "group_name": "Muchike 69"
//   }
// ]

// const girls = [
//   {
//     "branch_name": "Henoc",
//     "group_index": 1,
//     "tp": 24,
//     "group_name": "Henoc 1"
//   },
//   {
//     "branch_name": "Henoc",
//     "group_index": 2,
//     "tp": 25,
//     "group_name": "Henoc 2"
//   },
//   {
//     "branch_name": "Henoc",
//     "group_index": 3,
//     "tp": 20,
//     "group_name": "Henoc 3"
//   },
//   {
//     "branch_name": "Davit",
//     "group_index": 4,
//     "tp": 24,
//     "group_name": "Davit 4"
//   },
//   {
//     "branch_name": "Davit",
//     "group_index": 5,
//     "tp": 23,
//     "group_name": "Davit 5"
//   },
//   {
//     "branch_name": "Davit",
//     "group_index": 6,
//     "tp": 23,
//     "group_name": "Davit 6"
//   },
//   {
//     "branch_name": "Hymang",
//     "group_index": 9,
//     "tp": 20,
//     "group_name": "Hymang 9"
//   },
//   {
//     "branch_name": "Hymang",
//     "group_index": 10,
//     "tp": 16,
//     "group_name": "Hymang 10"
//   },
//   {
//     "branch_name": "Hymang",
//     "group_index": 11,
//     "tp": 9,
//     "group_name": "Hymang 11"
//   },
//   {
//     "branch_name": "Sungni",
//     "group_index": 13,
//     "tp": 26,
//     "group_name": "Sungni 13"
//   },
//   {
//     "branch_name": "Sungni",
//     "group_index": 14,
//     "tp": 15,
//     "group_name": "Sungni 14"
//   },
//   {
//     "branch_name": "Sungni",
//     "group_index": 15,
//     "tp": 22,
//     "group_name": "Sungni 15"
//   },
//   {
//     "branch_name": "Hanhphuc",
//     "group_index": 16,
//     "tp": 26,
//     "group_name": "Hanhphuc 16"
//   },
//   {
//     "branch_name": "Hanhphuc",
//     "group_index": 17,
//     "tp": 30,
//     "group_name": "Hanhphuc 17"
//   },
//   {
//     "branch_name": "Family",
//     "group_index": 18,
//     "tp": 18,
//     "group_name": "Family 18"
//   },
//   {
//     "branch_name": "Family",
//     "group_index": 19,
//     "tp": 17,
//     "group_name": "Family 19"
//   },
//   {
//     "branch_name": "Didim",
//     "group_index": 21,
//     "tp": 21,
//     "group_name": "Didim 21"
//   },
//   {
//     "branch_name": "Didim",
//     "group_index": 22,
//     "tp": 27,
//     "group_name": "Didim 22"
//   },
//   {
//     "branch_name": "Didim",
//     "group_index": 23,
//     "tp": 27,
//     "group_name": "Didim 23"
//   },
//   {
//     "branch_name": "Somang",
//     "group_index": 25,
//     "tp": 0,
//     "group_name": "Somang 25"
//   },
//   {
//     "branch_name": "Somang",
//     "group_index": 26,
//     "tp": 0,
//     "group_name": "Somang 26"
//   },
//   {
//     "branch_name": "Somang",
//     "group_index": 27,
//     "tp": 0,
//     "group_name": "Somang 27"
//   },
//   {
//     "branch_name": "Somang",
//     "group_index": 28,
//     "tp": 0,
//     "group_name": "Somang 28"
//   },
//   {
//     "branch_name": "Sarang",
//     "group_index": 30,
//     "tp": 36,
//     "group_name": "Sarang 30"
//   },
//   {
//     "branch_name": "Sarang",
//     "group_index": 31,
//     "tp": 34,
//     "group_name": "Sarang 31"
//   },
//   {
//     "branch_name": "Sarang",
//     "group_index": 32,
//     "tp": 14,
//     "group_name": "Sarang 32"
//   },
//   {
//     "branch_name": "Sarang",
//     "group_index": 33,
//     "tp": 14,
//     "group_name": "Sarang 33"
//   },
//   {
//     "branch_name": "Sarang",
//     "group_index": 34,
//     "tp": 22,
//     "group_name": "Sarang 34"
//   },
//   {
//     "branch_name": "Phuongdong",
//     "group_index": 35,
//     "tp": 32,
//     "group_name": "Phuongdong 35"
//   },
//   {
//     "branch_name": "Phuongdong",
//     "group_index": 36,
//     "tp": 30,
//     "group_name": "Phuongdong 36"
//   },
//   {
//     "branch_name": "Phuongdong",
//     "group_index": 37,
//     "tp": 29,
//     "group_name": "Phuongdong 37"
//   },
//   {
//     "branch_name": "Giosep",
//     "group_index": 39,
//     "tp": 15,
//     "group_name": "Giosep 39"
//   },
//   {
//     "branch_name": "Giosep",
//     "group_index": 40,
//     "tp": 19,
//     "group_name": "Giosep 40"
//   },
//   {
//     "branch_name": "Miso",
//     "group_index": 41,
//     "tp": 61,
//     "group_name": "Miso 41"
//   },
//   {
//     "branch_name": "Giacop",
//     "group_index": 43,
//     "tp": 0,
//     "group_name": "Giacop 43"
//   },
//   {
//     "branch_name": "Giacop",
//     "group_index": 44,
//     "tp": 33,
//     "group_name": "Giacop 44"
//   },
//   {
//     "branch_name": "Giacop",
//     "group_index": 45,
//     "tp": 38,
//     "group_name": "Giacop 45"
//   },
//   {
//     "branch_name": "Giacop",
//     "group_index": 46,
//     "tp": 31,
//     "group_name": "Giacop 46"
//   },
//   {
//     "branch_name": "Giacop",
//     "group_index": 47,
//     "tp": 24,
//     "group_name": "Giacop 47"
//   },
//   {
//     "branch_name": "Giacop",
//     "group_index": 48,
//     "tp": 25,
//     "group_name": "Giacop 48"
//   },
//   {
//     "branch_name": "Giacop",
//     "group_index": 49,
//     "tp": 21,
//     "group_name": "Giacop 49"
//   },
//   {
//     "branch_name": "Epheso",
//     "group_index": 53,
//     "tp": 16,
//     "group_name": "Epheso 53"
//   },
//   {
//     "branch_name": "Epheso",
//     "group_index": 54,
//     "tp": 17,
//     "group_name": "Epheso 54"
//   },
//   {
//     "branch_name": "Epheso",
//     "group_index": 55,
//     "tp": 25,
//     "group_name": "Epheso 55"
//   },
//   {
//     "branch_name": "Epheso",
//     "group_index": 56,
//     "tp": 22,
//     "group_name": "Epheso 56"
//   },
//   {
//     "branch_name": "Epheso",
//     "group_index": 57,
//     "tp": 19,
//     "group_name": "Epheso 57"
//   },
//   {
//     "branch_name": "Ysac",
//     "group_index": 61,
//     "tp": 30,
//     "group_name": "Ysac 61"
//   },
//   {
//     "branch_name": "Ysac",
//     "group_index": 62,
//     "tp": 33,
//     "group_name": "Ysac 62"
//   },
//   {
//     "branch_name": "Ysac",
//     "group_index": 63,
//     "tp": 27,
//     "group_name": "Ysac 63"
//   },
//   {
//     "branch_name": "Ysac",
//     "group_index": 64,
//     "tp": 38,
//     "group_name": "Ysac 64"
//   },
//   {
//     "branch_name": "Muchike",
//     "group_index": 65,
//     "tp": 21,
//     "group_name": "Muchike 65"
//   },
//   {
//     "branch_name": "Muchike",
//     "group_index": 66,
//     "tp": 24,
//     "group_name": "Muchike 66"
//   },
//   {
//     "branch_name": "Muchike",
//     "group_index": 67,
//     "tp": 21,
//     "group_name": "Muchike 67"
//   },
//   {
//     "branch_name": "Muchike",
//     "group_index": 68,
//     "tp": 24,
//     "group_name": "Muchike 68"
//   }
// ]

// const male_students = [
//   {
//     "branch_name": "Hymang",
//     "group_index": 2,
//     "tp": 0,
//     "group_name": "Hymang 2"
//   },
//   {
//     "branch_name": "Ysac",
//     "group_index": 3,
//     "tp": 0,
//     "group_name": "Ysac 3"
//   },
//   {
//     "branch_name": "Didim",
//     "group_index": 5,
//     "tp": 0,
//     "group_name": "Didim 5"
//   },
//   {
//     "branch_name": "Epheso",
//     "group_index": 7,
//     "tp": 0,
//     "group_name": "Epheso 7"
//   },
//   {
//     "branch_name": "Davit",
//     "group_index": 8,
//     "tp": 0,
//     "group_name": "Davit 8"
//   },
//   {
//     "branch_name": "Family",
//     "group_index": 9,
//     "tp": 0,
//     "group_name": "Family 9"
//   },
//   {
//     "branch_name": "Phuongdong",
//     "group_index": 10,
//     "tp": 0,
//     "group_name": "Phuongdong 10"
//   },
//   {
//     "branch_name": "Henoc",
//     "group_index": 11,
//     "tp": 0,
//     "group_name": "Henoc 11"
//   },
//   {
//     "branch_name": "Hanhphuc",
//     "group_index": 12,
//     "tp": 0,
//     "group_name": "Hanhphuc 12"
//   },
//   {
//     "branch_name": "Giacop",
//     "group_index": 30,
//     "tp": 0,
//     "group_name": "Giacop 30"
//   },
//   {
//     "branch_name": "Somang",
//     "group_index": 36,
//     "tp": 0,
//     "group_name": "Somang 36"
//   },
//   {
//     "branch_name": "Giosep",
//     "group_index": 37,
//     "tp": 0,
//     "group_name": "Giosep 37"
//   },
//   {
//     "branch_name": "Sungni",
//     "group_index": 39,
//     "tp": 0,
//     "group_name": "Sungni 39"
//   },
//   {
//     "branch_name": "Miso",
//     "group_index": 43,
//     "tp": 0,
//     "group_name": "Miso 43"
//   },
//   {
//     "branch_name": "Sarang",
//     "group_index": 60,
//     "tp": 0,
//     "group_name": "Sarang 60"
//   },
//   {
//     "branch_name": "Muchike",
//     "group_index": 69,
//     "tp": 0,
//     "group_name": "Muchike 69"
//   }
// ]

// const female_students = [
//   {
//     "branch_name": "Hymang",
//     "group_index": 1,
//     "tp": 0,
//     "group_name": "Hymang 1"
//   },
//   {
//     "branch_name": "Davit",
//     "group_index": 2,
//     "tp": 0,
//     "group_name": "Davit 2"
//   },
//   {
//     "branch_name": "Ysac",
//     "group_index": 4,
//     "tp": 0,
//     "group_name": "Ysac 4"
//   },
//   {
//     "branch_name": "Didim",
//     "group_index": 6,
//     "tp": 0,
//     "group_name": "Didim 6"
//   },
//   {
//     "branch_name": "Phuongdong",
//     "group_index": 7,
//     "tp": 0,
//     "group_name": "Phuongdong 7"
//   },
//   {
//     "branch_name": "Henoc",
//     "group_index": 10,
//     "tp": 0,
//     "group_name": "Henoc 10"
//   },
//   {
//     "branch_name": "Hanhphuc",
//     "group_index": 17,
//     "tp": 0,
//     "group_name": "Hanhphuc 17"
//   },
//   {
//     "branch_name": "Family",
//     "group_index": 19,
//     "tp": 0,
//     "group_name": "Family 19"
//   },
//   {
//     "branch_name": "Somang",
//     "group_index": 29,
//     "tp": 0,
//     "group_name": "Somang 29"
//   },
//   {
//     "branch_name": "Giosep",
//     "group_index": 30,
//     "tp": 0,
//     "group_name": "Giosep 30"
//   },
//   {
//     "branch_name": "Epheso",
//     "group_index": 37,
//     "tp": 0,
//     "group_name": "Epheso 37"
//   },
//   {
//     "branch_name": "Sungni",
//     "group_index": 40,
//     "tp": 0,
//     "group_name": "Sungni 40"
//   },
//   {
//     "branch_name": "Miso",
//     "group_index": 44,
//     "tp": 0,
//     "group_name": "Miso 44"
//   },
//   {
//     "branch_name": "Giacop",
//     "group_index": 50,
//     "tp": 0,
//     "group_name": "Giacop 50"
//   },
//   {
//     "branch_name": "Sarang",
//     "group_index": 60,
//     "tp": 0,
//     "group_name": "Sarang 60"
//   },
//   {
//     "branch_name": "Muchike",
//     "group_index": 70,
//     "tp": 0,
//     "group_name": "Muchike 70"
//   }
// ]

// const raw_branches = [
//   "Sarang",
//   "Sungni",
//   "Somang",
//   "Giacop",
//   "Miso",
//   "Hymang",
//   "Muchike",
//   "Ysac",
//   "Phuongdong",
//   "Epheso",
//   "Family",
//   "Henoc",
//   "Giosep",
//   "Hanhphuc",
//   "Davit",
//   "Didim",
//   ""
// ]

// const valid_branches = [{ "name": "Sarang", "id": "330e7e54-43a3-44b3-8f4a-f72f2339335b", "total_point": 0, "tp_4l": "497" }, { "name": "Sungni", "id": "e0365850-d2d6-431d-b34d-2d2452ed3045", "total_point": 0, "tp_4l": "401" }, { "name": "Somang", "id": "57c52bdd-843e-42f9-a180-ffde09917b95", "total_point": 0, "tp_4l": "465" }, { "name": "Giacop", "id": "831d21d9-42f5-4c37-9521-2fa1fefd02ab", "total_point": 0, "tp_4l": "470" }, { "name": "Miso", "id": "6c2968b9-dc2a-425e-8fe5-993facd42a78", "total_point": 0, "tp_4l": "337" }, { "name": "Hymang", "id": "4465cbf4-4018-4123-ae55-4ff37672cba1", "total_point": 0, "tp_4l": "230" }, { "name": "Muchike", "id": "2ad5b337-3d54-408c-9a6d-1807ddfd7e37", "total_point": 0, "tp_4l": "198" }, { "name": "Ysac", "id": "d0a537cd-c5ec-4b1a-a008-2f80f3dfc661", "total_point": 0, "tp_4l": "359" }, { "name": "Phuongdong", "id": "335f933d-5510-4bdc-a376-84d83b732565", "total_point": 0, "tp_4l": "358" }, { "name": "Epheso", "id": "53ab91e9-40cc-4bb0-8d8a-2e6059fbfea3", "total_point": 0, "tp_4l": "259" }, { "name": "Family", "id": "95b6759c-5c33-4e8e-acdc-1578aa398a79", "total_point": 0, "tp_4l": "218" }, { "name": "Henoc", "id": "e02747d6-7dde-4aca-a7a9-781701160dc5", "total_point": 0, "tp_4l": "258" }, { "name": "Giosep", "id": "259aafad-0bcc-428d-bd72-e37cb5e44227", "total_point": 0, "tp_4l": "155" }, { "name": "Hanhphuc", "id": "4d2664ae-5aa5-4820-9ef3-ff140b56893b", "total_point": 0, "tp_4l": "229" }, { "name": "Davit", "id": "990884db-ab89-4139-9a4e-37c8d31ba197", "total_point": 0, "tp_4l": "441" }, { "name": "Didim", "id": "f79702ae-2856-47b3-b2fd-fd82606b5688", "total_point": 0, "tp_4l": "235" }, { "name": "", "id": "78fed73b-e6bf-4138-82cf-11f5601f2793", "total_point": 0, "tp_4l": 0 }]

// const newBoys = [
//   {
//     "branch_name": "Davit",
//     "group_index": 1,
//     "tp": 14
//   },
//   {
//     "branch_name": "Davit",
//     "group_index": 2,
//     "tp": 12
//   },
//   {
//     "branch_name": "Davit",
//     "group_index": 3,
//     "tp": 27
//   },
//   {
//     "branch_name": "Henoc",
//     "group_index": 4,
//     "tp": 16
//   },
//   {
//     "branch_name": "Henoc",
//     "group_index": 5,
//     "tp": 16
//   },
//   {
//     "branch_name": "Henoc",
//     "group_index": 6,
//     "tp": 15
//   },
//   {
//     "branch_name": "Hymang",
//     "group_index": 8,
//     "tp": 28
//   },
//   {
//     "branch_name": "Hymang",
//     "group_index": 9,
//     "tp": 15
//   },
//   {
//     "branch_name": "Hanhphuc",
//     "group_index": 11,
//     "tp": 14
//   },
//   {
//     "branch_name": "Hanhphuc",
//     "group_index": 12,
//     "tp": 17
//   },
//   {
//     "branch_name": "Family",
//     "group_index": 14,
//     "tp": 21
//   },
//   {
//     "branch_name": "Epheso",
//     "group_index": 15,
//     "tp": 15
//   },
//   {
//     "branch_name": "Epheso",
//     "group_index": 16,
//     "tp": 18
//   },
//   {
//     "branch_name": "Epheso",
//     "group_index": 17,
//     "tp": 17
//   },
//   {
//     "branch_name": "Didim",
//     "group_index": 18,
//     "tp": 25
//   },
//   {
//     "branch_name": "Giacop",
//     "group_index": 20,
//     "tp": 17
//   },
//   {
//     "branch_name": "Giacop",
//     "group_index": 21,
//     "tp": 19
//   },
//   {
//     "branch_name": "Giacop",
//     "group_index": 22,
//     "tp": 33
//   },
//   {
//     "branch_name": "Phuongdong",
//     "group_index": 26,
//     "tp": 16
//   },
//   {
//     "branch_name": "Phuongdong",
//     "group_index": 27,
//     "tp": 13
//   },
//   {
//     "branch_name": "Phuongdong",
//     "group_index": 28,
//     "tp": 15
//   },
//   {
//     "branch_name": "Sarang",
//     "group_index": 30,
//     "tp": 21
//   },
//   {
//     "branch_name": "Sarang",
//     "group_index": 31,
//     "tp": 28
//   },
//   {
//     "branch_name": "Somang",
//     "group_index": 33,
//     "tp": null
//   },
//   {
//     "branch_name": "Somang",
//     "group_index": 34,
//     "tp": null
//   },
//   {
//     "branch_name": "Somang",
//     "group_index": 35,
//     "tp": null
//   },
//   {
//     "branch_name": "Sungni",
//     "group_index": 36,
//     "tp": 17
//   },
//   {
//     "branch_name": "Sungni",
//     "group_index": 37,
//     "tp": 22
//   },
//   {
//     "branch_name": "Miso",
//     "group_index": 39,
//     "tp": 31
//   },
//   {
//     "branch_name": "Miso",
//     "group_index": 40,
//     "tp": 26
//   },
//   {
//     "branch_name": "Giosep",
//     "group_index": 48,
//     "tp": 19
//   },
//   {
//     "branch_name": "Muchike",
//     "group_index": 61,
//     "tp": 18
//   },
//   {
//     "branch_name": "Ysac",
//     "group_index": 62,
//     "tp": 26
//   },
//   {
//     "branch_name": "Ysac",
//     "group_index": 63,
//     "tp": 29
//   }
// ]

// const valid_boys = newBoys.map(boy => ({
//   ...boy,
//   group_name: `${boy.branch_name} ${boy.group_index}`
// }))
// const valid_branches = raw_branches.map(name => ({
//   name: name,
//   id: uuidv4(),
//   total_point: 0,
//   tp_4l: 0,
// }))

// const mapBranch = (array) => {
//   return array.map(item => {
//     return ({
//       id: uuidv4(),
//       name: item.group_name,
//       branch_id: valid_branches.find(i => i.name === item.branch_name)?.id,
//       branch_name: item.branch_name,
//       total_point: 0,
//       tp_4l: item.tp,
//     })
//   }
//   )
// }

// console.log('json branch', JSON.stringify(valid_branches))
// console.log('json group', {
//   men: mapBranch(men),
//   women: mapBranch(women),
//   young_male: mapBranch(valid_boys),
//   young_female: mapBranch(girls),
//   student_male: mapBranch(male_students),
//   student_female: mapBranch(female_students),
// })

const InputData = () => {
  const { userAuth } = useSelector(state => state)

  const [rows, setRows] = useState([])
  const [currentTab, setCurrentTab] = useState(INPUT_TABS[0])
  const [currentAge, setCurrentAge] = useState(LEADERBOARD_AGES[0].id)
  const [dataChanged, setDataChanged] = useState(false)
  const [loading, setLoading] = useState(false)
  const [branches, setBranches] = useState([])
  const [groups, setGroups] = useState([])

  const getReportFields = () => {
    switch (currentTab.id) {
      case INPUT_TAB_VALUES.branch:
        return LEADERBOARD_BRANCH_FIELDS
      case INPUT_TAB_VALUES.group:
        return LEADERBOARD_GROUP_FIELDS
      case INPUT_TAB_VALUES.individual:
        return LEADERBOARD_INDIVIDUAL_FIELDS
      default:
        return null
    }
  }

  const debounceDropDown = useCallback(debounce((nextRowsValue) => setRows(nextRowsValue), 1000), [])

  const handleChangeField = (value, fieldKey, row) => {
    const oldRows = rows

    debounceDropDown(oldRows.map(prevRow => {
      if (prevRow.id === row.id) {
        return {
          ...prevRow,
          [fieldKey]: value
        }
      }
      return prevRow
    }))

  }

  const handleAddRow = () => {
    const id = uuidv4()
    const fields = {}

    getReportFields().map(field => {
      fields[field.key] = field.type === 'text' ? '' : 0
      return null
    })

    setRows(prevRows => [...prevRows, {
      id,
      ...fields
    }])
    setDataChanged(true)
  }

  const confirmDeleteRow = (row) => {
    if (window.confirm('Xác nhận xóa hàng này?')) handleDeleteRow(row)
  }

  const handleDeleteRow = (row) => {
    setRows(prevRows => prevRows.filter(prevRow => prevRow.id !== row.id))
  }

  const handleChangeAge = (value) => {
    if (loading) return

    if (dataChanged) {
      if (window.confirm('Lưu dữ liệu trước khi chuyển ban?')) {
        handleSubmit(null, value)
      } else {
        setDataChanged(false)
        setCurrentAge(value)
      }
    } else {
      setCurrentAge(value)
    }
  }

  const wrapData = () => {
    let dataToUpdate = {}

    if (currentTab.id === INPUT_TAB_VALUES.branch) {
      dataToUpdate['leaderboard_branch'] = rows
    }
    else if (currentTab.id === INPUT_TAB_VALUES.group) {
      const currentData = userAuth.leaderboard['leaderboard_group']
      dataToUpdate['leaderboard_group'] = { ...currentData, [currentAge]: rows }
    }
    else if (currentTab.id === INPUT_TAB_VALUES.individual) {
      const currentData = userAuth.leaderboard['leaderboard_individual']
      dataToUpdate['leaderboard_individual'] = { ...currentData, [currentAge]: rows }
    }

    return dataToUpdate
  }

  const handleSubmit = (tab, age) => {
    if (loading) return

    setLoading(true)

    const dataToUpdate = wrapData()

    userAjax.update(
      userAuth.user_id,
      JSON.stringify(dataToUpdate)
    )
      .then(res => {
        setDataChanged(false)
        handleSuccess('Cập nhật thành công!')
        if (tab && INPUT_TABS.includes(tab)) {
          setCurrentTab(tab)
        }
        if (age && Object.keys(LEADERBOARD_AGE_TYPES).includes(age)) {
          setCurrentAge(age)
        }
        setLoading(false)
      })
      .catch((error) => {
        console.log('error', error)
        handleError(error)
        setLoading(false)
      })
  }

  const handleChangeTab = (tab) => {
    if (loading) return

    if (dataChanged) {
      if (window.confirm('Lưu dữ liệu trước khi chuyển tab?')) {
        handleSubmit(tab)
      } else {
        setDataChanged(false)
        setCurrentAge(LEADERBOARD_AGES[0].id)
        setCurrentTab(tab)
      }
    } else {
      setCurrentAge(LEADERBOARD_AGES[0].id)
      setCurrentTab(tab)
    }
  }

  const handleChangeBranch = (branchId, groupRow) => {
    const branch = branches.find(item => item.id === branchId)

    if (branch) {
      setRows(prevRows => prevRows.map(prevRow => {
        if (prevRow.id === groupRow.id) {
          return {
            ...prevRow,
            branch_name: branch.name,
            branch_id: branch.id,
          }
        }
        return prevRow
      }))
      setDataChanged(true)
    }
  }

  const handleChangeGroup = (groupId, groupRow) => {
    const group = groups.find(item => item.id === groupId)

    if (group) {
      setRows(prevRows => prevRows.map(prevRow => {
        if (prevRow.id === groupRow.id) {
          return {
            ...prevRow,
            group_name: group.name,
            group_id: group.id,
          }
        }
        return prevRow
      }))
      setDataChanged(true)
    }
  }

  useEffect(() => {
    if (userAuth?.user_id && userAuth.leaderboard) {
      setBranches(userAuth.leaderboard.leaderboard_branch || [])
      setGroups(userAuth.leaderboard?.leaderboard_group?.[currentAge] || [])

      if (currentTab.id === INPUT_TAB_VALUES.branch) {
        setRows(userAuth.leaderboard.leaderboard_branch)
      } else if (currentTab.id === INPUT_TAB_VALUES.group) {
        setRows(userAuth.leaderboard?.leaderboard_group?.[currentAge] || [])
      } else if (currentTab.id === INPUT_TAB_VALUES.individual) {
        setRows(userAuth.leaderboard?.leaderboard_individual?.[currentAge] || [])
      }
    }
  }, [userAuth, currentTab, currentAge])

  // const rowWithPoints = currentTab.id === INPUT_TAB_VALUES.branch
  //   ? rows.map(branch => {
  //     let totalPoint = 0
  //     console.log('branch-----------', branch)
  //     Object.keys(LEADERBOARD_AGE_TYPES).map(key => {
  //       console.log('age', key)
  //       console.log('currentPoint', totalPoint)
  //       console.log('list', userAuth?.leaderboard?.leaderboard_group?.[key]?.filter(group => group.branch_id === branch.id))
  //       totalPoint = totalPoint + (userAuth?.leaderboard?.leaderboard_group?.[key]?.filter(group => group.branch_id === branch.id).reduce((sum, { total_point }) => sum + parseFloat(total_point), 0) ?? 0)
  //       return key
  //     })

  //     console.log('final point--------', totalPoint)
  //     return {
  //       ...branch,
  //       total_point: totalPoint
  //     }
  //   })
  //   : rows
  const rowWithPoints = rows

  const availableBranches = branches.filter(branch => branch.name)
  const availableGroups = groups.filter(group => group.name)
  const fields = useMemo(getReportFields, [currentTab])

  return (
    <div className="input-data">
      <ul className="nav nav-tabs">
        {INPUT_TABS.map(tab => (
          <li key={tab.id} className={"nav-item " + (loading && ' disabled')} onClick={() => handleChangeTab(tab)}>
            <div className={"nav-link " + (currentTab.id === tab.id && ' active')}>{tab.label}</div>
          </li>
        ))}
      </ul>

      <div className="input-block">
        {currentTab.id !== INPUT_TAB_VALUES.branch && (
          <div className="age-select">
            Theo độ tuổi
            <select disabled={loading} onChange={e => handleChangeAge(e.target.value)} value={currentAge}>
              {LEADERBOARD_AGES.map(age => (
                <option key={age.id} value={age.id}>{age.label}</option>
              ))}
            </select>
          </div>
        )}
        <div className={"input-rows-scollable " + (currentTab.id !== INPUT_TAB_VALUES.branch && 'has-age-select')}>
          {rowWithPoints.map((row, rowIndex) => (
            <div className="input-row" key={row.id + row.name + rowIndex}>
              <div className="row-heading">
                <div className="row-label">#{rowIndex + 1}.</div>
                <div className="row-btns">
                  <div onClick={() => confirmDeleteRow(row)}>
                    <i className="icon-trash menu-icon" />
                  </div>
                </div>
              </div>
              <ul className="input-list">
                {fields.map(
                  (field, index) => {
                    if (field.key === LEADERBOARD_GROUP_TYPES.branch_name) {
                      return <li key={currentTab.id + index + currentAge}>
                        <label htmlFor={field.key}>{field.label}:</label> <select onChange={(e) => handleChangeBranch(e.target.value, row)} value={row.branch_id}>
                          <option value=''>---chọn Chi Hội---</option>
                          {availableBranches.map(branch => <option key={branch.id} value={branch.id}>{branch[LEADERBOARD_BRANCH_TYPES.name]}</option>)}
                        </select>
                      </li>
                    }

                    if (field.key === LEADERBOARD_INDIVIDUAL_TYPES.group_name) {
                      return (
                        <li key={currentTab.id + index + currentAge}>
                          <label htmlFor={field.key}>{field.label}:</label> <select onChange={(e) => handleChangeGroup(e.target.value, row)} value={row.group_id}>
                            <option value=''>---chọn Địa Vực---</option>
                            {availableGroups.map(branch => <option key={branch.id} value={branch.id}>{branch[LEADERBOARD_GROUP_TYPES.name]}</option>)}
                          </select>
                        </li>
                      )
                    }

                    return (
                      <li key={currentTab.id + index + currentAge} className={field.readOnly && 'read-only'}>
                        <label htmlFor={field.key}>{field.label}:</label>
                        <input
                          id={field.key}
                          placeholder=''
                          type={field.type}
                          onChange={(e) => handleChangeField(e.target.value, field.key, row)}
                          defaultValue={(row?.[field.key] || '')}
                          readOnly={field.readOnly}
                        />
                      </li>
                    )
                  }
                )}
              </ul>
            </div>
          ))}
          <div className="actions-row">
            <div className="clickable" onClick={handleAddRow}>
              Thêm +1 hàng
            </div>
          </div>
        </div>
        <div className='bottom-pad'>
          <div onClick={handleSubmit}>
            Xác nhận
          </div>
        </div>
      </div>
    </div>
  )
}

export default InputData