import React, { useState } from 'react';
import { reduxForm } from 'redux-form';

import { FORM_FIELDS } from '@constant/form';

import { FORM_FIELD_TYPE, FUNCTION_HELPER, SAMPLE_FIELD } from '../../../constant/form';
import StringUtil from '../../../util/string';

import './addNewForm.scss';

const AddNewForm = ({
  errors,
  handleSubmit,
  blocking,
  isEdit,
  selectedForm = {}
}) => {
  const [formContent, setFormContent] = useState(
    selectedForm?.content || [SAMPLE_FIELD]
  )

  const addField = () => {
    setFormContent(prevState => {
      return [...prevState, SAMPLE_FIELD]
    })
  }

  const changeField = (value, index, key) => {
    setFormContent(prevState => prevState.map((field, i) =>
      i === index
        ? ({
          ...field,
          [key]: value,
          id: key === 'label' ? StringUtil.nonAccentVietnamese(value) : field.id
        })
        : field
    ))
  }

  const changePosition = (direction, oldIndex) => {
    setFormContent(prevState => {
      const tempFields = [...prevState]
      const movedIndex = direction === 'up' ? (oldIndex - 1) : (oldIndex + 1)

      const movedField = [...prevState][movedIndex]
      const oldField = [...prevState][oldIndex]
      tempFields[movedIndex] = oldField
      tempFields[oldIndex] = movedField

      return tempFields
    })
  }

  const deleteField = (index) => {
    setFormContent(prevState => {
      return [...prevState.splice(0, index), ...prevState.splice(index + 1)]
    })
  }

  return (
    <form className="create-class-form" onSubmit={(e) => {
      e.preventDefault()
      handleSubmit(
        {
          form_id: selectedForm?.form_id || null,
          name: document.getElementById('name').value,
          description: document.getElementById('description').value || '',
          content: formContent
        }
      )
    }}>
      <div className="row">
        <div className="col-12">
          {FORM_FIELDS.map((field, index) => <div className="form-group" key={field.name + index}>

            <label htmlFor={field.name}>{field.label}</label>
            <input
              id={field.name}
              type={field.type}
              required={field.required}
              defaultValue={selectedForm[field.name] || ''}
              className='form-control'
            />
          </div>)}

          <hr />

          {formContent?.map((field, index) => <div
            className="form-group field-input-group"
            key={field.name + index}
          >
            <div className='field-input actions'>
              <div />
              <div className='action-list'>
                {index > 0 && <i className='fa fa-chevron-up' onClick={() => changePosition('up', index)} />}
                {index < (formContent.length - 1) && <i className='fa fa-chevron-down' onClick={() => changePosition('dowm', index)} />}
                <i className='fa fa-trash' onClick={() => deleteField(index)} />
              </div>
            </div>
            <div className='field-input'>
              <label>Tên trường</label>
              <input
                value={field.label}
                onChange={e => changeField(e.target.value, index, 'label')}
                required
              />
            </div>
            <div className='field-input'>
              <label>Kiểu dữ liệu</label>
              <select value={field.type} onChange={e =>
                changeField(e.target.value, index, 'type')
              }>
                <option value={FORM_FIELD_TYPE.text}>Văn bản</option>
                <option value={FORM_FIELD_TYPE.number}>Số</option>
              </select>
            </div>
            {field.type === FORM_FIELD_TYPE.number &&
              <div className='field-input'>
                <label>Số thập phân?</label>
                <input type="checkbox" style={{ width: "20px" }} value={field.is_decimal} onChange={e =>
                  changeField(e.target.checked, index, 'is_decimal')
                } />
              </div>}
            <div className='field-input'>
              <label>Hàm bổ trợ</label>
              <select value={field.function_helper} onChange={e =>
                changeField(e.target.value, index, 'function_helper')
              }>
                <option value="">Không sử dụng</option>
                <option value={FUNCTION_HELPER.SUM}>SUM</option>
              </select>
            </div>
          </div>)}

          <div className='addFieldBtn' onClick={addField}>Thêm trường &nbsp; <i className="fa fa-plus-circle" aria-hidden="true" /></div>
        </div>
      </div>

      {errors && errors.map((err, index) => (
        <div className="box-line error" key={index}>{err.message}</div>
      ))}
      <div className="form-actions">
        <button className="submitBtn newFormBtn" type="submit" disabled={formContent.length === 0}>
          {isEdit ? 'Xác nhận' : 'Tạo mới'}
        </button>
      </div>
    </form >
  )
}

export default reduxForm({
  form: 'AddNewForm'
})(AddNewForm)
