import Ajax from './ajax'

class FormAjax extends Ajax {
    list(uriTail, loading = true) {
        return this.ajax(loading).get(`/forms${uriTail ? uriTail : ''}`)
    }

    listResponses(id) {
        return this.ajax().get(`/forms/${id}`)
    }

    create(data) {
        return this.ajax().post(`/forms`, data)
    }

    update(id, data) {
        return this.ajax().post(`/forms/${id}/update`, data)
    }
}

const formAjax = new FormAjax()
export default formAjax
