export const FORM_STATUS_OPTIONS = [
  {
    value: 0,
    label: 'Đã khóa'
  },
  {
    value: 1,
    label: 'Đang mở'
  },
]

export const FORM_FIELD_TYPE = {
  text: 'text',
  number: 'number',
}

export const FUNCTION_HELPER = {
  SUM: 'sum',
}

export const FORM_FIELDS = [
  {
    name: 'name',
    type: FORM_FIELD_TYPE.text,
    placeholder: '',
    label: 'Tên biểu mẫu',
    required: true,
  },
  {
    name: 'description',
    type: FORM_FIELD_TYPE.text,
    placeholder: '',
    label: 'Mô tả',
    required: false,
  },
]

export const SAMPLE_FIELD = {
  name: 'name',
  type: FORM_FIELD_TYPE.text,
  label: '',
  required: true,
  is_decimal: false
}