import Base64 from 'base-64';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import "./ToolsPage.scss";

const ToolsPage = () => {
  const [copiedLvl1, setCopiedLvl1] = useState(false)
  const [copiedLvl2, setCopiedLvl2] = useState(false)
  const [copiedVideosNew, setCopiedVideosNew] = useState(false)
  const [copiedNewSongVideos, setCopiedNewSongVideos] = useState(false)

  const videosCodeLvl1 = Base64.encode(moment().format('DDMMYYYY') + "popular")
  const videosCodeLvl2 = Base64.encode(moment().format('DDMMYYYY') + "CTCM")
  const videosNew = Base64.encode(moment().format('DDMMYYYY') + "New")
  const newSongVideosCode = Base64.encode(moment().format('DDMMYYYY') + "newsong-videos")

  useEffect(() => {
    if (copiedLvl1 || copiedLvl2 || copiedNewSongVideos || videosNew) {
      setTimeout(() => {
        setCopiedLvl1(false)
        setCopiedLvl2(false)
        setCopiedVideosNew(false)
        setCopiedNewSongVideos(false)
      }, 3000)
    }
  }, [copiedLvl1, copiedLvl2, copiedNewSongVideos, videosNew]);

  return (
    <div className="toolsPage">
      <h3 className="font-weight-bold">Mã truy cập video</h3>
      <ol>
        <li>
          Trudo Cũ - MG 1,2,3
          <CopyToClipboard text={videosCodeLvl1}
            onCopy={() => setCopiedLvl1(true)}
          >
            <button disabled={copiedLvl1}>{copiedLvl1 ? "Đã copy" : "Click copy mã"}</button>
          </CopyToClipboard>
        </li>
        <li>
          Trudo Cũ - MG Cha Mẹ
          <CopyToClipboard text={videosCodeLvl2}
            onCopy={() => setCopiedLvl2(true)}
          >
            <button disabled={copiedLvl2}>{copiedLvl2 ? "Đã copy" : "Click copy mã"}</button>
          </CopyToClipboard>
        </li>
        <li>
          Trudo Mới
          <CopyToClipboard text={videosNew}
            onCopy={() => setCopiedVideosNew(true)}
          >
            <button disabled={copiedVideosNew}>{copiedVideosNew ? "Đã copy" : "Click copy mã"}</button>
          </CopyToClipboard>
        </li>
        <li>
          Bài Ca Mới
          <CopyToClipboard text={newSongVideosCode}
            onCopy={() => setCopiedNewSongVideos(true)}
          >
            <button disabled={copiedNewSongVideos}>{copiedNewSongVideos ? "Đã copy" : "Click copy mã"}</button>
          </CopyToClipboard>
        </li>
      </ol>
      <h3 className="font-weight-bold">Link truy cập video</h3>
      <ol>
        <li>Trudo Cũ - MG 1,2,3: <div><a href={window.location.origin + "/videos"} target="_blank" rel="noopener noreferrer">{window.location.origin + "/videos"}</a></div></li>
        <li>Trudo Cũ - MG Cha Mẹ <div><a href={window.location.origin + "/videos"} target="_blank" rel="noopener noreferrer">{window.location.origin + "/videos"}</a></div></li>
        <li>Trudo Mới <div><a href={window.location.origin + "/videos-new"} target="_blank" rel="noopener noreferrer">{window.location.origin + "/videos-new"}</a></div></li>
        <li>Bài Ca Mới <div><a href={window.location.origin + "/newsongs"} target="_blank" rel="noopener noreferrer">{window.location.origin + "/newsongs"}</a></div></li>
      </ol>
    </div>
  )
}

export default ToolsPage