import commonConstant from '@constant/common'

class ApiResult {
  errorMessage(error) {
    const errors = this.errors(error)
    return errors.length > 0 ? `Lỗi(${errors[0].code}): ${errors[0].message}` : 'Lỗi chưa xác định'
  }

  errors(apiResponse) {
		//Sometime apiResponse.response return empty 
		return this.getMessage((typeof apiResponse.response.data == 'string') ? JSON.parse(apiResponse.response.data) : apiResponse.response.data)
  }

  getMessage(response) {
    return (typeof response.warning !== 'undefined') ? response.warning : this.getErrorMessage(response)
  }

  getErrorMessage(response) {
    if (response.error) {
      if (response.error.code === commonConstant.ACCESS_DENIED_CODE) {
        this.displayErrorMessage(response.error)
      } else if (response.error.code === commonConstant.UNAUTHORIZED_ERROR_CODE) {
        if (window.location.pathname !== '/') window.location.href = commonConstant.LOGIN_PAGE_URL
      } else if (response.error.code === commonConstant.UNKNOWN_ERROR_CODE) {
        window.location.href = commonConstant.SYSTEM_ERROR_URL
      } else if (response.error.code === commonConstant.PERMISSION_DENIED_CODE) {
        setTimeout(function () {
          window.location.href = commonConstant.LOGIN_PAGE_URL
        }, 2000)
      } else if (response.error.code === commonConstant.NOT_FOUND_CODE_COMMON) {
        setTimeout(function () {
          window.location.href = commonConstant.LOGIN_PAGE_URL
        }, 2000)
      }

      return [].concat(response.error)
    } else {
      localStorage.setItem('error', JSON.stringify(response))
      // window.location.href = '/system-error'
    }
  }

  displayErrorMessage(error) {
    if(error.message && parseInt(error.message)) {
      window.location.href = commonConstant.ACCESS_DENIED_PAGE_URL + '?time=' + parseInt(error.message)
    } else {
      window.location.href = commonConstant.ACCESS_DENIED_PAGE_URL
    }
  }

  success(apiResponse) {
    return (typeof apiResponse.data == 'string') ? JSON.parse(apiResponse.data).result : apiResponse.data.result
  }

  fileSuccess (apiResponse, fileName, fileType) {
    const blob = new Blob([apiResponse.data], {type: fileType})
    if (window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(blob, fileName)
    } else {
      const url = window.URL || window.webkitURL || window.mozURL || window.msURL
      let link = document.createElement('a')
      link.href = url.createObjectURL(blob)
      link.download = fileName
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }
  }
}
let apiResult = new ApiResult()
export default apiResult
