const DECIMAL = 10
class StringUtil {
  static capitalizeFirstLetter(str) {
    str = str.replace(/[-_]/g, ' ')
    return str.replace(/\w\S*/g, function (str) {
      return str.charAt(0).toUpperCase() + str.toLowerCase().slice(1)
    }).replace(/\s/g, '')
  }

  static omit(str, byteIndex) {
    for (let j = 0, byte = 0; j < str.length; j++) {
      str.charCodeAt(j) < 0x100 ? byte++ : byte += 2
      if (byte > byteIndex) {
        return str.substr(0, j) + '...'
      }
    }
    return str
  }
  static convertToInt(str) {
    return parseInt(str, DECIMAL)
  }

  static separateByFigure(num) {
    return (num !== '' && num !== undefined) ? num.toLocaleString() : ''
  }

  static paddingByZeroAfterDecimal(num, place) {
    return num.toFixed(place).toLocaleString()
  }

  static isEmpty(str) {
    return !str || str.trim().length === 0
  }

  static isPartialMatched(target, keyword) {
    return target.indexOf(keyword) !== -1
  }

  static isNonEmpty(str) {
    return str !== undefined && str !== ''
  }

  static replaceAll(str, find, replace) {
    return str.replace(new RegExp(find, 'g'), replace);
  }

  static stripHtmlTags(html) {
    const div = document.createElement("div");
    div.innerHTML = html;
    return div.textContent || div.innerText || "";
  }

  static createFriendlyUrl(str) {
    return str.replace(/[^a-zA-Z0-9-_]/g, '')
  }

  static stringToFriendlyUri(str) {
    return this.createFriendlyUrl(this.replaceAll(str, ' ', '-'))
  }

  static formatCurrency(num) {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  }

  static getParamFromUrl(key, url) {
    if (typeof (url) === 'undefined')
      url = window.location.href;
    const match = url.match('[?&]' + key + '=([^&]+)');
    return match ? match[1] : '';
  }

  static getSearchString(str) {
    return this.replaceAll(str, /[^a-z0-9A-Z_ÀÁÂÃÈÉÊÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêìíòóôõùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼỀỀỂưăạảấầẩẫậắằẳẵặẹẻẽềềểỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễệỉịọỏốồổỗộớờởỡợụủứừỬỮỰỲỴÝỶỸửữựỳỵỷỹ ]/u, '')
  }

  static numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  static roundAndFormatNumber(number) {
    return this.numberWithCommas(Math.round(number * 100) / 100)
  }

  static getAllParamsUrl(url) {
    let vars = {};
    url.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (m, key, value) {
      vars[key] = value;
    });
    return vars;
  }

  static nonAccentVietnamese(str) {
    str = str.toLowerCase();
    str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
    str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
    str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
    str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
    str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
    str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
    str = str.replace(/đ/g, "d");
    // Some system encode vietnamese combining accent as individual utf-8 characters
    str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ""); // Huyền sắc hỏi ngã nặng 
    str = str.replace(/\u02C6|\u0306|\u031B/g, ""); // Â, Ê, Ă, Ơ, Ư
    return str;
  }
}


export default StringUtil
