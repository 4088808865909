import React from 'react';
import { Field, reduxForm } from 'redux-form';

import TextInput from '@parts/form/textInput';

import './common.scss';


const CreateMemberForm = ({ errors, handleSubmit, blocking, closeForm, isEdit }) => {
  return (
    <form className="create-class-form" onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-12">
          {/* <h3>{isEdit ? 'Cập nhật thông tin tài khoản' : 'Thêm Tài Khoản'}</h3> */}

          <div className="form-group">
            {/* <label>Email*</label> */}
            <Field
              name="email"
              component={TextInput}
              type="email"
              placeholder="abc@example.com"
              label="Email*"
              required
            />
          </div>

          <div className="form-group">
            {/* <label>Khu vực*</label> */}
            <Field
              name="zion"
              component={TextInput}
              type="text"
              placeholder="HN123"
              label="Khu vực*"
              required
            />
          </div>

          {!isEdit && <div className="form-group">
            {/* <label>Mật khẩu (Mặc định)</label> */}
            <Field
              name=""
              component={TextInput}
              type="text"
              placeholder="Zion@2023"
              label="Mật khẩu (Mặc định)"
              disabled
            />
          </div>}

        </div>
      </div>

      {errors && errors.map((err, index) => (
        <div className="box-line error" key={index}>{err.message}</div>
      ))}
      <div className="form-actions">
        <button className="submitBtn" type="submit">
          {isEdit ? 'Xác nhận' : 'Tạo mới'}
        </button>
        {/* <button
          className="cancelBtn"
          type="button"
          onClick={closeForm}
        >
          Hủy bỏ
        </button> */}
      </div>
    </form>
  )
}

export default reduxForm({
  form: 'CreateMemberForm'
})(CreateMemberForm)
