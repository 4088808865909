import Ajax from './ajax'

class ReportAjax extends Ajax {
  list(uriTail, loading = true) {
    return this.ajax(loading).get(`/reports${uriTail ? uriTail : ''}`)
  }

  listSubmission(uriTail, loading = true) {
    return this.ajax(loading).get(`/report-submissions${uriTail ? uriTail : ''}`)
  }

  create(data) {
    return this.ajax().post(`/reports`, data)
  }

  checkSubmission(uriTail) {
    return this.ajax().get(`/report-submission${uriTail ? uriTail : ''}`)
  }

  update(id, data) {
    return this.ajax().post(`/reports/${id}/update`, data)
  }

  getReportsAdmin(uriTail) {
    return this.ajax().get(`/reports-admin${uriTail ? uriTail : ''}`)
  }

  getTotalSub(uriTail) {
    return this.ajax().get(`/reports-total-sub${uriTail ? uriTail : ''}`)
  }

  getWmsAdmin(uriTail) {
    return this.ajax().get(`/wm-admin${uriTail ? uriTail : ''}`)
  }

  getCurrentStatusReport(uriTail) {
    return this.ajax().get(`/report-current-status${uriTail ? uriTail : ''}`)
  }
}

const reportAjax = new ReportAjax()
export default reportAjax
