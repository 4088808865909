import React from 'react';

const TextInput = ({ input, required, id, label, min, max, step, className, placeholder, type, disabled, readOnly }) => (
  <div
    className={className}
  >
    <label htmlFor={id} style={{ fontWeight: 600 }}>{label}</label>
    <input id={id} {...input} required={required} min={min ? min : undefined} max={max ? max : undefined} step={step ? step : undefined} className={type === 'checkbox' ? '' : "form-control"} placeholder={placeholder} type={type}
      disabled={disabled} readOnly={readOnly}
    />
  </div>
)

export default TextInput
